export default {
  setMeta(state, { path, value }) {
    state.meta[path] = value;
  },
  setData(state, { path, value }) {
    state.data[path] = structuredClone(value);
  },
  setLoading(state, { path, value }) {
    state.loading[path] = value;
  },
  setError(state, { path, value }) {
    state.error[path] = value;
  },
  spreadData(state) {
    state.data = { ...state.data };
  },
  setMultipleData(state, multipleData) {
    multipleData.forEach((item) => {
      state.data[item.path] = structuredClone(item.value);
    });
  },
  setMultipleMeta(state, multipleMeta) {
    multipleMeta.forEach((item) => {
      state.meta[item.path] = item.value;
    });
  },
  /* =======Modal======== */
  setModalData(state, { path, value }) {
    state.modal.data[path] = typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value;
  },
  setModalMeta(state, { path, value }) {
    state.modal.meta[path] = value;
  },
  setModalMultipleData(state, multipleData) {
    multipleData.forEach((item) => {
      state.modal.data[item.path] = structuredClone(item.value);
    });
  },
  setModalMultipleMeta(state, multipleMeta) {
    multipleMeta.forEach((item) => {
      state.modal.meta[item.path] = item.value;
    });
  },
  /* =======ModalInModal======== */
  setModalModalData(state, { path, value }) {
    state.modal.modal.data[path] = structuredClone(value);
  },
  setModalModalMeta(state, { path, value }) {
    state.modal.modal.meta[path] = value;
  },
  setModalModalMultipleData(state, multipleData) {
    multipleData.forEach((item) => {
      state.modal.modal.data[item.path] = structuredClone(item.value);
    });
  },
  setModalModalMultipleMeta(state, multipleMeta) {
    multipleMeta.forEach((item) => {
      state.modal.modal.meta[item.path] = item.value;
    });
  },
  setAsideData(state, { path, value }) {
    if (value) {
      state.aside.data[path] = typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value;
    } else {
      state.aside.data[path] = value;
    }
  },
  setAsideMeta(state, { path, value }) {
    state.aside.meta[path] = value;
  },
  setAsideMultipleData(state, multipleData) {
    multipleData.forEach((item) => {
      state.aside.data[item.path] = structuredClone(item.value);
    });
  },
  setAsideMultipleMeta(state, multipleMeta) {
    multipleMeta.forEach((item) => {
      state.aside.meta[item.path] = item.value;
    });
  },
  /* =======AsideInAside======== */
  setAsideAsideData(state, { path, value }) {
    state.aside.aside.data[path] = structuredClone(value);
  },
  setAsideAsideMeta(state, { path, value }) {
    state.aside.aside.meta[path] = value;
  },
  setAsideAsideMultipleData(state, multipleData) {
    multipleData.forEach((item) => {
      state.aside.aside.data[item.path] = structuredClone(item.value);
    });
  },
  setAsideAsideMultipleMeta(state, multipleMeta) {
    multipleMeta.forEach((item) => {
      state.aside.aside.meta[item.path] = item.value;
    });
  },
  /* ======= Menu ======== */
  setMenuData(state, { path, value }) {
    state.menu.data[path] = structuredClone(value);
  },
  setMenuMeta(state, { path, value }) {
    state.menu.meta[path] = value;
  },
  setMenuMultipleData(state, multipleData) {
    multipleData.forEach((item) => {
      state.menu.data[item.path] = structuredClone(item.value);
    });
  },
  setMenuMultipleMeta(state, multipleMeta) {
    multipleMeta.forEach((item) => {
      state.menu.meta[item.path] = item.value;
    });
  },
};
