<template>
  <div>
    <v-text-field
      v-model="value"
      density="compact"
      :label="label"
      color="primary"
      hide-details
      @update:model-value="update"
    >
      <template #append-inner>
        <v-btn
          color="primary"
          variant="tonal"
          size="x-small"
          :loading="barcodeLoading"
          @click="generateBarcode"
        >
          Сгенерировать
        </v-btn>
        <v-btn
          color="primary"
          variant="tonal"
          size="x-small"
          class="ml-2"
          @click="asideIsOpen = true"
        >
          <v-icon>
            mdi-cog
          </v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-navigation-drawer
      v-model="asideIsOpen"
      location="right"
      width="400"
      temporary
    >
      <v-card
        :elevation="0"
      >
        <v-card-title class="title">
          Генерация шк
        </v-card-title>
        <v-select
          v-model="type"
          type="number"
          label="Тип"
          variant="outlined"
          :items="types"
          item-title="name"
          item-value="id"
        ></v-select>
        <v-text-field
          v-model="length"
          type="number"
          label="Длина"
          variant="outlined"
        ></v-text-field>
        <v-btn
          color="primary"
          block
          :loading="barcodeLoading"
          @click="generateBarcode"
        >
          Сгенерировать
        </v-btn>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'BarcodeGenerator',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    organizationId: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      type: 'number',
      types: [
        { id: 'letter_and_number', name: 'Буквы и цифры' },
        { id: 'number', name: 'Цифры' },
        { id: 'letter', name: 'Буквы' },
      ],
      length: 10,
      value: '',
      asideIsOpen: false,
      barcodeLoading: false,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
  },
  beforeMount() {
    this.value = this.modelValue;
  },
  methods: {
    update() {
      this.$emit('update:modelValue', this.value);
    },
    generateBarcode() {
      this.barcodeLoading = true;
      this.$store.dispatch('barcode/generateBarcode', { length: this.length, type: this.type })
        .then((res) => {
          this.value = res.data.data.barcode;
          this.update();
        })
        .finally(() => {
          this.barcodeLoading = false;
        });
    },
  },
};
</script>