export default {
  namespaced: true,
  state: {
    isMobile: false,
  },
  mutations: {
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
  },
  getters: {
    isMobile: (state) => state.isMobile,

  },
};
