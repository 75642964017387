import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueApexCharts from 'vue3-apexcharts';
import store from './store';
import router from './router';
import App from './App.vue';

import './assets/css/reset.css';
import './assets/css/global.sass';
import './assets/css/font.sass';
import './assets/css/override-vuetify.sass';
import './assets/css/override-agGrid.sass';
import './assets/css/variables.css';

import vuetify from '@/plugins/vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createUiComponents } from '@/components/UiKit/createComponents';

const basePath = `${process.env.VUE_APP_API_BASE_PATH}`;
axios.defaults.baseURL = basePath;
const token = localStorage.getItem('token');
axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
store.commit('common/setBasePath', basePath);
axios.interceptors.response.use(
  (resp) => Promise.resolve(resp),
  // eslint-disable-next-line consistent-return
  (err) => {
    if (
      err.response?.status === 401
    ) {
      if (localStorage.getItem('refresh_token')
        && (router.currentRoute?.value?.name !== 'login' || router.currentRoute?.value?.name !== 'register')) {
        store.dispatch('auth/refresh')
          .then(() => {
            router.push('/');
          })
          .catch(() => {
            localStorage.removeItem('token');
            router.push({ name: 'login' });
          });
      } else if (!localStorage.getItem('refresh_token')) {
        router.push({ name: 'login' });
      }
    }
    return Promise.reject(err);
  },
);

const app = createApp(App);
createUiComponents(app);
app.config.unwrapInjectedRef = true;
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(vuetify);
app.use(VueApexCharts);
app.mount('#app');
