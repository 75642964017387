<template>
  <v-card>
    <v-card-title class="title mb-4">
      {{ label }}
    </v-card-title>
    <div class="pa-4 pt-0">
      <template v-for="(field, i) of Object.entries(fields)">
        <slot
          :name="`field.${i}`"
          :update="updateInnerValue"
          :field="field"
          :item="value[field[0]]"
          :value="value"
          :idx="i"
        >
          <v-text-field
            v-if="field[1].field_type === 'string'"
            v-model="value[field[0]]"
            min="0"
            class="my-2"
            density="compact"
            hide-details
            :label="field[1].label"
            @update:modelValue="updateInnerValue"
          />
          <v-text-field
            v-else-if="field[1].field_type === 'int' || field[1].field_type === 'number'"
            v-model="value[field[0]]"
            min="0"
            class="my-2"
            density="compact"
            type="number"
            hide-details
            :label="field[1].label"
            @update:modelValue="updateInnerValue"
          />
          <UiSelectSearch
            v-else-if="field[1].field_type === 'selectbox'"
            v-model="value[field[0]]"
            class="my-2"
            item-title="name"
            item-value="id"
            :items="field[1].values"
            density="compact"
            :multiple="field[1].is_collection"
            :max-count="field[1]?.max_count || 0"
            :allow-custom="field[1]?.allow_custom_values"
            :label="field[1].label"
            hide-details
            @update:modelValue="updateInnerValue"
          />
          <UiSelectboxProduct
            v-else-if="field[1].field_type === 'selectbox_organization_product'"
            v-model="value[field[0]]"
            :label="field[1].label"
            :items="field[1].values"
            density="default"
            variant="plain"
            :multiple="field[1].is_collection"
            :max-count="field[1]?.max_count || 0"
            hide-details
            :disabled="field[1].only_read"
            @update:modelValue="updateInnerValue"
          />
          <UiSelectboxCreate
            v-else-if="field[1].field_type === 'selectbox_organization_create'"
            v-model="value[field[0]]"
            :label="field[1].label"
            :items="field[1].values"
            density="default"
            variant="plain"
            :multiple="field[1].is_collection"
            :max-count="field[1]?.max_count || 0"
            hide-details
            :disabled="field[1].only_read"
            @update:modelValue="updateInnerValue"
          />
          <CarSelect
            v-else-if="field[1].field_type === 'selectbox_driver_car'"
            v-model="value[field[0]]"
            :items="field[1].values"
            @update:modelValue="updateInnerValue"
          />
          <DriverSelect
            v-else-if="field[1].field_type === 'selectbox_driver'"
            v-model="value[field[0]]"
            :label="field[1].label"
            :items="field[1].values"
            @update:modelValue="updateInnerValue"
          />
          <v-checkbox
            v-else-if="field[1].field_type === 'checkbox'"
            v-model="value[field[0]]"
            color="primary"
            hide-details
            @update:modelValue="updateInnerValue"
          />
          <defaultTableEdit
            v-else-if="field[1].field_type === 'default_table'"
            v-model="value[field[0]]"
            :fields="field[1]"
            @update:modelValue="updateInnerValue"
          />
          <Section
            v-else-if="field[1].field_type === 'section'"
            v-model="value[field[0]]"
            :fields="field[1]"
            @update:modelValue="updateInnerValue"
          />
          <storagePicker
            v-else-if="field[1].field_type === 'selectbox_storage'"
            v-model="value[field[0]]"
            :prop-items="field[1].values"
            :prop-areas="field[1].areas"
            :storages="field[1].storages"
            :label="field[1].label"
            density="compact"
            :multiple="false"
            @update:modelValue="updateInnerValue"
          />
          <div v-else />
        </slot>
      </template>
      <slot name="bottom" />
      <v-btn
        color="primary"
        class="mt-4"
        block
        @click="confirm"
      >
        Готово
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import DefaultTableEdit from '@/components/Tables/DefaultTable/components/edit/DefaultTableEdit.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import Section from '@/components/Section/edit/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import UiSelectSearch from '@/components/UiKit/Form/SelectboxSearch';
import UiSelectboxProduct from '@/components/UiKit/Form/SelectboxProduct/index.vue';
import UiSelectboxCreate from '@/components/UiKit/Form/SelectboxCreate/index.vue';
import ListChapters from '@/components/ListChapters/index.vue';

export default {
  name: 'CustomModal',
  components: {
    ListChapters,
    storagePicker,
    CarSelect,
    DriverSelect,
    DefaultTableEdit,
    Section,
    UiSelectSearch,
    UiSelectboxProduct,
    UiSelectboxCreate,
  },
  inject: ['storeModule'],
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    listParams: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    dataKey: {
      type: String,
    },
    selectedNodes: {
      type: Array,
      default: () => [],
    },
    isAllSelected: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: {},
    };
  },
  beforeMount() {
    this.value = JSON.parse(JSON.stringify(this.modelValue));
  },
  methods: {
    confirm() {
      if (!this.selection) {
        this.$emit('confirm', this.value);
        return;
      }
      this.$store.dispatch(`${this.storeModule}/selectionAction`, {
        type: this.dataKey,
        payload: {
          ids: this.selectedNodes,
          fields: this.value,
        },
        query: { isAll: this.isAllSelected },
        listParams: this.listParams,
      })
        .then(() => {
          this.$emit('confirm', this.value);
        });
    },
    updateInnerValue() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>