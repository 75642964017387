<template>
  <v-card>
    <v-card-title class="mb-4">
      <template v-if="isCopy">
        {{ label }} - копировать
      </template>
      <template v-else-if="!chapterValues">
        <q>{{ label }}</q> - создать
      </template>
      <template v-else>
        {{ label }} - редактировать
      </template>
    </v-card-title>

    <template
      v-for="([fieldName, field]) of fieldsValue"
    >
      <editable-field
        v-model="value[fieldName]"
        :fields="chapterFields"
        :field="field"
        :field-name="fieldName"
        :show-data-raw="showDataRaw"
        :loading="false"
        :can-be-read-only="false"
      ></editable-field>
    </template>

    <v-btn
      color="primary"
      block
      class="mt-4"
      size="small"
      :disabled="Object.entries(value).length === 0"
      @click="submit"
    >
      Готово
    </v-btn>
  </v-card>
</template>

<script setup>
import EditableField from '@/components/Field/EditableField/index.vue';
</script>

<script>

export default {
  name: 'DefaultTableEditCreateChapter',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    chapterFields: {
      type: Object,
      required: true,
    },
    chapterValues: {
      type: Object,
      default: null,
    },
    chapterItems: {
      type: Array,
      default: () => ([]),
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    showDataRaw: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    oldChapterName: {
      type: String,
      default: null,
    },
  },
  emits: ['add', 'copy'],
  data() {
    return {
      value: {},
      fieldsValue: [],
    };
  },
  created() {
    this.fieldsValue = Object.entries(JSON.parse(JSON.stringify(this.chapterFields)));
    this.fieldsValue.forEach(([fieldName, field]) => {
      if (field.field_type === 'checkbox') {
        this.value[fieldName] = false;
        return;
      }
      if (this.fields[fieldName].default_id) {
        this.value[fieldName] = this.fields[fieldName].default_id;
        return;
      }
      this.value[fieldName] = null;
    });
    if (this.chapterValues) {
      Object.entries(this.chapterValues).forEach(([key, val]) => {
        if (typeof +val === 'number') this.value[key] = +val;
        if (typeof val === 'string' && this.isCopy) this.value[key] = `${val} - копия`;
      });
    }
  },
  methods: {
    submit() {
      if (this.isCopy) {
        this.$emit('copy', this.value, this.chapterItems);
        return;
      }
      this.$emit(this.chapterValues ? 'edit' : 'add', this.value, this.oldChapterName);
    },
  },
};
</script>

<style lang="sass">
.v-card-title
  font-size: 20px !important
</style>