import { DefaultEntityStore } from '@/store/defaultEntityStore';
import { columns } from '@/store/modules/acceptance/columns';

const store = new DefaultEntityStore('acceptance');

const state = {
  ...store.state,
  // eslint-disable-next-line no-new-object
  columns,
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
