import { createQueryString } from '@/utils/utils';
import initialMutations from '@/store/initialMutations';

export default {
  namespaced: true,
  state: {
    loading: {
      file: false,
    },
  },
  mutations: { ...initialMutations },
  actions: {
    uploadFiles({ commit, dispatch }, { payload }) {
      commit('setLoading', { path: 'file', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: '/api/files/upload?',
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'file', value: false });
          });
      });
    },
    getPDFFile({ dispatch }, { entity, id, query }) {
      let url = `/api/${entity}/pdf/${id}?`;
      url = createQueryString(url, query);
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    getFile({ dispatch }, { entity, id, query }) {
      let url = `/api/${entity}/file/${id}?`;
      url = createQueryString(url, query);
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getExcelFile({ dispatch }, { entity, id, query }) {
      let url = `/api/${entity}/excel/${id}?`;
      url = createQueryString(url, query);
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    deleteFile({ dispatch }, { id }) {
      const url = `/api/uploadedFile/delete/${id}`;
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

  },
};