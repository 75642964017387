import initialMutations from '@/store/initialMutations';

export default {
  namespaced: true,
  state: {
    data: {
      templateList: [],
    },
    loading: {
      downloadFile: false,
      sendFile: false,
      excelTemplates: false,
    },
  },
  actions: {
    getExcelSample({ dispatch, commit }, module) {
      const url = `/api/${module}/excel/sample`;
      commit('setLoading', { path: 'downloadFile', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'downloadFile', value: false });
          });
      });
    },

    sendExcelSample({ dispatch, commit }, { module, payload }) {
      const url = `/api/${module}/excel/sample`;
      commit('setLoading', { path: 'sendFile', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'sendFile', value: false });
          });
      });
    },

    sendShortExcelSample({ dispatch, commit }, { module, payload, organizationId }) {
      const url = `/api/${module}/organization/${organizationId}/excel`;
      commit('setLoading', { path: 'sendFile', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'sendFile', value: false });
          });
      });
    },

    getExcelTemplates({ dispatch, commit }) {
      commit('setLoading', { path: 'excelTemplates', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: '/api/excelTemplate/list',
        }, { root: true })
          .then((res) => {
            commit('setData', { path: 'templateList', value: res.data.data.items });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'excelTemplates', value: false });
          });
      });
    },

    createExcelTemplate({ dispatch, commit }, { payload }) {
      commit('setLoading', { path: 'excelTemplates', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'put',
          query: '/api/excelTemplate/create',
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'excelTemplates', value: false });
          });
      });
    },

    updateExcelTemplate({ dispatch, commit }, { payload }) {
      commit('setLoading', { path: 'excelTemplates', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'put',
          query: '/api/excelTemplate/update',
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'excelTemplates', value: false });
          });
      });
    },
  },
  mutations: {
    ...initialMutations,
  },
  getters: {
    downloadLoading: (s) => s.loading.downloadFile,
    sendLoading: (s) => s.loading.sendFile,
    excelTemplatesLoading: (s) => s.loading.excelTemplates,
  },
};