<template>
  <div class="excelLoad">
    <template v-if="listExport">
      <div class="excelLoad__buttons">
        <v-btn
          color="success-dark"
          :loading="excelLoading"
          @click="loadList"
        >
          Выгрузить в Excel
        </v-btn>
        <v-btn
          color="success-dark"
          variant="tonal"
          @click="openExcelColumnsModal"
        >
          <v-icon>
            mdi-cog
          </v-icon>
        </v-btn>
      </div>
    </template>
    <template v-if="formattedSample">
      <template v-for="s of formattedSample">
        <v-card
          elevation="10"
          class="mb-2 mx-2 px-0"
        >
          <v-card-title class="title">
            {{ s.name }}
          </v-card-title>
          <hr>
          <div class="excelLoad__block">
            <div class="excelLoad__flex">
              <p>Шаблон</p>
              <v-btn
                size="small"
                color="primary"
                :loading="downloadLoading === s.sample"
                @click="downloadTemplate(s.sample)"
              >
                Скачать
              </v-btn>
            </div>
          </div>
          <hr>
          <div class="excelLoad__block">
            <v-file-input
              v-model="file[s.sample]"
              label="Загрузить"
              density="compact"
              hide-details
              :accept="accept"
              :loading="sendLoading === s.sample"
              color="primary"
              @update:modelValue="createFromExcel($event, s.sample)"
            />
          </div>
          <hr>
        </v-card>
      </template>
    </template>
    <v-dialog
      v-model="isPopup"
      width="800"
    >
      <ColumnsModal
        v-if="popupName === 'columns'"
        :columns="columns"
        @sumbit="loadList($event); closePopup()"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getBase64 } from '@/utils/utils';
import { popupMixin } from '@/mixins/popup';
import ColumnsModal from '@/components/ExcelLoad/components/ColumnsModal/index.vue';

export default {
  name: 'ExcelLoad',
  components: { ColumnsModal },
  mixins: [popupMixin],
  inject: ['storeModule'],
  props: {
    sample: {
      type: String || Array,
      required: true,
    },
    postSample: {
      type: String,
      required: true,
    },
    asideData: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'excel',
    },
    listExport: {
      type: Boolean,
      default: false,
    },
    listParams: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: ['setPayload'],
  data() {
    return {
      file: {},
      downloadLoading: false,
      sendLoading: false,
    };
  },
  computed: {
    accept() {
      return this.type === 'excel' ? '.xlsx,.xls,.csv' : '.pdf';
    },
    excelLoading() {
      return this.$store.getters[`${this.storeModule}/excelLoading`];
    },
    isArraySample() {
      return Array.isArray(this.sample);
    },
    formattedSample() {
      if (!this.isArraySample) return [{ name: 'Загрузка файлом', sample: { excel_sample: this.sample?.excel_sample, body: this.sample?.body || {} } }];
      return this.sample.map((el) => ({ ...el, sample: { excel_sample: el.excel_sample, body: el.body } }));
    },
  },
  beforeUnmount() {
    this.$store.dispatch(`${this.storeModule}/clearAsideData`);
  },
  methods: {
    downloadTemplate(sample) {
      const s = sample.excel_sample;
      const action = this.type === 'excel' ? 'excel/getExcelSample' : 'pdf/getPdfTemplate';
      this.downloadLoading = s;
      this.$store.dispatch(action, s)
        .then((res) => {
          const f = res.data.data[`${this.type}_sample`];
          const a = document.createElement('a');
          const mediaType = this.type === 'excel' ? 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' : 'data:application/pdf;base64,';
          const fileFormat = this.type === 'excel' ? 'xlsx' : 'pdf';

          a.href = `${mediaType}${f}`;
          a.download = `${res.data.data.file_name}` ?? `Шаблон.${fileFormat}`;
          a.click();
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    async createFromExcel(val, sample) {
      const s = sample.excel_sample;
      const body = sample.body || {};
      this.sendLoading = s;
      this.file[s] = [val];
      const f = await getBase64(this.file[s][0]);
      let method;
      if (this.type === 'excel') {
        method = this.asideData.excel_type !== 'short' ? 'excel/sendExcelSample' : 'excel/sendShortExcelSample';
      } else {
        method = 'pdf/sendPdfTemplate';
      }

      const payload = { ...body };

      if (this.type === 'excel') payload.excel_sample = String(f).replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
      else payload.pdf_sample = String(f).replace('data:application/pdf;base64,', '');

      this.$store.dispatch(method, {
        module: s,
        payload,
      })
        .then((res) => {
          this.$store.commit('ajax/addMessage', { message: 'Загружено', type: 'text' });
          this.$store.commit(`${this.storeModule}/setAsideMeta`, { path: 'isOpen', value: false });
          this.$emit('setPayload', res.data?.data);
        })
        .finally(() => {
          this.file[sample] = null;
          this.sendLoading = false;
        });
    },
    loadList(columnFields = []) {
      this.$store.dispatch(`${this.storeModule}/getExcelList`, { listParams: this.listParams, columnFields })
        .then((data) => {
          const f = data.excel_base64;
          const a = document.createElement('a');
          const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
          const fileFormat = 'xlsx';

          a.href = `${mediaType}${f}`;
          a.download = `${data.file_name}` ?? `Шаблон.${fileFormat}`;
          a.click();
        });
    },
    openExcelColumnsModal() {
      this.openPopup('columns');
    },
  },
};
</script>

<style lang="sass">
.excelLoad
  text-align: start
  &__buttons
    display: flex
    display: grid
    grid-template-columns: 80% 20%
    gap: 10px
    padding: 25px
  &__block
    padding: 20px
  &__flex
    display: flex
    align-items: center
    gap: 10px
</style>
