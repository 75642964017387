export const popupMixin = {
  data() {
    return {
      popup: {
        meta: { isOpen: false, name: '', width: 650 },
        data: { },
      },
    };
  },
  computed: {
    isPopup: {
      get() {
        return this.popup.meta.isOpen;
      },
      set(val) {
        this.popup.meta.isOpen = val;
      },
    },
    popupName: {
      get() {
        return this.popup.meta.name;
      },
      set(val) {
        this.popup.meta.name = val;
      },
    },
    popupWidth: {
      get() {
        return this.popup.meta.width;
      },
      set(val) {
        this.popup.meta.width = val;
      },
    },
    popupData: {
      get() {
        return this.popup.data;
      },
      set(val) {
        this.popup.data = val;
      },
    },
  },
  methods: {
    openPopup(name, data, meta) {
      this.popup.meta = { ...meta, name, isOpen: true };
      this.popup.data = { ...data };
    },
    closePopup() {
      this.popup.meta.isOpen = false;
      this.popup.meta.name = '';
      this.popup.data = {};
    },
  },
};