<template>
  <tr v-if="value">
    <td
      v-if="fields.organization_product_from_id"
      style="padding-left: 70px"
    >
      <UiSelectboxConsignment
        v-if="parentProductFrom?.is_need_consignment && value.organization_product_from"
        v-model="value.organization_product_from.id"
        :label="fields['organization_product_from_id']?.label"
        :items="parentProductFrom?.session_product_values"
        density="compact"
        :multiple="fields['organization_product_from_id'].is_collection"
        :max-count="fields['organization_product_from_id']?.max_count || 0"
        hide-details
        :is-consignment="!!parentProductFrom?.is_need_consignment"
        :readonly="(readOnly && fields['organization_product_from_id'].only_read !== false) || fields['organization_product_from_id'].only_read"
        @update:modelValue="update(); value.organization_product_from_id = $event"
      />
    </td>
    <td
      class="d-grid align-center"
      :style="!getOrganizationProductFrom(parentValue.main_organization_product_from?.id)?.is_need_consignment ? 'padding-left: 70px' : ''"
    >
      <UiSelectboxConsignment
        v-if="value.organization_product"
        v-model="value.organization_product.id"
        :label="fields['organization_product_id']?.label"
        :items="organizationProductItems"
        density="compact"
        :multiple="fields['organization_product_id'].is_collection"
        :max-count="fields['organization_product_id']?.max_count || 0"
        hide-details
        :is-consignment="!!parentProduct?.is_need_consignment"
        :readonly="
          (readOnly && fields['organization_product_id'].only_read !== false)
            || fields['organization_product_id'].only_read
            || !isOrganizationProductEnabled
        "
        @update:modelValue="value.organization_product_id = $event; value.price = organizationProduct?.price; update();"
      />
    </td>
    <td v-if="fields.count">
      <v-text-field
        v-model="value['count']"
        :label="fields['count']?.label"
        min="0"
        density="compact"
        type="number"
        hide-details
        :disabled="
          (readOnly && fields['count']?.only_read !== false) ||
            fields['count']?.only_read"
        @update:modelValue="update"
      >
        <template #append-inner>
          <v-btn
            v-if="isMarking"
            color="primary"
            variant="tonal"
            size="x-small"
            @click.stop="openMarkedProductsModal(organizationProduct)"
          >
            <v-icon>mdi-qrcode-scan</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </td>
    <td v-if="fields.count_plan">
      <v-text-field
        v-model="value['count_plan']"
        :label="fields['count_plan']?.label"
        min="0"
        density="compact"
        type="number"
        hide-details
        :readonly="(readOnly && fields['count_plan']?.only_read !== false) || fields['count_plan']?.only_read"
        @update:modelValue="update();"
      />
    </td>
    <td v-if="fields.count_not_distributed">
      <v-text-field
        :model-value="undistributedCount"
        :label="fields['count_not_distributed']?.label"
        min="0"
        density="compact"
        type="number"
        hide-details
        :readonly="(readOnly && fields['count_not_distributed']?.only_read !== false) || fields['count_not_distributed']?.only_read"
        @update:modelValue="update"
      />
    </td>
    <td
      v-for="(field, j) of Object.entries(payloadFields)"
      :key="j"
      style="min-width: 100px"
    >
      <PayloadField
        :key="j"
        :product="value"
        :field="field[1]"
        :field-name="field[0]"
        :readonly="(readOnly && fields[field[0]]?.only_read !== false) || fields[field[0]]?.only_read"
        :index="j"
        @update:product="value = $event"
      />
    </td>
    <td>
      <v-btn
        v-if="(!readOnly && !value.canDelete) || canDeleteConsignment "
        size="extra-small"
        class="text-caption px-2"
        color="error"
        icon="mdi-close"
        @click="delSessionProduct()"
      />
    </td>
  </tr>
</template>

<script>
import UiSelectboxConsignment from '@/components/UiKit/Form/SelectboxConsignment/index.vue';
import PayloadField
  from '@/components/Tables/MainProducts/edit/components/TableBody/components/TableRow/components/PayloadField.vue';

export default {
  name: 'SessionProductRow',
  components: {
    PayloadField,
    UiSelectboxConsignment,
  },
  inject: ['fields', 'canCreateSessionProduct', 'storeModule'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    parentValue: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    payloadFields: {
      type: Object,
      default: () => ({}),
    },
    isParentConsignment: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:product', 'delSessionProduct', 'update:field', 'openMarkedModal'],
  data() {
    return {
      value: {},
    };
  },
  computed: {
    showData() {
      return this.$store.getters[`${this.storeModule}/showData`];
    },
    canChangeCountConsignment() {
      return this.canCreateSessionProduct && this.product.is_new;
    },
    canDeleteConsignment() {
      return this.canCreateSessionProduct && this.product.is_new;
    },
    organizationProduct() {
      return this.fields.organization_product_id.values.find((el) => el?.id === this.value?.organization_product?.id);
    },
    organizationProductFrom() {
      if (!this.parentProductFrom?.session_product_values) return null;
      return this.parentProductFrom?.session_product_values?.find((el) => el?.id === this.value?.organization_product_from?.id);
    },
    parentProduct() {
      return this.$parent.organizationProduct;
    },
    parentProductFrom() {
      return this.$parent.organizationProductFrom;
    },
    isOrganizationProductEnabled() {
      return this.$parent.isOrganizationProductEnabled();
    },
    isMarking() {
      return !!this.organizationProduct?.is_marking;
    },
    organizationProductItems() {
      if (this.fields.organization_product_from_id) {
        return this.organizationProductFrom?.organization_product_id_values;
      }
      return this.isParentConsignment
        ? this.parentProduct?.session_product_values
        : this.parentProduct?.organization_product_id_values ?? this.fields.organization_product_id?.values;
    },
    undistributedCount() {
      const initialCount = this.value.count_plan;
      let newCount = 0;
      let value = initialCount;

      if (this.showData?.[this.fields.child_entity_field]) {
        this.showData?.[this.fields.child_entity_field]?.forEach((upperItem) => {
          const product = upperItem?.main_products?.find((pr) => pr.main_organization_product?.id === this.parentProduct?.id);
          if (!product) return;
          const sessionProduct = product?.session_products?.find((pr) => pr.organization_product?.id === this.value?.organization_product?.id);
          newCount += sessionProduct.count_plan > 0 ? sessionProduct.count_plan : sessionProduct.count;
        });
        value = initialCount - newCount;
      }
      value = value > 0 ? value : 0;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.value.count_not_distributed = value;
      return value;
    },
  },
  watch: {
    product() {
      this.value = { ...this.product };
    },
  },
  beforeMount() {
    this.value = { ...this.product };
  },
  methods: {
    getOrganizationProductFrom(id) {
      if (!this.fields.organization_product_from_id) return {};
      return this.fields.organization_product_from_id.values.find((el) => el?.id === id);
    },
    delSessionProduct() {
      this.$emit('delSessionProduct');
    },
    formatValue() {
      if (this.value.organization_product) this.value.organization_product_id = this.value.organization_product?.id;
      if (this.value.organization_product_from) this.value.organization_product_from_id = this.value.organization_product_from?.id;
    },
    update() {
      this.formatValue();
      this.$nextTick(() => {
        this.$emit('update:product', this.value);
      });
    },
    // onCountPlanUpdate(count) {
    //   if (this.organizationProduct?.id === this.parentProduct?.id) {
    //     this.updateParentField('count_plan', count);
    //   }
    // },
    // updateParentField(key, value) {
    //   this.$emit('update:field', { key, value });
    // },
    openMarkedProductsModal(product) {
      this.$emit('openMarkedModal', product);
    },
  },
};
</script>