import { getFormattedTime } from '@/utils/utils';
import status from '@/pages/workerList/components/status.vue';
import activeJob from '@/pages/workerList/components/activeJob.vue';
import worker from '@/pages/workerList/components/worker.vue';
import actions from '@/pages/workerList/components/actions.vue';


export const columns = [
  {
    headerName: 'Имя',
    field: 'worker_full_name',
    width: 250,
    cellRenderer: worker,
  },
  {
    headerName: 'Должность',
    field: 'job_title_name',
  },
  {
    headerName: 'Статус',
    field: 'status',
    cellRenderer: status,
  },
  {
    headerName: 'Активная работа',
    field: 'has_active_job',
    cellRenderer: activeJob,
  },
  {
    headerName: 'Зарплата',
    field: 'sum',
  },
  {
    headerName: 'Рабочее время',
    field: 'time_of_work_on_day_in_minutes',
    valueFormatter: ({ value }) => getFormattedTime(value),
  },
  {
    headerName: 'Действия',
    field: 'actions',
    cellRenderer: actions,
  },
];
