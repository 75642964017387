import Input from './Form/Input/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import Tooltip from '@/components/UiKit/Tooltip/index.vue';
import SelectboxProduct from '@/components/UiKit/Form/SelectboxProduct/index.vue';
import DatePicker from '@/components/UiKit/Form/DatePicker/index.vue';
import SelectboxOrganization from '@/components/UiKit/Form/SelectboxOrganization/index.vue';
import SelectboxConsignment from '@/components/UiKit/Form/SelectboxConsignment/index.vue';

export function createUiComponents(app) {
  app.component('UiInput', Input);
  app.component('UiSelectSearch', InputSearch);
  app.component('UiTooltip', Tooltip);
  app.component('UiSelectboxProduct', SelectboxProduct);
  app.component('UiDatePicker', DatePicker);
  app.component('UiSelectboxOrganization', SelectboxOrganization);
  app.component('UiSelectboxConsignment', SelectboxConsignment);
}