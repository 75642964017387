<template>
  <v-text-field
    v-if="field[1].field_type === 'string' || field[1].field_type === 'text'"
    v-model="item[field[0]]"
    :label="label"
    min="0"
    class="my-2"
    density="compact"
    hide-details
    :disabled="disabled"
    @update:modelValue="onUpdate({field, item})"
  />
  <v-text-field
    v-else-if="field[1].field_type === 'int' || field[1].field_type === 'number'"
    v-model="item[field[0]]"
    :label="label"
    min="0"
    class="my-2"
    density="compact"
    type="number"
    hide-details
    :disabled="disabled"
    @update:modelValue="onUpdate({field, item})"
  />

  <template v-else-if="field[1].field_type === 'datetime'">
    <DatePicker
      v-model="item[field[0]]"
      time
      clearable
      :label="label"
      :disabled="disabled"
    />
  </template>

  <InputSearch
    v-else-if="field[1].field_type === 'selectbox'"
    v-model="item[field[0]]"
    :label="label"
    class="my-2"
    :items="field[1].values"
    density="compact"
    :multiple="field[1].is_collection"
    :max-count="field[1]?.max_count || 0"
    hide-details
    :disabled="disabled"
    @update:modelValue="onUpdate({field, item, from: 'select'})"
  />

  <template v-else-if="field[1].field_type === 'uploaded_file'">
    <UploadedFile
      v-model="item[field[0]]"
      :accept="field[1].file_extension"
      :disabled="field[1].only_read"
      :label="label"
      :multiple="!!field[1]?.is_collection"
      :raw-data="!item.id ?item['rawData'] : !field[1]?.is_collection ? getRawItem(i)?.[field[0]]?.value : getRawItem(i)?.[field[0]]?.values"
      @update:modelValue="onUpdate({field, item})"
      @update:raw="onUpdateRaw(i, field[0], $event); item['rawData'] = $event"
    />
  </template>

  <template
    v-else-if="field[1].field_type ==='gallery'"
  >
    <Gallery
      v-model="item[field[0]]"
      :photo-array="getRawItem(i)?.[field[0]]?.values || []"
      :label="label"
      @update:modelValue="onUpdateRaw(i, field[0], $event); item['rawData'] = $event"
    >
      <template #hint>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </Gallery>
  </template>


  <CarSelect
    v-else-if="field[1].field_type === 'selectbox_driver_car'"
    v-model="item[field[0]]"
    :items="field[1].values"
    :label="label"
    @update:modelValue="onUpdate({field, item})"
  />
  <DriverSelect
    v-else-if="field[1].field_type === 'selectbox_driver'"
    v-model="item[field[0]]"
    :items="field[1].values"
    :label="label"
    @update:modelValue="onUpdate({field, item})"
  />
  <template
    v-else-if="field[1].field_type === 'checkbox'"
  >
    <v-checkbox
      v-model="item[field[0]]"
      color="primary"
      hide-details
      :label="label"
      :disabled="disabled"
      @update:modelValue="onUpdate({field, item})"
    />
  </template>

  <template v-else-if="field[1].field_type === 'box_size_select'">
    <BoxSizeSelect
      v-model="item[field[0]]"
      :items="field[1].values"
    />
  </template>

  <DefaultTableEdit
    v-else-if="field[1].field_type === 'default_table'"
    v-model="item[field[0]]"
    :fields="field[1]"
    :disabled="disabled && field[1].only_read !== false"
    :read-only="disabled"
    :payload-parent="fieldName"
    :field-name="field[0]"
    :inner-index="i"
    :loading="loading"
    :is_chapter="false"
    :raw-data="getRawItem(i)?.[field[0]]?.values ?? item['rawData']"
    :upper-field="fields"
    @set-watch-query="onSetWatchQuery"
    @update:modelValue="onUpdate({field, item})"
    @update:raw="onUpdateRaw(i, field[0], $event); item['rawData'] = $event"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </DefaultTableEdit>

  <DefaultTableEdit
    v-else-if="field[1].field_type === 'default_table_chapters'"
    v-model="item[field[0]]"
    :fields="field[1]"
    :disabled="disabled && field[1].only_read !== false"
    :read-only="disabled"
    :payload-parent="fieldName"
    :field-name="field[0]"
    :inner-index="i"
    :is_chapter="true"
    :loading="loading"
    :raw-data="getRawItem(i)?.[field[0]]?.values ?? item['rawData']"
    style="border-radius: 0"
    :upper-field="fields"
    @set-watch-query="onSetWatchQuery"
    @update:chapter="$emit('update:chapter', $event)"
    @update:modelValue="onUpdate({field, item})"
    @update:raw="onUpdateRaw(i, field[0], $event); item['rawData'] = $event"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </DefaultTableEdit>

  <template
    v-else-if="field[1].field_type === 'main_products'"
  >
    <main-products-edit
      :key="field[0]"
      v-model="item[field[0]]"
      :raw-data="getRawItem(i)?.[field[0]] || []"
      :fields="field[1]"
      :field-name="fieldName"
      :read-only="field[1].only_read"
      :upper-item="item"
      @update:modelValue="onUpdate({field, item})"
    />
  </template>

  <Section
    v-else-if="field[1].field_type === 'section'"
    v-model="item[field[0]]"
    :fields="field[1]"
    :disabled="disabled && field[1].only_read !== false"
    :read-only="disabled"
    @update:modelValue="onUpdate({field, item})"
  />

  <StoragePicker
    v-else-if="field[1].field_type === 'selectbox_storage'"
    :key="item.id"
    v-model="item[field[0]]"
    :prop-items="field[1].values"
    :prop-areas="field[1].areas"
    :storages="field[1].storages"
    density="compact"
    :multiple="false"
    :disabled="disabled"
    @update:modelValue="onUpdate({field, item})"
    @update:path="item['storage_path'] = $event"
  />

  <SelectboxProduct
    v-else-if="field[1].field_type === 'selectbox_organization_product'"
    v-model="item[field[0]]"
    :label="label"
    class="my-2"
    :items="field[1].values"
    density="compact"
    :multiple="field[1].is_collection"
    :max-count="field[1]?.max_count || 0"
    hide-details
    :disabled="disabled"
    @update:modelValue="onUpdate({field, item})"
  />

  <SelectboxCreate
    v-else-if="field[1].field_type === 'selectbox_create'"
    v-model="item[field[0]]"
    :field="field[1]"
    :label="label"
    class="my-2"
    :items="field[1].values"
    density="compact"
    :multiple="field[1].is_collection"
    :max-count="field[1]?.max_count || 0"
    hide-details
    :disabled="disabled"
    @update:modelValue="onUpdate({field, item})"
  />

  <IntegrationsModal
    v-else-if="field[1].field_type === 'organization_integrations'"
    v-model="item[field[0]]"
    :field="field[1]"
    :name="item['name']"
    :is-create="!item['id']"
    @update:modelValue="onUpdate({field, item})"
  />

  <BarcodeGenerator
    v-else-if="field[1].field_type === 'string_barcode_generate'"
    :key="i"
    v-model="item[field[0]]"
    :label="label"
    @update:modelValue="onUpdate({field, item})"
  />

  <div v-else />
</template>

<script setup>
import IntegrationsModal from '@/components/Organizations/integrations/modal.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import Help from '@/components/UiKit/Help/index.vue';
import SelectboxProduct from '@/components/UiKit/Form/SelectboxProduct/index.vue';
import UploadedFile from '@/components/UiKit/UploadedFile/index.vue';
import DatePicker from '@/components/UiKit/Form/DatePicker/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import MainProductsEdit from '@/components/Tables/MainProducts/edit/index.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import Section from '@/components/Section/edit/index.vue';
import BarcodeGenerator from '@/components/UiKit/BarcodeGenerator/index.vue';
import Gallery from '@/components/UiKit/Gallery/index.vue';
import DefaultTableEdit from '@/components/Tables/DefaultTable/components/edit/DefaultTableEdit.vue';
import StoragePicker from '@/components/UiKit/StoragePicker/index.vue';
import SelectboxCreate from '@/components/UiKit/Form/SelectboxCreate/index.vue';
</script>


<script>
export default {
  name: 'DefaultTableField',
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  beforeMount() {
    this.item = this.modelValue;
  },
  methods: {
    update() {
      this.$emit('update:modelValue', this.item);
    },
    onUpdate() {
      this.update();
    },
  },
};
</script>