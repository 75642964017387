<template>
  <v-btn
    size="small"
    color="primary"
    variant="tonal"
    @click="toWorkerProfile"
  >
    Перейти
  </v-btn>
</template>

<script>
export default {
  name: 'WorkerListActions',
  methods: {
    toWorkerProfile() {
      this.$router.push({ name: 'workerProfileShow', params: { workerId: this.params.data.worker_id }, query: this.$route.query });
    },
  },
};
</script>