import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { ru } from 'vuetify/locale';
import DateFnsAdapter from '@date-io/date-fns';
import ruRu from 'date-fns/locale/ru';

import themes from '@/themes';

const themeObj = {};
themes.forEach((theme) => {
  themeObj[theme.name] = theme;
});

export default createVuetify({
  theme: {
    locale: {
      locale: 'ru',
      messages: { ru },
    },
    defaultTheme: 'advanced',
    themes: themeObj,
  },
  date: {
    options: {
      adapter: DateFnsAdapter,
      locale: {
        ru: ruRu,
      },
    },
    instance: {
      locale: {
        ru: ruRu,
      },
    },
  },
  components,
  directives,
});
