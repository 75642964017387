export const columns = [
  {
    field: 'type',
    headerName: 'Тип',
    sortable: true,
  },
  {
    field: 'organization_id',
    headerName: 'Организация',
    sortable: true,
  },
  {
    field: 'comment',
    headerName: 'Коммент',
    sortable: true,
    cellStyle: { 'text-overflow': 'ellipsis' },
  },
  {
    field: 'created_at',
    headerName: 'Дата создания',
    width: 170,
    sortable: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString('ru-RU'),
  },
  // {
  //     field: 'created_from_id',
  //     headerName: 'created_from_id',
  //     sortable: true,
  // },
  // {
  //     field: 'is_archive',
  //     headerName: 'is_archive',
  //     sortable: true,
  // },
  {
    field: 'responsible_user_id',
    headerName: 'Ответственный пользователь',
    sortable: true,
  },
  {
    field: 'status',
    headerName: 'Статус',
    sortable: true,
  },
  {
    field: 'status1_c',
    headerName: 'Статус 1С',
    sortable: true,
  },
  {
    field: 'storage_id',
    headerName: 'Склад',
    sortable: true,
  },
  {
    headerName: 'Действия',
    cellRenderer: 'action',
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'clearFilter',
        },
        { filter: 'agNumberColumnFilter' },
      ],
    },

  },
];
