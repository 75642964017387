<template>
  <v-select
    v-model="modelValue"
    :items="availableOptions"
    item-title="title"
    item-value="key"
    class="worker-list-status-cell"
    variant="solo"
    density="compact"
    :no-data-text="'Нет данных'"
    :disabled="!availableOptions.length"
  />
</template>

<script>
export default {
  name: 'StatusRendered',
  data() {
    return {
      initialValue: '',
      options: [
        { key: 'active', title: 'Активно', canChangeTo: ['closed', 'suspended'] },
        { key: 'suspended', title: 'Приостановлено', canChangeTo: ['closed', 'active'] },
        { key: 'closed', title: 'Закрыто', canChangeTo: [] },
      ],
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.options.find((o) => o.key === this.params.value) || { key: 'undefined', title: 'Неизвестно' };
      },
      set(status) {
        this.params.node.setDataValue('status', status);
        this.$store.dispatch(`${this.params.context.componentParent.storeModule}/setStatus`, {
          status,
          workerId: this.params.data.worker_id,
        });
      },
    },
    availableOptions() {
      const selectedArr = this.options.filter((el) => this.modelValue.canChangeTo.includes(el.key)) || [];
      let initialArr = this.options.find((el) => el.key === (this.initialValue))?.canChangeTo;
      initialArr = initialArr.map((el) => this.options.find((option) => option.key === el)).filter((el) => !selectedArr.find((e) => e.key === el.key)) || [];
      return [...selectedArr, ...initialArr];
    },
  },
  beforeMount() {
    this.initialValue = this.modelValue.key;
  },
};
</script>

<style>
.worker-list-status-cell > div.v-input__details {
  display: none;
}
.worker-list-status-cell .v-field {
  border-radius: 0;
}
</style>
