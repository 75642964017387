export const payloadMixin = {
  inject: ['storeModule'],
  computed: {
    payload: {
      get() {
        return this.$store.getters['payload/payload']?.[this.storeModule] || {};
      },
      set(val) {
        this.$store.commit('payload/setPayload', { payload: val, storeModule: this.storeModule });
      },
    },
  },
  methods: {
    setPayload() {
      this.$store.commit('payload/setPayload', { payload: this.payload, storeModule: this.storeModule });
    },
    setPayloadField({ key, value }) {
      this.$store.commit('payload/setPayloadField', { key, value, storeModule: this.storeModule });
    },
    clearPayload() {
      this.$store.commit('payload/clearPayload', this.storeModule);
    },
  },
};