<template>
  <div class="worker-name">
    <v-btn
      v-if="params.data.count_not_filed_jobs > 0"
      :id="`count_not_filed_jobs-${params.node.rowIndex}`"
      color="red-darken-2"
      icon="mdi-alert-circle"
      size="x-small"
      variant="tonal"
    />
    <v-menu
      :activator="`#count_not_filed_jobs-${params.node.rowIndex}`"
      transition="none"
      open-on-hover
    >
      <v-card
        class="pa-4"
        color="error"
      >
        Количество незаполненных работ {{ params.data.count_not_filed_jobs }}
      </v-card>
    </v-menu>
    <v-btn
      v-if="!params.data.has_jobs"
      :id="`has_jobs-${params.node.rowIndex}`"
      color="yellow-darken-2"
      icon="mdi-alert-circle"
      size="x-small"
      variant="tonal"
    />
    <v-menu
      :activator="`#has_jobs-${params.node.rowIndex}`"
      transition="none"
      open-on-hover
    >
      <v-card
        class="pa-4"
        color="warning"
      >
        Нет начатой работы
      </v-card>
    </v-menu>
    <span class="name">{{ params.value }}</span>
  </div>
</template>

<script>
export default {
  name: 'WorkerListNameWorker',
};
</script>

<style lang="css">
.worker-name {
  display: flex;
  align-items: center;
  gap: 8px;
}
.worker-name > span.name {
  font-weight: 500;
}
</style>
