import initialMutations from '@/store/initialMutations';

export default {
  namespaced: true,
  state: {
    data: {
      requirements: [],
    },
    loading: {
      requirements: false,
    },
  },
  mutations: { ...initialMutations },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getRequirements({ commit, dispatch }, payload) {
      commit('setLoading', { path: 'requirements', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: `api/requirements/${payload.organization_id}/get?date_from=${payload.date_from}&delivery_date=${payload.delivery_date}`,
        }, { root: true })
          .then((res) => {
            commit('setData', { path: 'requirements', value: res.data.data });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'requirements', value: false });
          });
      });
    },
  },
};