import { DefaultEntityStore } from '@/store/defaultEntityStore';
import initialMutations from '@/store/initialMutations';

const store = new DefaultEntityStore('shipment');

const state = {
  ...store.state,
  data: {
    products: [],
    pallets: [],
    palletBoxes: [],
  },
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,

  getShipmentProducts({ commit, dispatch }, { id, countRemnant, palletId }) {
    let url = `api/shipment/${id}/shipment-products?`;
    if (countRemnant !== undefined) url += `countRemnant=${countRemnant}&`;
    if (palletId) url += `palletId=${palletId}`;
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: url,
      }, { root: true })
        .then((res) => {
          commit('setData', { path: 'products', value: res.data.data.shipment_products });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getShipmentPallets({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `api/shipment/${id}/pallets`,
      }, { root: true })
        .then((res) => {
          commit('setData', { path: 'pallets', value: res.data.data.pallets });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  bindPalletProducts({ dispatch, commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/shipment/pallet/${id}/shipment-product/change`,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          const pallet = res.data.data.pallet;
          commit('updatePallet', { pallet, productId: payload[0].id });

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  changeShipmentPallet({ dispatch, commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/shipment/${id}/pallet/change`,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          if (payload.id === 0) {
            commit('addNewPallet', res.data.data.pallet);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPalletBoxes({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `api/shipment/pallet/${id}/box/available`,
      }, { root: true })
        .then((res) => {
          commit('setData', { path: 'palletBoxes', value: res.data.data.boxes });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendAcceptedFiles({ dispatch }, { id, organizationId, payload }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/shipment/${id}/organization/${organizationId}/excel/accepted-file`,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPlannedMainProducts({ dispatch, commit }, { payload, organizationId }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/shipment/organization/${organizationId}/planned-main-products/get`,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          commit('payload/setPayloadField', { storeModule: 'shipment', key: 'planned_main_products', value: res.data.data.planned_main_products }, { root: true });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
const mutations = {
  ...store.mutations,
  ...initialMutations,
  // eslint-disable-next-line no-shadow
  addNewPallet(state, pallet) {
    state.data.pallets.push(pallet);
    state.data.pallets = [...state.data.pallets];
  },
  // eslint-disable-next-line no-shadow
  updatePallet(state, { pallet, productId = null }) {
    state.data.pallets = state.data.pallets.map((p) => {
      if (p.id === pallet.id) {
        // p = { ...pallet, ...p };
        pallet.shipment_products.forEach((pr) => {
          if (pr.id === productId) {
            pr.isOpen = true;
          }
        });
        p = { ...pallet, isOpen: p.isOpen };
      }
      return p;
    });
  },
  // eslint-disable-next-line no-shadow
  addNewProduct(state, product) {
    state.data.products.push(product);
  },
  // eslint-disable-next-line no-shadow
  updateProduct(state, product) {
    state.data.products = state.data.products.map((p) => {
      if (p.id === product.id) {
        // p = { ...product, ...p };
        p = { ...product, isOpen: p.isOpen };
      }
      return p;
    });
  },
  // eslint-disable-next-line no-shadow
  setProducts(state, products) {
    state.data.products = products;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
