export default {
  namespaced: true,
  state: {
    periods: [],
    tariffs: [],
  },
  mutations: {
    setPeriods(state, payload) {
      state.periods = payload;
    },
    setTariffs(state, payload) {
      state.tariffs = payload;
    },
  },
  actions: {
    getAll({ commit, dispatch }) {
      return Promise.all([dispatch('getPeriods', { commit }), dispatch('getTariffs', { commit })]);
    },
    getPeriods({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: 'api/payment/period/list',
        }, { root: true })
          .then(async (resp) => {
            commit('setPeriods', resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTariffs({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: 'api/payment/tariff/list',
        }, { root: true })
          .then(async (resp) => {
            commit('setTariffs', resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createTariff({ dispatch, commit, rootGetters }, sendData) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: 'api/payment/tariff/create',
          sendData,
        }, { root: true })
          .then(async (resp) => {
            const tariff = resp.data.data;
            const currentTraffs = rootGetters['tariffs/tariffs'];
            commit('setTariffs', [
              ...currentTraffs,
              tariff,
            ]);
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    tariffs: (state) => state.tariffs,
    periods: (state) => state.periods,
  },
};
