<template>
  <tbody>
    <TableRow
      v-for="(item) of paginatedItems"
      :key="`${fieldName}:${item.idx}`"
      :index="item.idx"
      :item="item"
      :payload-fields="payloadFields"
      :empty-element="emptyElement"
      :read-only="readOnly"
      :readonly-rule="readonlyRule"
      :delete-rule="deleteRule"
      :upper-items="upperItems"
      @openMarkedModal="$emit('openMarkedModal', $event)"
      @update:item="onItemUpdate($event, item.idx)"
      @delete="del"
    />
  </tbody>
  <v-pagination
    v-model="page"
    :length="pagesTotal"
    density="compact"
    color="primary"
  ></v-pagination>
</template>

<script>
import { computed } from 'vue';
import TableRow from '@/components/Tables/MainProducts/edit/components/TableBody/components/TableRow/index.vue';

import MainProductsTableEdit from '@/components/Tables/MainProducts/edit/index.vue';
import Section from '@/components/Section/edit/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import IntegrationsModal from '@/components/Organizations/integrations/modal.vue';
import { getChapterFieldKey } from '@/utils/utils';
import { payloadMixin } from '@/mixins/payload';

export default {
  name: 'TableBody',
  components: {
    MainProductsTableEdit, Section, BoxSizeSelect, CarSelect, DriverSelect, storagePicker, InputSearch, IntegrationsModal, TableRow,
  },
  mixins: [payloadMixin],
  provide() {
    return {
      upperItems: computed(() => this.upperItems),
    };
  },
  inject: {
    storeModule: { from: 'storeModule' },
    fields: { from: 'fields' },
    upperFields: { from: 'upperFields' },
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    payloadFields: {
      type: Object,
      default: () => ({}),
    },
    fieldName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    payloadParent: {
      type: String,
      default: null,
    },
    innerIndex: {
      type: Number,
      default: null,
    },
    appendFields: {
      type: Array,
      default: () => [],
    },
    appendItems: {
      type: Array,
      default: () => [],
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    emptyElement: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:modelValue', 'openMarkedModal', 'update:item'],
  data() {
    return {
      page: 1,
      pageSize: 5,
      // items: [],
    };
  },
  computed: {
    organizationId() {
      const orgKey = getChapterFieldKey({ field: 'organizations', chapterField: 'organization_id' });
      return this.chapterValues[orgKey];
    },
    organization() {
      return this.payload.organizations?.find((org) => String(org.organization_id) === String(this.organizationId));
    },
    chapterValues() {
      return this.$store.getters['common/chapterValues'];
    },
    pagesTotal() {
      return Math.ceil(this.items.length / this.pageSize);
    },
    paginatedItems() {
      return this.items.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize,
      );
    },
    isFormatted() {
      return this.modelValue?.some((el) => el.organization_product);
    },
    items: {
      get() {
        if (!this.isFormatted) {
          return this.getInitialItems();
        }
        return this.getUpdatedItems();
      },
      set(val) {
        const formattedItems = val.reduce((acc, item) => {
          item.organization_product_id = item.main_organization_product.id;
          if (item?.main_organization_product_from?.id) item.organization_product_from_id = item?.main_organization_product_from?.id;
          acc.push(item);
          return acc;
        }, []);
        this.$emit('update:modelValue', formattedItems);
      },
    },
    paths() {
      return this.fields.child_main_products_paths;
    },
    upperItems() {
      let upperItems = [];
      if (!this.paths) return [];

      this.paths.forEach((pathString) => {
        let obj = { ...this.organization };
        const path = pathString.split('->');
        const upperItemKey = path[path.length - 1];
        path.forEach((k) => {
          if (!obj) return;
          if (!obj[k]) obj[k] = [];
          if (k === upperItemKey) {
            upperItems = [...upperItems, ...obj[k]];
            return;
          }
          const ff = this?.upperFields?.[k];
          const chapterField = ff?.chapter_fields?.[0];
          const chapterValuesKey = getChapterFieldKey({ field: k, chapterField });
          obj = obj[k].find((el) => `${el[chapterField]}` === this.chapterValues[chapterValuesKey]);
        });
      });
      return upperItems;
    },
  },
  methods: {
    getInitialItems() {
      if (!this.modelValue) return [];
      return JSON.parse(JSON.stringify(this.modelValue)).map((row, i) => {
        Object.entries(row).forEach((el) => {
          if (this.fields[el[0]]?.field_type === 'default_table') {
            row[el[0]] = Array.isArray(el[1]) ? el[1] : el[1].values;
          } else if (this.fields[el[0]]?.is_collection && el[1]?.values && el[1]?.values?.length) {
            row[el[0]] = Array.isArray(el[1]) ? el[1].map((v) => v.id) : el[1].values.map((v) => v.id);
          } else if (el[1]?.value !== undefined) {
            row[el[0]] = el[1].value;
          }
          return el;
        });
        row.session_products.forEach((prod) => {
          if (!prod.boxes) return;
          const productBox = prod?.boxes?.find((el) => el.box === null);
          if (!productBox) prod.boxes.push({ count: 0, count_plan: 0, box: null });
        });
        row.idx = i;
        return row;
      });
    },
    getUpdatedItems() {
      const arr = [];
      this.modelValue.forEach((sessionProduct) => {
        const product = arr.find((el) => el.main_organization_product.id === sessionProduct.main_organization_product.id);
        if (!product) {
          arr.push({
            ...sessionProduct,
            main_organization_product_from: sessionProduct.main_organization_product_from,
            main_organization_product: sessionProduct.main_organization_product,
            organization_product_id: sessionProduct.main_organization_product.id,
            organization_product_from_id: sessionProduct.main_organization_product_from.id,
            session_products: [sessionProduct],
          });
        } else {
          product.session_products.push(sessionProduct);
        }
      });
      return arr;
    },
    onItemUpdate(item, index) {
      this.items = this.items.map((el, i) => {
        if (i === index) {
          el = item;
        }
        return el;
      });
    },
    add() {
      this.items = [...this.items, structuredClone(this.emptyElement)];
      this.$nextTick(() => {
        this.page = this.pagesTotal;
      });
    },
    del(idx) {
      this.items = this.items
        .filter((el, i) => i !== idx);
    },
  },
};
</script>