import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('organization');

const state = {
  ...store.state,
  data: {
    mergeList: [],
    mergeChildrenList: [],
  },
  loading: {
    ym: false,
    merge: false,
  },
};
const getters = {
  ...store.getters,
  // eslint-disable-next-line no-new-object
  config: () => new Object({ editable: true, showable: true, archivable: false }),
};
const actions = {
  ...store.actions,
  createYMIntegration({ commit, dispatch }, payload) {
    commit('setLoading', { path: 'ym', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `/api/organization/fbs/${payload.organizationId}/ym/create`,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'ym', value: false });
        });
    });
  },
  getValidMergeOrganizations({ commit, dispatch }) {
    commit('setLoading', { path: 'merge', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: '/api/organization/merge/valid',
      }, { root: true })
        .then((res) => {
          commit('setData', { path: 'mergeList', value: res.data.data.valid_main_organizations });
          commit('setData', { path: 'mergeChildrenList', value: res.data.data.valid_children_organizations });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'merge', value: false });
        });
    });
  },
  mergeOrganizations({ commit, dispatch }, { payload }) {
    commit('setLoading', { path: 'merge', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: '/api/organization/merge',
        sendData: payload,
      }, { root: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'merge', value: false });
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
