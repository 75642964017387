<template>
  <div class="d-flex align-center">
    <a
      class="reglinkcompany text-primary"
      @click.prevent.stop="copyLink(params.value)"
    >
      {{ !isCopied ? 'Скопировать' : 'Скопировано' }}
    </a>
    <v-btn
      :icon="isCopied ? 'mdi-check' : 'mdi-content-copy'"
      size="x-small"
      color="primary"
      variant="tonal"
      class="ml-4"
      @click.stop="copyLink(params.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'LinkCellRenderer',
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    url() {
      let url = window.location.hostname;
      if (window.location.port) url += `:${window.location.port}`;
      return url;
    },
  },
  methods: {
    copyLink(uniqueString) {
      const link = `http://${this.url}/auth/register?reglinkcompany=${uniqueString}`;

      const input = document.createElement('textarea');
      input.innerHTML = link;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.isCopied = true;
      this.params.context.componentParent.$root.toast.show({ message: 'Скопировано', color: 'primary', timer: 1500 });
      setTimeout(() => {
        this.isCopied = false;
      }, 1500);
    },
  },
};
</script>

<style lang="sass">
.reglinkcompany
  max-width: 400px
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  word-break: break-all
  margin-right: 10px
  overflow: hidden
  &:hover
    text-decoration: underline

</style>
