<template>
  <div class="carSelect">
    <v-select
      v-model="val"
      :items="selectData"
      :label="label"
      height="20"
      hide-details
      variant="filled"
      density="compact"
      :no-data-text="'Нет данных'"
      item-title="name"
      item-value="id"
      :menu-props="{minWidth: 500, width: 500}"
      @update:modelValue="$emit('update:modelValue', val || null)"
    >
      <template #item="{item, props}">
        <v-list-item v-bind="props">
          <template #append>
            <div class="carSelect_append">
              <v-text-field
                v-if="editItem === item.value"
                v-model="item.title"
                hide-details
                variant="filled"
                density="compact"
                @click.stop="$event.target.focus()"
              />
              <div v-else />
              <v-btn
                v-if="editItem !== item.value"
                icon="mdi-pencil"
                color="primary"
                variant="tonal"
                size="x-small"
                @click.stop="onEditClick(item.value)"
              />
              <v-btn
                v-else
                icon="mdi-check"
                color="primary"
                variant="flat"
                size="x-small"
                @click.stop="editCar(item)"
              />
              <v-btn
                icon="mdi-close"
                color="error"
                size="x-small"
                @click.stop="deleteCar(item)"
              />
            </div>
          </template>
        </v-list-item>
      </template>
      <template #append-item>
        <div class="carSelect_actions">
          <template
            v-if="addingNewItem"
          >
            <div style="flex-grow: 1">
              <v-text-field
                v-model="newItem.state_number"
                hide-details
                variant="filled"
                density="compact"
                label="Номер"
                class="mb-2"
              />
              <v-text-field
                v-model="newItem.brand"
                hide-details
                variant="filled"
                density="compact"
                label="Марка"
              />
            </div>
            <v-btn
              color="primary"
              size="x-small"
              icon="mdi-plus"
              @click="addNewCar"
            />
          </template>
          <v-btn
            v-else
            color="primary"
            size="x-small"
            icon="mdi-plus"
            @click="addingNewItem = true"
          />
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'CarSelect',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      val: '',
      selectData: [],
      newItem: {
        state_number: '',
        brand: '',
        id: 0,
      },
      editItem: false,
      addingNewItem: false,
    };
  },
  watch: {
    modelValue() {
      this.val = this.modelValue;
    },
    items() {
      this.selectData = this.items;
    },
  },
  beforeMount() {
    this.val = this.modelValue;
    this.selectData = this.items;
  },
  methods: {
    addNewCar() {
      if (!this.newItem?.state_number) return;
      this.$store.dispatch('driverCar/create', { payload: this.newItem, query: {} })
        .then((res) => {
          this.selectData.push({ id: res.data?.id?.value, name: `${this.newItem?.state_number} ${this.newItem?.brand}` });
          this.addingNewItem = false;
        });
    },
    onEditClick(id) {
      this.editItem = id;
    },
    editCar(item) {
      this.$store.dispatch('driverCar/create', { payload: { state_number: item.title, id: item.value }, query: {} })
        .then(() => {
          this.editItem = false;
          const it = this.selectData.find((el) => el.id === item.value);
          it.name = item.title;
        });
    },
    deleteCar(item) {
      const id = typeof item.value === 'object' ? item.value.id : item.value;
      this.$store.dispatch('driverCar/delete', { id })
        .then(() => {
          if (this.val === id) this.val = null;
          this.selectData = this.selectData.filter((el) => el.id !== id);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.carSelect
  min-width: 200px
  &_actions
    padding-inline: 10px
    margin-top: 10px
    display: flex
    gap: 10px
  &_append
    display: grid
    grid-template-columns: 150px auto auto
    align-items: center
    gap: 10px
</style>