<template>
  <v-card class="editModal pa-4">
    <v-card-title class="title">
      Заполнить рабочего
    </v-card-title>
    <v-text-field
      v-model="totalCount"
      type="number"
      label="Кол-во"
      hide-details
    >
      <template #append-inner>
        <v-btn
          v-if="countEdited"
          size="x-small"
          icon="mdi-restore"
          color="error"
          variant="tonal"
          @click="countEdited = false"
        />
      </template>
    </v-text-field>
    <v-text-field
      v-model="totalSum"
      type="number"
      label="Сумма"
      class="mt-6"
      hide-details
    >
      <template #append-inner>
        <v-btn
          v-if="sumEdited"
          size="x-small"
          icon="mdi-restore"
          color="error"
          variant="tonal"
          @click="sumEdited = false"
        />
      </template>
    </v-text-field>
    <v-btn
      color="primary"
      class="mt-6"
      block
      @click="$emit('update:item', user)"
    >
      Готово
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'WorkRecordsEditModal',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    outerItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      sumEdited: false,
      countEdited: false,
    };
  },
  computed: {
    selectedJob() {
      return this.fields.job_id?.values.find((el) => el.id === this.outerItem.job_id);
    },
    selectedUnit() {
      return this.selectedJob.units.find((el) => el.id === this.outerItem.job_child_id);
    },
    totalSum: {
      get() {
        return this.sumEdited ? this.user.sum : this.calcSum;
      },
      set(val) {
        this.sumEdited = true;
        this.user.sum = val;
      },
    },
    calcSum() {
      return this.outerItem.price * this.user.count;
    },
    totalCount: {
      get() {
        return this.countEdited ? this.user.count : this.calcCount;
      },
      set(val) {
        this.countEdited = true;
        this.user.count = val;
      },
    },
    calcCount() {
      if (!this.item.count_array) return 0;
      const item = this.item.count_array.find((el) => el.unit === this.selectedUnit.unit);
      if (!item) return null;
      return item.count ?? item.count_start;
    },
  },
  beforeMount() {
    this.user = JSON.parse(JSON.stringify(this.item));
  },
};
</script>

<style lang="sass" scoped>

</style>