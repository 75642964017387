import initialMutations from '@/store/initialMutations';
import { createQueryString } from '@/utils/utils';

export default {
  namespaced: true,
  state: {
    basePath: '',
    /* current themes:
     - classic
     - advanced
    */
    theme: 'advanced',
    offerText: '',
    qr: '',
    qrLink: '',
    chapterValues: {},
    upperFields: {},
    loading: {
      qr: false,
      save: false,
    },
  },
  mutations: {
    ...initialMutations,
    setBasePath(state, payload) {
      state.basePath = payload;
    },
    setTheme(state, payload) {
      localStorage.setItem('userTheme', payload);
      document.body.setAttribute('data-theme', payload);
      state.theme = payload;
    },
    setOfferText(state, payload) {
      state.offerText = payload;
    },
    setDownloadQr(state, { qr, link }) {
      state.qr = qr;
      state.qrLink = link;
    },
    setChapterValues(state, payload) {
      state.chapterValues = payload;
    },
    setUpperFields(state, payload) {
      state.upperFields = payload;
    },
  },
  actions: {
    getOffer({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: '/api/public/offer',
        }, { root: true })
          .then((res) => {
            commit('setOfferText', res.data.data.text);
            resolve(res);
          })
          .catch((err) => {
            commit('setOfferText', 'Не получилось загрузить текст офреты :(');
            reject(err);
          });
      });
    },
    getDownloadQr({ dispatch, commit }) {
      commit('setLoading', { path: 'qr', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: '/api/terminal-app/apk/qrcode',
        }, { root: true })
          .then((res) => {
            commit('setDownloadQr', { qr: res.data.data.qrBase64, link: res.data.data.link });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'qr', value: false });
          });
      });
    },
    create({ commit, dispatch }, { payload, query, entity }) {
      commit('setLoading', { path: 'save', value: true });
      let url = `/api/${entity}/create?`;
      url = createQueryString(url, query);
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'put',
          query: url,
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'save', value: false });
          });
      });
    },
    delete({ commit, dispatch }, { query, entity, id }) {
      commit('setLoading', { path: 'delete', value: true });
      let url = `/api/${entity}/delete/${id}?`;
      url = createQueryString(url, query);
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'delete', value: false });
          });
      });
    },
  },
  getters: {
    basePath: (state) => state.basePath,
    theme: (state) => state.theme,
    chapterValues: (state) => state.chapterValues,
    upperFields: (state) => state.upperFields,
  },
};
