<template>
  <label
    v-if="$slots.default"
    class="subtitle text-start text-grey d-flex align-center"
    style="font-size: 12px"
  >
    <slot></slot>
    <slot name="hint"></slot>
  </label>
</template>
<script>
export default {
  name: 'Label',
};
</script>