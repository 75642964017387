<template>
  <v-select
    ref="select"
    v-model="value"
    height="20"
    hide-details
    :density="density"
    :items="allItems"
    item-title="name"
    item-value="id"
    clearable
    :multiple="multiple"
    :label="label"
    :variant="variant"
    :menu-props="{minWidth: 500, width: 500}"
    :disabled="disabled"
    :loading="loading"
    style="min-width: 100px;"
    no-data-text="Нет организаций в рамках выбранной компании"
    @update:modelValue="onUpdate(value)"
  >
    <template #item="{props, item}">
      <v-list-item
        v-show="!item.raw?.organization_path || item.raw?.organization_path?.length <= 1"
        v-bind="props"
        class="align-center"
      >
        <template #prepend="{isSelected, select}">
          <v-checkbox
            v-if="multiple"
            :model-value="isSelected"
            hide-details
            density="compact"
            @click.prevent="select(!isSelected)"
          />
        </template>
        <template #append>
          <v-btn
            v-if="item.raw?.organizations?.length"
            :icon="!isOrgOpened(item.raw.id) ? 'mdi-chevron-down' : 'mdi-chevron-up'"
            variant="text"
            size="small"
            @click.stop="toggleOrganization(item.raw.id); typeof $refs?.select?._?.ctx['onUpdate:focused'] === 'function' ? $refs?.select?._?.ctx['onUpdate:focused']() : ''"
          />
        </template>
      </v-list-item>
      <ul
        v-if="isOrgOpened(item.raw.id)"
        style="margin-left: 40px"
        density="compact"
      >
        <v-list-item
          v-for="(p, i) of item.raw.organizations"
          :key="i"
          density="compact"
          class="childOrganization ma-0 py-2"
          color="primary"
          @click.stop="onInnerOrganizationSelect(p.id)"
        >
          <template #prepend>
            <v-checkbox
              v-if="multiple"
              :model-value="value && value?.includes && value?.includes(p.id)"
              hide-details
              density="compact"
              color="primary"
              @click.stop="onInnerOrganizationSelect(p.id)"
            />
          </template>
          {{ p.name }}
        </v-list-item>
      </ul>
    </template>
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>

    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner">
      </slot>
    </template>
  </v-select>
</template>

<script>
import logMixin from '@/mixins/log';

export default {
  name: 'SelectBoxOrganization',
  mixins: [logMixin],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'filled',
    },
    density: {
      type: String,
      default: 'comfortable',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',
      selectedItems: [],
      openedOrganizations: new Set(),
    };
  },
  computed: {
    allItems() {
      return this.items.reduce((acc, val) => {
        acc.push(val);
        if (val.organizations) {
          acc.push(...val.organizations);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    modelValue() {
      if (typeof this.$refs?.select?._?.ctx['onUpdate:focused'] === 'function') this.$refs?.select?._?.ctx['onUpdate:focused']();
      if (this.multiple && this.modelValue) {
        this.value = [...this.modelValue];
      } else {
        this.value = this.modelValue;
      }
    },
  },
  beforeMount() {
    if (this.modelValue) {
      this.value = this.modelValue;
    } else if (this.multiple) {
      this.value = [];
    } else {
      this.value = '';
    }
  },
  methods: {
    onUpdate(value) {
      this.$emit('update:modelValue', value);
    },
    onInnerOrganizationSelect(value) {
      if (this.multiple) {
        if (!this.value.includes(value)) {
          this.value.push(value);
        } else {
          this.value = this.value.filter((v) => v !== value);
        }
      } else {
        this.value = value;
      }
      this.$refs.select.$forceUpdate();
      this.$emit('update:modelValue', this.value);
    },
    toggleOrganization(id) {
      if (!this.openedOrganizations.has(id)) this.openedOrganizations.add(id);
      else this.openedOrganizations.delete(id);
    },
    isOrgOpened(id) {
      return this.openedOrganizations.has(id);
    },
  },
};
</script>

<style lang="sass" scoped>
.childOrganization
  cursor: pointer
  &:hover
    transition: .3s ease
    background: rgba(0, 0, 0, 0.04)
</style>