export const columns = [
  {
    field: 'userId',
    headerName: 'userId',
    sortable: true,
    width: 40,
  },
  {
    field: 'id',
    headerName: 'id',
    sortable: true,
  },
  {
    field: 'title',
    headerName: 'Номер',
    sortable: true,
  },
  {
    field: 'body',
    headerName: 'Коммент',
    sortable: true,
  },
];
