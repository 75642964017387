import { dataDefinition } from '@/const/const';
import { payloadMixin } from '@/mixins/payload';

export const tableValueMixin = {
  mixins: [payloadMixin],
  data() {
    return {
      dataDefinition: dataDefinition(this.storeModule),
    };
  },
  computed: {
    items() {
      return this.fields[this?.fieldName].values;
    },
    name() {
      return this.showData[this?.fieldName].name ? this.showData[this?.fieldName].name : this.showData[this?.fieldName];
    },
    type() {
      return this.dataDefinition?.[this.fieldName]?.type ?? this.fields?.[this?.fieldName]?.field_type;
    },
    fileData: {
      get() {
        return this.$store.getters['payload/fileData'];
      },
      set(val) {
        this.$store.commit('payload/setFileData', val);
      },
    },
    showDataRaw: {
      get() {
        return this.$store.getters[`${this.storeModule}/showDataRaw`];
      },
      // set(val) {
      //   this.$store.commit(`${this.storeModule}/setShowDataRaw`, val);
      // },
    },
    fieldsLoading() {
      return this.$store.getters[`${this.storeModule}/fieldsLoading`];
    },
  },
  methods: {
    onFileLoad({
      entity, file, field, extension,
    }) {
      const fd = JSON.parse(JSON.stringify(this.fileData));
      fd.file = file;
      fd.entity = entity;
      fd.field = field;
      fd.extension = extension;
      this.fileData = fd;
    },
  },
};
