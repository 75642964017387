<template>
  <button>
    <v-icon
      class="ml-2"
      :size="size"
    >
      mdi-dots-vertical
    </v-icon>
    <v-menu
      activator="parent"
      :close-on-content-click="true"
      :lang="'ru'"
      transition="none"
    >
      <v-list>
        <template v-if="$slots.prepend">
          <slot name="prepend"></slot>
        </template>
        <v-list-item
          v-if="actions.copy"
          @click="$emit('copy')"
        >
          Копировать
        </v-list-item>
        <v-list-item
          v-if="actions.edit"
          @click="$emit('edit')"
        >
          Изменить
        </v-list-item>
        <v-list-item
          v-if="actions.delete"
          @click="$emit('delete')"
        >
          Удалить
        </v-list-item>
        <template v-if="$slots.append">
          <slot name="append"></slot>
        </template>
      </v-list>
    </v-menu>
  </button>
</template>

<script>
export default {
  name: 'StoragesActionsMenu',
  props: {
    actions: {
      type: Object,
      default: () => ({ copy: true, edit: true, delete: true }),
    },
    size: {
      type: Number,
      default: 12,
    },
  },
  emits: ['copy', 'edit', 'delete'],
};
</script>