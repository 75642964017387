import axios from 'axios';
import { convertShipmentList } from '@/utils/utils.js';

export default {
  namespaced: true,
  state: {
    fboArr: [],
    marketplaces: [],
    organizations: [],
  },
  mutations: {
    setFboArr(state, payload) {
      state.fboArr = payload;
    },
    setMarketplaces(state, payload) {
      state.marketplaces = payload;
    },
    setOrganizations(state, payload) {
      state.organizations = payload;
    },
  },
  actions: {
    getShipmentSchedule({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('http://45.86.180.121/api/shipment/schedule?status=created', {
          headers: {
            Authorization: '',
          },
        })
          .then((res) => {
            commit('setFboArr', convertShipmentList(res.data.days));
            commit('setMarketplaces', res.data.marketplaces);
            commit('setOrganizations', res.data.organizations);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    fboArr: (state) => state.fboArr,
    marketplaces: (state) => state.marketplaces,
    organizations: (state) => state.marketplaces,
  },
};
