import initialMutations from '@/store/initialMutations';
import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('marketplaceCard');

const state = {
  ...store.state,
  data: {
    uploadingItems: [],
  },
  loading: {
    uploadingItems: false,
  },
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,
  // api/uploading/api/10/list
  getUploadingItems({ dispatch, commit }, { organizationApiId }) {
    commit('setLoading', { path: 'uploadingItems', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `/api/uploading/api/${organizationApiId}/list`,
      }, { root: true })
        .then(async (res) => {
          commit('setData', { path: 'uploadingItems', value: res.data.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingItems', value: false });
        });
    });
  },
  sendUploadingItems({ dispatch, commit }, { id, payload }) {
    commit('setLoading', { path: 'uploadingItems', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `/api/uploading/api/${id}/create?`,
        sendData: payload,
      }, { root: true })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingItems', value: false });
        });
    });
  },
  /// api/uploading/api/{id}/show
  getUploadingInfo({ dispatch, commit }, { id, productId, barcode }) {
    commit('setLoading', { path: 'uploadingItems', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `/api/uploading/api/${id}/show?productId=${productId}&barcode=${barcode}`,
      }, { root: true })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingItems', value: false });
        });
    });
  },
};
const mutations = {
  ...initialMutations,
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
