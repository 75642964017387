<template>
  <v-card
    v-show="showSnackbar"
    style="background: rgba(40,40,40,0.9)"
    :timeout="timer"
    bottom
    right
    class="toast"
    @click="handleMessageClick"
  >
    <div
      v-if="activeMessage"
      class="toast__header"
    >
      <div style="display: grid; justify-items: center">
        <v-icon
          class="mb-6 ml-1"
          :size="25"
          :color="activeMessage.color"
        >
          {{ activeMessage.icon }}
        </v-icon>
        <v-btn
          :color="activeMessage.color"
          class="ml-2"
          size="x-small"
          :icon="isCopied ? 'mdi-check' : 'mdi-content-copy'"
          @click.stop="copyMsg"
        />
      </div>
      <div
        class="subtitle"
        style="white-space: normal; width: 90%"
      >
        <div
          class="message__header"
          :class="`text-${activeMessage.color}`"
        >
          <p
            class="message"
          >
            {{ activeMessage.message }}
          </p>
          <div v-if="messages.length > 1">
            ({{ messageIdx + 1 }} / {{ messages.length }})
          </div>
        </div>
        <v-card-actions class="py-0 align-end">
          <v-spacer></v-spacer>
          <template v-if="messages.length > 1">
            <v-btn
              :color="activeMessage.color"
              variant="flat"
              size="x-small"
              @click="$emit('clear')"
            >
              Закрыть всё
            </v-btn>
            <v-btn
              size="x-small"
              variant="flat"
              class="ml-2"
              @click.stop="prevMessage()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              size="x-small"
              variant="flat"
              class="ml-2"
              @click.stop="nextMessage()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'Toast',
  props: {
    messages: {
      type: Array,
      default: () => ([]),
    },
    showSnackbar: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['clear'],
  data() {
    return {
      messageIdx: 0,
      isCopied: false,
    };
  },
  computed: {
    activeMessage() {
      return this.messages[this.messageIdx];
    },
  },
  watch: {
    messageIdx() {
      this.isCopied = false;
    },
  },
  methods: {
    prevMessage() {
      if (this.messageIdx > 0) {
        this.messageIdx--;
      }
    },
    nextMessage() {
      if (this.messageIdx < this.messages.length - 1) {
        this.messageIdx++;
      }
    },
    handleMessageClick() {
      this.$emit('clear', this.messageIdx);
      this.prevMessage();
    },
    copyMsg() {
      const input = document.createElement('textarea');
      input.innerHTML = this.messages[this.messageIdx].message;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2500);
    },
  },
};
</script>
<style lang="sass" scoped>
.toast
  padding: 10px !important
  padding-right: 20px !important
  min-width: 300px
  border-radius: 5px !important
  &__header
    display: flex
    gap: 10px
    align-items: start
    max-width: 460px
    overflow: hidden

  .subtitle
    text-overflow: ellipsis

  .message__header
    display: flex
    justify-content: space-between
    font-size: 14px
    font-weight: 600
    opacity: 1
    .message
      max-width: 75%
      max-height: 100px
      text-overflow: ellipsis
      text-align: start
</style>
