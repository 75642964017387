<template>
  <v-card class="columnsModal">
    <div class="columnsModal__head">
      <v-card-title class="title">
        Выбрать поля
      </v-card-title>
      <UiSelect
        v-model="template"
        :items="templateItems"
        class="templateSelect"
        :label="rightColumns.length ? template ? 'Шаблон' : 'Создать шаблон из полей' : 'Выберите шаблон или поля'"
        variant="outlined"
        color="primary"
        :allow-custom="rightColumns.length"
        :allow-custom-immediate="false"
        allow-custom-label="Создать шаблон"
        allow-custom-placeholder="Название шаблона"
        :clearable="template"
        :loading="excelLoading"
        @update:model-value="onTemplateSelect"
        @add-custom="createTemplate"
      />
    </div>
    <div class="columnsModal__content">
      <ColumnsList
        :items="leftColumns"
        label="Доступные поля"
        @select="moveToRight"
      />
      <ColumnsList
        :items="rightColumns"
        label="Поля для экспорта"
        drag
        @select="moveToLeft"
        @update:items="rightColumns = $event"
      />
    </div>

    <v-btn
      color="primary"
      class="mt-4"
      block
      @click="sumbit"
    >
      Готово
    </v-btn>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import ColumnsList from '@/components/ExcelLoad/components/ColumnsModal/columns-list.vue';
import UiSelect from '@/components/UiKit/Form/SelectboxSearch/index.vue';

export default {
  name: 'ExcelLoadColumns',
  components: { ColumnsList, UiSelect },
  props: {
    columns: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: ['sumbit'],
  data() {
    return {
      template: null,
      leftColumns: [],
      rightColumns: [],
    };
  },
  computed: {
    ...mapState('excel', {
      templates: (s) => s.data.templateList,
    }),
    templateItems() {
      return this.templates.map((el) => ({ id: el.id, name: el.name }));
    },
    selectedTemplate() {
      return this.templates.find((el) => el.id === this.template);
    },
    excelLoading() {
      return this.$store.getters['excel/excelTemplatesLoading'];
    },
  },
  beforeMount() {
    this.getTemplateList();
    this.leftColumns = [...this.columns];
  },
  methods: {
    moveToRight(item) {
      this.template = '';
      this.leftColumns = this.leftColumns.filter((el) => el.field !== item.field);
      this.rightColumns.push(item);
    },
    moveToLeft(item) {
      this.template = '';
      this.rightColumns = this.rightColumns.filter((el) => el.field !== item.field);
      this.leftColumns.push(item);
    },
    sumbit() {
      this.$emit('sumbit', this.rightColumns.map((el) => el.field));
    },
    onTemplateSelect() {
      const cols = this.selectedTemplate?.columns;
      if (!Array.isArray(cols)) {
        this.setColumnsDefault();
        return;
      }
      this.rightColumns = this.columns.filter((el) => cols.includes(el.field));
      this.leftColumns = this.columns.filter((el) => !cols.includes(el.field));
    },
    getTemplateList() {
      this.$store.dispatch('excel/getExcelTemplates');
    },
    createTemplate(name) {
      const payload = {
        id: 0,
        name,
        columns: [...this.rightColumns.map((el) => el.field)],
      };
      this.$store.dispatch('excel/createExcelTemplate', { payload })
        .then(() => {
          this.getTemplateList();
        });
    },
    setColumnsDefault() {
      this.leftColumns = this.columns;
      this.rightColumns = [];
    },
  },
};
</script>

<style lang="sass" scoped>
.columnsModal
  &__head
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    & .templateSelect
      max-width: 300px
  &__content
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 10px
</style>