<template>
  <v-select
    v-if="availableOptions"
    v-model="selectedValue"
    :items="availableOptions"
    item-title="name"
    item-value="id"
    class="worker-list-status-cell"
    density="compact"
    :no-data-text="'Нет данных'"
    :disabled="!availableOptions.length || disabled"
    hide-details
  />
</template>

<script>
export default {
  name: 'StatusPicker',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialStatus: '',
      value: '',
      options: [
        {
          id: 'planned',
          name: 'Планируется',
          canChangeTo: ['closed'],
          initial: true,
        },
        {
          id: 'active',
          name: 'Активно',
          canChangeTo: ['suspended', 'closed'],
          initial: false,
        },
        {
          id: 'suspended',
          name: 'Приостановлено',
          canChangeTo: ['closed'],
          initial: false,
        },
        {
          id: 'closed',
          name: 'Закрыто',
          canChangeTo: [],
          initial: true,
        },
      ],
    };
  },
  computed: {
    availableOptions() {
      if (!this.selectedValue.id || !this.initialStatus) return this.options.filter((el) => !!el.initial);
      const selectedArr = this.options.filter((el) => this.selectedValue?.canChangeTo?.includes(el.id)) || [];
      const initialOption = this.options.find((option) => option.id === this.initialStatus);

      let initialArr = initialOption?.canChangeTo;
      initialArr = initialArr
        .map((el) => this.options.find((option) => option.id === el))
        .filter((el) => !selectedArr.find((e) => e.id === el.id)) || [];

      if (this.initialStatus && !initialArr.includes(initialOption)) initialArr.push(initialOption);
      return [...selectedArr, ...initialArr];
    },
    selectedValue: {
      get() {
        return this.options.find((o) => o.id === this.value) || { id: 'planned', name: 'Планируется' };
      },
      set(status) {
        this.value = status;
        this.$emit('update:modelValue', this.value);
      },
    },
  },
  beforeMount() {
    this.value = this.modelValue;
    this.initialStatus = this.modelValue;
    this.$emit('update:modelValue', this.selectedValue.id);
  },
};
</script>