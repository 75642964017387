import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('remnantsChangerUpper');

const state = {
  ...store.state,
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
