import axios from 'axios';

export default {
  namespaced: true,
  state: {
    messages: [],
  },
  actions: {
    axiosSend(
      { commit },
      {
        type = 'post',
        query,
        sendData,
        errorDisplay = true,
        params,
        headers,
        signal,
      },
    ) {
      if (!query) return;

      const options = { // Параметры axios
        method: type,
        url: query,
      };

      if (sendData) options.data = sendData;

      if (params) options.params = params;

      if (headers) options.headers = headers;

      if (signal) options.signal = signal;


      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        axios(options)
          .then((response) => {
            if (response !== undefined && response?.status === 302) {
              window.location.replace(window.location.href);
              throw ({ message: 'Истекло время сессии' });
            }
            if (response !== undefined && response?.status === 500) {
              throw ({ message: 'Ошибка сервера' });
            }
            if (response !== undefined && response?.status === 404) {
              throw ({ message: 'Ошибка запроса на сервер' });
            }
            if (response instanceof Error) {
              throw ({ message: 'Ошибка запроса на сервер' });
            } else if (response.data && response.data.errors) {
              if (errorDisplay) {
                Object.values(response.data.errors).forEach((e) => {
                  commit('addMessage', { message: e.message, type: 'error', error: e });
                });
              }
              resolve(response);
            }
            resolve(response);
          })
          .catch((err) => {
            if (err.code === 'ERR_CANCELED') {
              reject(err);
              return;
            }
            if (errorDisplay && err.response?.data?.errors) {
              Object.values(err.response?.data?.errors).forEach((e) => {
                commit('addMessage', { message: e.message, type: 'error' });
              });
            } else if (String(err.response?.status).startsWith('5')) {
              commit('addMessage', {
                message: 'Ошибка сервера',
                type: 'error',
                error: err,
              });
            } else if (err) {
              commit('addMessage', {
                message: 'Что-то пошло не так.',
                type: 'error',
                error: err,
              });
            }
            reject(err);
          });
      });
    },
    removeMessageByIdx({ commit }, idx) {
      commit('removeMessageByIdx', idx);
    },
    addMessage({ commit }, message) {
      commit('addMessage', message);
    },
  },
  mutations: {
    addMessage(state, { message, type, error }) {
      state.messages.push({ message, type, error });
    },
    removeMessageByIdx(state, idx) {
      state.messages.splice(idx, 1);
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    clearMessages(state) {
      state.messages = [];
    },
  },
};
