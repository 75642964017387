<template>
  <v-card>
    <v-card-title class="title">
      Маркированные товары
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'MarketProductsModal',
};
</script>