<template>
  <v-select
    v-model="value"
    :items="items"
    placeholder="Буква"
    density="compact"
    hide-details
    item-title="name"
    item-value="id"
    :no-data-text="'Нет данных'"
    :label="label"
    @update:modelValue="$emit('update:modelValue', value)"
  >
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>

    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner">
      </slot>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'StorageLetterSelect',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    items() {
      return 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ'.split('').filter((el) => !this.exclude.includes(el));
    },
  },
  beforeMount() {
    this.value = this.modelValue;
  },
};
</script>