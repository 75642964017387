// global store module for common modals
export default {
  namespaced: true,
  state: {
    // TODO: make multiple modals (no need for now)
    isOpen: false,
    name: '',
    width: 700,
  },
  mutations: {
    setData(state, { path, value }) {
      state[path] = value;
    },
    openModal(state, { name }) {
      state.isOpen = true;
      state.name = name;
    },
    closeModal(state) {
      state.isOpen = false;
      state.name = '';
    },
  },
};
