export const columns = [
  {
    field: 'manufacturer_sku',
    headerName: 'Артикул',
    sortable: true,
  },
  {
    field: 'name',
    headerName: 'Название',
    sortable: true,
  },
  {
    field: 'organization_id',
    sortable: true,
  },
  {
    field: 'type',
    sortable: true,
  },
  {
    field: 'packing_instruction',
    sortable: true,
  },
  {
    field: 'price',
    sortable: true,
  },
  {
    field: 'volume',
    sortable: true,
  },
  {
    field: 'url_packing',
    sortable: true,
  },
  {
    field: 'description',
    sortable: true,
  },
  {
    field: 'group_op_id',
  },
  {
    headerName: 'Действия',
    cellRenderer: 'action',
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'clearFilter',
        },
        { filter: 'agNumberColumnFilter' },
      ],
    },
    pinned: 'right',
    minWidth: 130,
    maxWidth: 130,
  },
];
