<template>
  <v-btn
    size="small"
    color="#04713c"
    variant="flat"
    class="v-btn--icon excelBtn"
    :disabled="disabled"
  >
    <v-icon
      size="23"
    >
      mdi-file-excel
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ExcelBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>