<template>
  <v-card class="confirmDelete">
    <v-card-title class="my-4">
      <slot name="title">
        Удалить?
      </slot>
    </v-card-title>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        variant="tonal"
        @click="$emit('no')"
      >
        Нет
      </v-btn>
      <v-btn
        color="info"
        variant="flat"
        @click="$emit('yes')"
      >
        Да
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmDelete',
};
</script>

<style lang="sass" scoped>
.confirmDelete
  font-family: "Roboto", sans-serif
</style>
