<template>
  <div :style="params.value.length > 1 ? 'display: grid; grid-template-rows: repeat(auto-fill, 20px); max-height: 40px; overflow-y: auto' : ''">
    <template v-for="filePath of params.value">
      <a
        class="filePath text-primary"
        :href="basePath + filePath.url"
        target="_blank"
        style="height: 20px"
      >
        {{ filePath.label || 'Перейти' }}
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LinkCellRenderer',
  props: {
    link: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    basePath() {
      return `${window.location.origin}/`;
    },
  },
};
</script>

<style lang="sass">
.filePath
  max-width: 400px
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  word-break: break-all
  margin-right: 10px
  line-height: 1
  &:hover
    text-decoration: underline
</style>
