<template>
  <div
    class="columnsList__wrapper"
  >
    <Label
      v-if="label"
      class="pb-2"
    >
      {{ label }}
    </Label>
    <v-list
      class="columnsList"
      density="compact"
      color="primary"
    >
      <draggable
        v-if="drag"
        :list="items"
        item-key="name"
        class="gallery_photos"
        ghost-class="ghost"
        @change="update"
      >
        <template #item="{element, index}">
          <v-list-item
            :key="index"
            style="cursor: grab;"
            @click="$emit('select', element)"
          >
            {{ element.label }}
          </v-list-item>
        </template>
      </draggable>

      <v-list-item
        v-for="(element, index) of items"
        v-else
        :key="index"
        @click="$emit('select', element)"
      >
        {{ element.label }}
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Label from '@/components/UiKit/Label/index.vue';

export default {
  name: 'ColumnsList',
  components: { draggable, Label },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
    drag: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select'],
  methods: {
    update() {
      this.$emit('update:items', this.items);
    },
  },
};
</script>

<style lang="sass" scoped>
.columnsList
  max-height: 400px
  height: 400px
  overflow-y: auto
  border-radius: 5px
  border: 1px solid #eee
</style>