<template>
  <div class="selectBoxConsignment">
    <UiSelectboxProduct
      v-model="value"
      :items="products"
      class="my-2"
      density="compact"
      hide-details
      @update:modelValue="$emit('update:modelValue', value)"
    >
      <template
        v-if="is_consignment"
        #append-item
      >
        <div>
          <v-btn
            size="x-small"
            color="primary"
            @click="isPopup = true"
          >
            Создать партию
          </v-btn>
        </div>
      </template>
    </UiSelectboxProduct>

    <v-dialog
      v-model="isPopup"
      width="900"
    >
      <ConsignmentCreateModal
        :product-id="value"
        @addConsignment="onConsignmentAdd"
      />
    </v-dialog>
  </div>
</template>

<script>
import ConsignmentCreateModal
  from '@/components/UiKit/Form/SelectboxConsignment/components/ConsignmentCreateModal/index.vue';

export default {
  name: 'SelectboxConsignment',
  components: { ConsignmentCreateModal },
  provide() {
    return {
      storeModule: 'consignment',
    };
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isConsignment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: '',
      products: [],

      isPopup: false,
    };
  },
  watch: {
    modelValue() {
      this.init();
    },
  },
  beforeMount() {
    this.products = JSON.parse(JSON.stringify(this.items));
    this.init();
  },
  methods: {
    onConsignmentAdd(data) {
      this.products.push(data);
      this.isPopup = false;
    },
    init() {
      if (this.modelValue) {
        this.value = this.modelValue;
      } else if (this.multiple) {
        this.value = [];
      } else {
        this.value = '';
      }
    },
  },
};
</script>