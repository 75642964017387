<template>
  <div class="productGroupSelect">
    <v-select
      v-model="val"
      :items="selectData"
      height="20"
      hide-details
      :variant="variant"
      :density="density"
      :label="label"
      :disabled="disabled"
      :clearable="clearable"
      :no-data-text="'Нет данных'"
      item-title="name"
      item-value="id"
      :menu-props="{minWidth: 500, width: 500}"
      @update:modelValue="$emit('update:modelValue', val || null)"
    >
      <template #item="{item, props}">
        <v-list-item v-bind="props">
          <template #append>
            <div class="productGroupSelect_append">
              <v-text-field
                v-if="editItem === item.value"
                v-model="item.title"
                hide-details
                variant="filled"
                density="compact"
                @click.stop="$event.target.focus()"
              />
              <div v-else />
              <template v-if="editable">
                <v-btn
                  v-if="editItem !== item.value"
                  icon="mdi-pencil"
                  color="primary"
                  variant="tonal"
                  size="x-small"
                  @click.stop="onEditClick(item.value)"
                />
                <v-btn
                  v-else
                  icon="mdi-check"
                  color="primary"
                  variant="flat"
                  size="x-small"
                  @click.stop="editGroup(item)"
                />
                <v-btn
                  icon="mdi-close"
                  color="error"
                  size="x-small"
                  @click.stop="deleteGroup(item)"
                />
              </template>
            </div>
          </template>
        </v-list-item>
      </template>
      <template #append-item>
        <div class="productGroupSelect_actions">
          <template
            v-if="addingNewItem"
          >
            <v-text-field
              v-model="newItem.name"
              hide-details
              variant="filled"
              density="compact"
              placeholder="Название группы"
            />
            <v-btn
              color="primary"
              size="x-small"
              icon="mdi-plus"
              @click="addNewGroup"
            />
          </template>
          <v-btn
            v-else-if="editable"
            color="primary"
            size="x-small"
            icon="mdi-plus"
            @click="addingNewItem = true"
          />
        </div>
      </template>
      <template
        v-if="$slots['prepend-inner']"
        #prepend-inner
      >
        <slot name="prepend-inner">
        </slot>
      </template>

      <template
        v-if="$slots['append-inner']"
        #append-inner
      >
        <slot name="append-inner">
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'SelectboxProductGroup',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'filled',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: 'default',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      val: '',
      selectData: [],
      newItem: {
        name: '',
        id: 0,
      },
      editItem: false,
      addingNewItem: false,
    };
  },
  beforeMount() {
    this.val = this.modelValue;
    this.selectData = this.items;
  },
  methods: {
    addNewGroup() {
      if (!this.newItem?.name) return;
      this.$store.dispatch('organizationProduct/createGroup', this.newItem)
        .then((res) => {
          this.selectData.push({ id: res.data.data?.id?.value, name: this.newItem?.name });
          this.addingNewItem = false;
        });
    },
    onEditClick(id) {
      this.editItem = id;
    },
    editGroup(item) {
      this.$store.dispatch('organizationProduct/editGroup', { name: item.title, id: item.value })
        .then(() => {
          this.editItem = false;
          const it = this.selectData.find((el) => el.id === item.value);
          it.name = item.title;
        });
    },
    deleteGroup(item) {
      const id = typeof item.value === 'object' ? item.value.id : item.value;
      this.$store.dispatch('organizationProduct/deleteGroup', { id })
        .then(() => {
          if (this.val === id) this.val = null;
          this.selectData = this.selectData.filter((el) => el.id !== id);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.productGroupSelect
  &_actions
    padding-inline: 10px
    margin-top: 10px
    display: flex
    gap: 10px
  &_append
    display: grid
    grid-template-columns: 150px auto auto
    align-items: center
    gap: 10px
</style>