import { filterObj } from '@/const/const';

// Функция, преобразующая фильтры отдаваемые таблицей, в body для запроса
export function generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page) {
  const body = {
    filter: {
      sort: {},
      page: {},
      is_archive: { type: 3, values: isArchive ? ['1'] : ['0'] },
    },
  };
  sortModel.forEach((s) => {
    // eslint-disable-next-line no-underscore-dangle
    body.filter.sort._sort_by = s.colId;
    // eslint-disable-next-line no-underscore-dangle
    body.filter.sort._sort_order = s.sort;
  });
  // eslint-disable-next-line no-underscore-dangle
  body.filter.page._per_page = limit;
  // eslint-disable-next-line no-underscore-dangle
  body.filter.page._page = page + 1;
  Object.entries(filterModel).forEach((f) => {
    const key = f[0];
    switch (f[1].filterType) {
    case 'set': {
      if (f[1].values.length === 0) return;
      const filterValues = f[1].values;
      body.filter[key] = { values: [], type: 3 };
      filterValues.forEach((filterValue) => {
        const i = (state.filters[f[0]].values).find((el) => el.name === filterValue).id;
        body.filter[key].values.push(i);
      });
      break;
    }
    case 'text': {
      body.filter[key] = { type: filterObj[f[1].type], values: [f[1].filter] };
      break;
    }
    case 'number': {
      body.filter[key] = { type: filterObj[f[1].type], values: [f[1].filter] };
      break;
    }
    case 'date': {
      const type = filterObj[f[1].type];
      if (type === 7) {
        body.filter[key] = {
          type,
          values: [`${f[1].dateFrom}`, `${f[1].dateTo}`],
        };
      } else {
        body.filter[key] = {
          type,
          values: [`${f[1].dateFrom}`],
        };
      }
      break;
    }
    default:
      // eslint-disable-next-line prefer-destructuring
      body.filter[key] = f[1];
      break;
    }
  });
  return body;
}

export function generateEnityRoutes(storeModule) {
  return [
    {
      path: `/${storeModule}/list`,
      name: `${storeModule}`,
      component: () => import(`@/pages/${storeModule}/list/${storeModule}.list.vue`),
    },
    {
      path: `/${storeModule}/archive`,
      name: `${storeModule}Archive`,
      component: () => import(`@/pages/${storeModule}/archive/${storeModule}.archive.vue`),
    },
    {
      path: `/${storeModule}/create`,
      name: `${storeModule}Create`,
      component: () => import(`@/pages/${storeModule}/create/${storeModule}.create.vue`),
    },
    {
      path: `/${storeModule}/edit/:id`,
      name: `${storeModule}Edit`,
      component: () => import(`@/pages/${storeModule}/edit/${storeModule}.edit.vue`),
    },
    {
      path: `/${storeModule}/show/:id`,
      name: `${storeModule}Show`,
      component: () => import(`@/pages/${storeModule}/show/${storeModule}.show.vue`),
    },
  ];
}

export function convertShipmentList(days) {
  days = Object.entries(days).reduce((acc, shipment) => {
    const date = shipment[0].split('.');
    let orgs = Object.entries(shipment[1].organizations);
    // eslint-disable-next-line no-shadow
    orgs = orgs.reduce((acc, org) => {
      const orgId = org[0];
      let shs = Object.entries(org[1].shipments);

      // eslint-disable-next-line no-shadow
      shs = shs.reduce((acc, sh) => {
        const shId = sh[0];
        const shData = sh[1];
        const start = shData.timeslot.split('-')[0];
        const end = shData.timeslot.split('-')[1];
        // eslint-disable-next-line prefer-destructuring
        shData.date = new Date(`${date[1]}-${date[0]}-${date[2]}`).toISOString().split('T')[0];
        shData.start = `${shData.date} ${start}:00`;
        shData.end = `${shData.date} ${end}:00`;
        shData.id = shId;
        shData.org = orgId;
        shData.class = 'calendar-shipment';
        shData.title = `Поставка №${shData.number}`;
        acc.push(shData);
        return acc;
      }, []);
      return acc.concat(shs);
    }, []);
    return acc.concat(orgs);
  }, []);
  return days;
}

export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export function getFormattedDate(date) {
  if (!date) return '';
  if (typeof date?.includes === 'function' && date?.includes('.')) return date;
  return new Date(date).toLocaleDateString('ru', { hour: '2-digit', minute: '2-digit' });
}

export function dateDiffInDays(a, b) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function getFormattedTime(minutes) {
  if (!minutes) {
    return '-';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours > 0 ? `${Math.round(hours)} ч.` : '';
  const formattedMinutes = remainingMinutes > 0 ? `${Math.round(remainingMinutes)} мин.` : '';

  if (hours > 0 && remainingMinutes > 0) {
    return `${formattedHours} ${formattedMinutes}`;
  }
  return formattedHours || formattedMinutes;
}

export function removeValuesWrapper(el) {
  if (!el) return el;
  Object.keys(el).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (el[key] && Object.hasOwn(el[key], 'value')) {
      el[key] = el[key].value;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (el[key] && Object.hasOwn(el[key], 'values')) {
      el[key] = el[key].values.map((val) => removeValuesWrapper(val));
    }
  });
  return el;
}

export function createQueryString(string, query, includeEmpty = true) {
  if (!query) return string;
  if (!string.endsWith('?')) string = `${string}?`;
  Object.keys(query).forEach((q) => {
    if (typeof query[q] !== 'object' && (query[q] || includeEmpty)) string += `${q}=${query[q]}&`;
  });
  return string;
}

export function transformObject(obj) {
  if (!obj || typeof obj !== 'object') return obj;
  const newObj = JSON.parse(JSON.stringify(obj));

  // eslint-disable-next-line no-shadow
  function moveValuesField(obj, show) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj[key]?.hasOwnProperty('values')) {
          obj[key] = obj[key].values;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (obj[key]?.hasOwnProperty('value')) {
          obj[key] = obj[key].value;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (obj[key]?.hasOwnProperty('file_paths') && !show) {
          delete obj[key].file_paths;
        }

        moveValuesField(obj[key], show);
      }
    }
  }

  moveValuesField(newObj, true);

  return newObj;
}

export function copyToClipboard(text) {
  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
}
export function transformFormattedDate(dateString) {
  if (!dateString || typeof dateString !== 'string') return '';
  if (!dateString.includes('.')) return dateString;
  const [date, time] = dateString.includes(', ') ? dateString.split(', ') : [dateString, undefined];
  // Split the date string into day, month, and year parts
  const [day, month, year] = date.split('.');
  const [hrs, mins] = time ? time.split(':') : ['00', '00'];

  // Format the date as "yyyy-mm-dd"
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

  // Add the time "00:00:00"
  const value = `${formattedDate} ${hrs}:${mins}:00`;
  return value;
}

export function isPrimitiveFieldType(type) {
  // Типы, request_on_change которых не должен вызывать последующего запроса (например на fields),
  // т.к они объектные
  const complexTypes = [
    'default_table_chapters',
    'default_table',
    'main_products',
  ];
  return !complexTypes.includes(type);
}

export const getChapterFieldKey = ({ field, chapterField }) => `${field}:${chapterField}`;