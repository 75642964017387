export const asideMixin = {
  data() {
    return {
      aside: {
        meta: { isOpen: false, name: '', width: 650 },
        data: { },
      },
    };
  },
  computed: {
    isAside: {
      get() {
        return this.aside.meta.isOpen;
      },
      set(val) {
        this.aside.meta.isOpen = val;
      },
    },
    asideName: {
      get() {
        return this.aside.meta.name;
      },
      set(val) {
        this.aside.meta.name = val;
      },
    },
    asideWidth: {
      get() {
        return this.aside.meta.width;
      },
      set(val) {
        this.aside.meta.width = val;
      },
    },
    asideData: {
      get() {
        return this.aside.data;
      },
      set(val) {
        this.aside.data = val;
      },
    },
  },
  methods: {
    openAside(name, data, meta) {
      this.aside.meta = { ...meta, name, isOpen: true };
      this.aside.data = { ...data };
    },
    closeAside() {
      this.aside.meta.isOpen = false;
      this.aside.meta.name = '';
      this.aside.data = {};
    },
  },
};