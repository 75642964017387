<template>
  <CustomModal
    v-model="value"
    :fields="modalFields"
    :label="value.isAdded ? 'Изменить интеграцию' : 'Добавить интеграцию'"
    :selection="false"
    @confirm="onConfirm"
  >
    <template #bottom>
      <a
        v-if="mpCode === 'ym'"
        class="link text-primary mt-4"
        @click="onYandexLinkClick"
      >
        Привязать яндекс маркет
      </a>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/Modal/CustomModal/index.vue';
import { payloadMixin } from '@/mixins/payload';

export default {
  name: 'IntegrationEditModal',
  components: { CustomModal },
  mixins: [payloadMixin],
  props: {
    organizationApi: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
    marketplaceId: {
      type: String,
      required: true,
    },
    mpCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: {},
    };
  },
  computed: {
    editFields() {
      if (this.mpCode === 'wb') {
        return ['api'];
      } if (this.mpCode === 'oz') {
        return ['api', 'seller'];
      }
      return [];
    },
    modalFields() {
      const f = {};
      Object.keys(this.fields).forEach((key) => {
        if (this.editFields.includes(key)) {
          f[key] = this.fields[key];
        }
      });
      return f;
    },
  },
  beforeMount() {
    this.value = JSON.parse(JSON.stringify(this.organizationApi));
  },
  methods: {
    onConfirm() {
      if (this.mpCode === 'ym') {
        this.$emit('close');
        return;
      }
      if (this.value.isAdded) {
        this.value.isAdded = null;
      } else {
        this.value.marketplace_id = this.marketplaceId;
        this.value.id = 0;
        this.value.isAdded = null;
      }
      this.$emit('close', this.value);
    },
    onYandexLinkClick() {
      localStorage.setItem('ym:organizationId', this.payload.id);
      window.open(this.fields.yandex_link, '_blank');
    },
  },
};
</script>