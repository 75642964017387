import LinkCellRenderer from '@/pages/profile/cellRenderers/reglinkCompany_link.vue';
import UsageCellRenderer from '@/pages/profile/cellRenderers/reglink_usage.vue';

export default [
  {
    field: 'unique_string',
    cellRenderer: LinkCellRenderer,
    width: 450,
  },
  {
    field: 'count_fact',
    cellRenderer: UsageCellRenderer,
  },
];
