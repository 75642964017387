<template>
  <div class="integrations">
    <v-btn
      color="primary"
      size="small"
      @click="isPopup = true"
    >
      Интеграции
    </v-btn>
    <v-dialog
      v-model="isPopup"
      width="700"
    >
      <v-card>
        <Integrations
          v-model="value"
          :field="field"
          :is-create="isCreate"
          @update:modelValue="onUpdate"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Integrations from '@/components/Organizations/integrations/index.vue';

export default {
  name: 'IntegrationsModal',
  components: { Integrations },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',
      isPopup: false,
    };
  },
  computed: {
    field() {
      return this.fields[this.fieldName];
    },
  },
  beforeMount() {
    this.value = structuredClone(this.modelValue);
  },
  methods: {
    onUpdate(val) {
      this.$emit('update:modelValue', val);
    },
  },
};
</script>