<template>
  <tbody>
    <tr v-if="fields.is_need_filter">
      <td v-for="[fieldName, field] of Object.entries(payloadFields)">
        <v-text-field
          v-model="filterModel[fieldName]"
          :label="`${field.label} - фильтровать`"
          density="compact"
          hide-details
          variant="outlined"
          color="primary"
        ></v-text-field>
      </td>
    </tr>
    <tr v-if="canSelectAll && displayedItems?.length > 1">
      <td
        v-for="(field, j) of Object.entries(payloadFields)"
        :key="j"
        style="min-width: 100px"
      >
        <TableField
          v-if="field[1].can_set_all"
          v-model="allValues[field[0]]"
          :field="field"
          :label="`Применить ко всем (${field[1].label})`"
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="setAllValues({field: field[0], value: $event[field[0]]})"
        />
      </td>
    </tr>
    <tr
      v-for="(item, i) of paginatedItems"
      :key="i"
      style="height: 35px"
    >
      <template v-if="item.id >= 0">
        <td
          v-for="(field, j) of Object.entries(payloadFields)"
          :key="j"
          style="min-width: 100px"
        >
          <slot
            :name="`col.${field[0]}`"
            :update="onUpdate"
            :field="field"
            :item="item"
            :idx="i"
          >
            <editable-field
              :key="`${field[0]}:${i}:${j}`"
              v-model="item[field[0]]"
              :field-name="field[0]"
              :fields="fields"
              :field="fields[fieldName]"
              :loading="loading"
              :show-data-raw="item['rawData'] ? item['rawData'] : getRawItem(i)?.[field[0]]?.value ?? getRawItem(i)?.[field[0]]?.values ?? getRawItem(i)?.[field[0]]"
              @update:modelValue="onUpdate({field, item})"
            />
          </slot>
        </td>
        <td>
          <v-btn
            v-if="!readOnly && !item.canDelete && !deleteRule(item)"
            size="extra-small"
            class="text-caption px-2"
            color="error"
            @click="del(item._index)"
          >
            X
          </v-btn>
        </td>
      </template>
    </tr>
    <v-skeleton-loader
      v-show="loading"
      type="table-row@3"
    ></v-skeleton-loader>
  </tbody>
  <v-pagination
    v-if="!loading && pagesTotal > 1 && fields.is_need_pagination"
    v-model="page"
    :length="pagesTotal"
    color="primary"
    density="compact"
  ></v-pagination>
</template>

<script setup>
import TableField from '../Field/index.vue';
import EditableField from '@/components/Field/EditableField/index.vue';
</script>

<script>
import { defaultTableBodyMixin } from '@/components/Tables/DefaultTable/components/edit/components/Body';

export default {
  name: 'DefaultTableBody',
  mixins: [defaultTableBodyMixin],
  beforeMount() {
    Object.entries(this.payloadFields).forEach(([key]) => {
      this.filterModel[key] = null;
    });
  },
};
</script>