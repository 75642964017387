<template>
  <v-btn
    color="primary"
    variant="tonal"
    size="x-small"
    class="my-4"
    @click="isOpen = !isOpen"
  >
    <v-icon>mdi-plus-box-multiple-outline</v-icon>
    <v-menu
      activator="parent"
      :min-width="350"
      :close-on-content-click="false"
    >
      <v-card>
        <VNumberInput
          v-model="n"
          single-line
          type="number"
          label="Количество"
          variant="outlined"
          control-variant="stacked"
          :max="max"
          :min="1"
        />
        <v-btn
          size="small"
          color="primary"
          @click="add"
        >
          Готово
        </v-btn>
      </v-card>
    </v-menu>
  </v-btn>
</template>
<script>
import { VNumberInput } from 'vuetify/labs/components';

export default {
  name: 'DefaultTableAddMultiple',
  components: { VNumberInput },
  props: {
    max: {
      type: Number,
      default: 100,
    },
  },
  emits: ['add'],
  data() {
    return {
      n: 1,
      isOpen: false,
    };
  },
  methods: {
    add() {
      this.$emit('add', this.n);
      this.n = 1;
    },
  },
};
</script>

<style>
.v-menu > .v-overlay__content {
  position: fixed;
}
</style>