<template>
  <slot
    name="createBtn"
    :save="save"
    :loading="saveLoading"
    :disabled="fieldsLoading"
  >
    <v-btn
      class="mr-3"
      size="small"
      color="primary"
      id="createBtn"
      :loading="saveLoading"
      :disabled="fieldsLoading"
      @click="save(...selectedButton.args)"
    >
      {{ selectedButton.label }}
      <template #append>
        <v-icon size="12" color="gray" @click.stop="isMenuOpen = true">mdi-dots-vertical</v-icon>
      </template>
    </v-btn>
  </slot>
  <v-menu v-model="isMenuOpen" target="#createBtn">
    <v-list>
      <v-list-item
        v-for="(btn, i) of availableButtons"
        :key="i"
        @click="selectedButton = btn"
      >
        {{ btn.label }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'SaveBtn',
  props: {
    buttonFields: {
      type: Object,
      default: () => ({ save: true }),
    },
    saveLoading: {
      type: Boolean,
      default: false,
    },
    fieldsLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Сохранить',
    },
  },
  emits: ['save'],
  data() {
    return {
      selectedButton: {},
      isMenuOpen: false,
    };
  },
  computed: {
    showSaveBtn() {
      return this.buttonFields?.save;
    },
    showSaveShowBtn() {
      return this.buttonFields?.saveShow;
    },
    showSaveCreateBtn() {
      return this.buttonFields?.saveCreate;
    },
    showSaveListBtn() {
      return this.buttonFields?.saveList;
    },
    buttons() {
      return [
        {
          id: 'createBtn',
          label: this.title,
          args: [false],
          show: this.showSaveBtn,
        },
        {
          id: 'createListBtn',
          label: `${this.title} и вернуться к списку`,
          args: [true],
          show: this.showSaveListBtn,
        },
        {
          id: 'createCreateBtn',
          label: `${this.title} и создать еще`,
          args: ['create'],
          show: this.showSaveCreateBtn,
        },
        {
          id: 'createShowBtn',
          label: `${this.title} и перейти к просмотру`,
          args: ['show'],
          show: this.showSaveShowBtn,
        },
      ];
    },
    availableButtons() {
      return this.buttons.filter((btn) => btn.show);
    },
  },
  beforeMount() {
    this.selectedButton = this.availableButtons[0];
  },
  methods: {
    save(...args) {
      this.$emit('save', ...args);
    },
  },
}; </script>