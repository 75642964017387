<template>
  <create
    inline
    style="min-height: auto"
    @save="onSave"
  />
</template>

<script>
import create from '@/components/Create/Create.vue';
import { payloadMixin } from '@/mixins/payload';

export default {
  name: 'ConsignmentCreateModal',
  components: { create },
  mixins: [payloadMixin],
  props: {
    productId: {
      type: Number,
      default: null,
    },
  },
  emits: ['addConsignment'],
  // beforeMount() {
  // if (this.productId) {
  //   this.payload.parent_organization_product_id = this.productId;
  // }
  // },
  methods: {
    onSave(data) {
      this.$emit('addConsignment', { id: data.organization_product_id.value, name: data.organization_product_name });
    },
  },
};
</script>