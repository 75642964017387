<template>
  <div
    v-if="Object.entries(chapters).length"
    class="entity-chapters"
  >
    <v-tabs
      :model-value="tab"
      color="primary"
    >
      <v-tab
        v-for="([key, value], i) of Object.entries(chapters)"
        :key="key"
        :value="value.chapterType"
        :disabled="disabled"
        @click="onUpdate(value.chapterType)"
      >
        {{ i + 1 }}. {{ value.label }}
      </v-tab>
    </v-tabs>
    <v-btn
      v-if="tabIndex !== Object.entries(chapters).length - 1"
      size="small"
      color="primary"
      :disabled="disabled"
      @click="nextTab"
    >
      Далее
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'EntityChapters',
  inject: ['storeModule'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 'main',
    };
  },
  computed: {
    chapters() {
      return this.$store.state[this.storeModule].chapters;
    },
    tabIndex() {
      return Object.values(this.chapters).findIndex(((chapter) => chapter.chapterType === this.tab));
    },
  },
  beforeMount() {
    if (this.chapters.length) {
      this.$emit('tabSelect', 'main');
    }
  },
  methods: {
    nextTab() {
      const oldTab = this.tab;
      const chapters = Object.values(this.chapters);
      const idx = chapters.findIndex((chapter) => chapter.chapterType === oldTab);
      if (idx === chapters.length - 1 || idx === -1) return;
      this.tab = chapters[idx + 1]?.chapterType;
      this.$emit('tabSelect', this.tab);
    },
    onUpdate(val) {
      this.tab = val;
      this.$emit('tabSelect', this.tab);
    },
  },
};
</script>

<style lang="sass">
.entity-chapters
  display: flex
  align-items: center
  gap: 10px
  & .v-tab
    &[aria-selected="true"]
      background: white !important
</style>