import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('storageType');

const state = {
  ...store.state,
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,

  deleteStorageType({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `/api/storageType/delete/${id}`,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
