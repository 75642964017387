import { DefaultEntityStore } from '@/store/defaultEntityStore';
import { columns } from '@/store/modules/organizationProduct/columns';
import { createQueryString, generateListBody, removeValuesWrapper } from '@/utils/utils';

const store = new DefaultEntityStore('organizationProduct');

const state = {
  ...store.state,
  columns,
  isEdit: false,
  data: {
    uploadingItems: [],
    uploadingFilters: {},
  },
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,
  // eslint-disable-next-line no-unused-vars
  getShow({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `api/organizationProduct/show/${payload.id}`,
      }, { root: true })
        .then((res) => {
          const showData = res.data.data;
          if (payload.isTechCard) {
            showData.organization_product_child_products = [
              {
                count: '1',
                id: 0,
                organization_product_id: payload.id,
              },
            ];
            showData.type = {
              value: 'Технологическая карта',
              label: 'Тип',
            };
          }
          commit('setShowDataRaw', showData);
          commit('setShowData', removeValuesWrapper(structuredClone(showData)));
          resolve(res.data);
        })
        .catch((err) => {
          commit('setShowDataError', true);
          reject(err);
        });
    });
  },
  createGroup({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'put',
        query: 'api/productGroup/create',
        sendData: payload,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteGroup({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/productGroup/delete/${id}`,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editGroup({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'put',
        query: 'api/productGroup/edit',
        sendData: payload,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkBarcodes({ dispatch }, payload) {
    let body;
    let url = 'api/organizationProduct/checkBarcodes';
    try {
      const {
        endRow, sortModel, filterModel, limit, isArchive, page,
      } = payload;
      const isAll = page === 'all';
      if (isAll) url += `isAll=${isAll}`;
      body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
    } catch (e) {
      body = { filter: {} };
    }
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
        sendData: body,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUploadingItems({ dispatch, commit }, { payload }) {
    commit('setLoading', { path: 'uploadingItems', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: '/api/organizationProduct/upload/list',
        sendData: payload,
      }, { root: true })
        .then(async (res) => {
          commit('setData', { path: 'uploadingItems', value: res.data.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingItems', value: false });
        });
    });
  },
  sendUploadingItems({ dispatch, commit }, { payload }) {
    commit('setLoading', { path: 'uploadingItems', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: '/api/organizationProduct/upload?',
        sendData: payload,
      }, { root: true })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingItems', value: false });
        });
    });
  },
  getUploadingInfo({ dispatch, commit }, { id, payload }) {
    commit('setLoading', { path: 'uploadingItems', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/organizationProductUpload/show?id=${id}`,
        sendData: payload,
      }, { root: true })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingItems', value: false });
        });
    });
  },
  getUploadingFilters({ dispatch, commit }, { organizationId, organizationApiId }) {
    const query = {
      organization_id: organizationId ? `[${organizationId}]` : undefined,
      organization_api_id: organizationApiId ? `[${organizationApiId}]` : undefined,
    };

    let url = 'api/organizationProductUpload/filters?';
    url = organizationId ? createQueryString(url, query, false) : url;

    commit('setLoading', { path: 'uploadingFilters', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: url,
      }, { root: true })
        .then(async (res) => {
          commit('setData', { path: 'uploadingFilters', value: res.data.data?.filters || {} });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'uploadingFilters', value: false });
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
