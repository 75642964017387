import { createRouter, createWebHistory } from 'vue-router';
// import axios from 'axios';
// import store from '@/store';
import routes from '@/router/routes';

const router = createRouter({
  linkActiveClass: 'is-active',
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.meta?.auth) {
  //   if (store.getters['auth/isAuthorized']) {
  //     next();
  //   } else {
  //     next('/auth/login');
  //   }
  // } else {
  //   next();
  // }
  next();
});
export default router;
