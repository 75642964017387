<template>
  <div
    v-if="field?.field_type"
    class="payloadField"
  >
    <editable-field
      v-model="value[fieldName]"
      :field-name="fieldName"
      :fields="fields"
      :field="fields[fieldName]"
      @update:modelValue="update"
    />
  </div>
</template>

<script setup>
import EditableField from '@/components/Field/EditableField/index.vue';
</script>

<script>

export default {
  name: 'PayloadFields',
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:product'],
  data() {
    return {
      value: {},
    };
  },
  beforeMount() {
    this.value = { ...this.product };
  },
  methods: {
    update() {
      this.$emit('update:product', this.value);
    },
  },
};
</script>