<template>
  <div class="section">
    <Label
      v-if="showLabel"
      class="subtitle px-0"
    >
      <template #default>
        {{ fields.label }}
      </template>
      <template
        v-if="$slots['hint']"
        #hint
      >
        <slot name="hint"></slot>
      </template>
    </Label>
    <div
      v-for="(field, j) of Object.entries(payloadFields)"
      :key="j"
      style="min-width: 100px"
    >
      <slot
        :name="`col.${j}`"
        :update="updateInnerValue"
        :field="field"
        :item="item"
        :idx="i"
      >
        <v-text-field
          v-if="field[1].field_type === 'string'"
          v-model="item[field[0]]"
          :label="field[1].label"
          min="0"
          class="my-2"
          density="compact"
          hide-details
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />
        <v-text-field
          v-else-if="field[1].field_type === 'int' || field[1].field_type === 'number'"
          v-model="item[field[0]]"
          :label="field[1].label"
          min="0"
          class="my-2"
          density="compact"
          type="number"
          hide-details
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />
        <UiSelectSearch
          v-else-if="field[1].field_type === 'selectbox'"
          v-model="item[field[0]]"
          :label="field[1].label"
          class="my-2"
          item-title="name"
          item-value="id"
          :items="field[1].values"
          density="compact"
          :multiple="field[1].is_collection"
          :max-count="field[1]?.max_count || 0"
          :allow-custom="field[1]?.allow_custom_values"
          hide-details
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />
        <CarSelect
          v-else-if="field[1].field_type === 'selectbox_driver_car'"
          v-model="item[field[0]]"
          :items="field[1].values"
          @update:modelValue="updateInnerValue"
        />
        <DriverSelect
          v-else-if="field[1].field_type === 'selectbox_driver'"
          v-model="item[field[0]]"
          :items="field[1].values"
          @update:modelValue="updateInnerValue"
        />
        <v-checkbox
          v-else-if="field[1].field_type === 'checkbox'"
          v-model="item[field[0]]"
          color="primary"
          hide-details
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />

        <template v-else-if="field[1].field_type === 'box_size_select'">
          <BoxSizeSelect
            v-model="item[field[0]]"
            :items="field[1].values"
          />
        </template>

        <defaultTableEdit
          v-else-if="field[1].field_type === 'default_table'"
          v-model="item[field[0]]"
          :fields="field[1]"
          :disabled="disabled && field[1].only_read !== false"
          :read-only="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />

        <storagePicker
          v-else-if="field[1].field_type === 'selectbox_storage'"
          v-model="item[field[0]]"
          :prop-items="field[1].values"
          :prop-areas="field[1].areas"
          :storages="field[1].storages"
          density="compact"
          :multiple="false"
          :label="field[1].label"
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />

        <UiSelectboxProduct
          v-else-if="field[1].field_type === 'selectbox_organization_product'"
          v-model="item[field[0]]"
          :label="field[1].label"
          class="my-2"
          :items="field[1].values"
          density="compact"
          :multiple="field[1].is_collection"
          :max-count="field[1]?.max_count || 0"
          hide-details
          :disabled="(readOnly && field[1].only_read !== false) || field[1].only_read"
          @update:modelValue="updateInnerValue"
        />

        <div v-else />
      </slot>
    </div>
  </div>
</template>

<script>
import DefaultTableEdit from '@/components/Tables/DefaultTable/components/edit/DefaultTableEdit.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import Label from '@/components/UiKit/Label/index.vue';

export default {
  name: 'Section',
  components: {
    storagePicker, BoxSizeSelect, CarSelect, DriverSelect, DefaultTableEdit, Label,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      item: {},
      payloadFields: [],
      emptyElement: { id: 0 },
      excludedFields: ['types', 'label', 'field_type', 'values'],
    };
  },
  watch: {
    fields() {
      this.updateFields();
    },
  },
  beforeMount() {
    this.updateFields();
    this.initItem();
  },
  methods: {
    initItem() {
      const item = JSON.parse(JSON.stringify(this.modelValue ?? {}));

      Object.entries(item).forEach((el) => {
        if (this.fields[el[0]]?.field_type === 'default_table') {
          item[el[0]] = Array.isArray(el[1]) ? el[1] : el[1].values;
        } else if (this.fields[el[0]]?.is_collection && el[1].values && el[1].values.length) {
          item[el[0]] = Array.isArray(el[1]) ? el[1].map((v) => v.id) : el[1].values.map((v) => v.id);
        } else if (el[1]?.value !== undefined) {
          item[el[0]] = el[1].value;
        }
      });

      this.item = item;
      if (!this.item.id) this.item.id = 0;
      this.$emit('update:modelValue', item);
    },
    updateFields() {
      this.payloadFields = {};
      Object.entries(this.fields).forEach((field) => {
        if (!this.excludedFields.includes(field[0])) {
          // eslint-disable-next-line prefer-destructuring
          if (!field[1].field_type) return;
          this.payloadFields[field[0]] = field[1];
        }
      });
    },
    updateInnerValue() {
      this.$emit('update:modelValue', this.item);
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  width: calc(100% + 20px)
  margin-top: 20px
  margin-left: -10px
  padding: 10px
  border-radius: 5px
  border: 1px solid #eee
</style>