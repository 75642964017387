<template>
  <SelectboxSearch
    ref="selectSearch"
    v-model="value"
    v-bind="$attrs"
    :items="itemsValue"
    :label="label"
    @update:model-value="update"
  >
    <template #append-item>
      <v-btn
        color="primary"
        size="x-small"
        @click="openPopup('create')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <template #item="{props, item}">
      <v-list-item v-bind="props">
        <template #append>
          <v-btn
            size="x-small"
            color="error"
            variant="tonal"
            :loading="deleteLoadingId === item.raw.id"
            @click.stop="onDelete(item)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-list-item>
    </template>
  </SelectboxSearch>
  <v-dialog
    v-model="isPopup"
    :width="700"
  >
    <CustomModal
      v-model="payload"
      :fields="createFields"
      :selection="false"
      :label="`${label} - создать`"
      @confirm="onCreate"
    >
    </CustomModal>
  </v-dialog>
</template>

<script setup>
import SelectboxSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import CustomModal from '@/components/Modal/CustomModal/index.vue';
</script>

<script>
import { popupMixin } from '@/mixins/popup';

export default {
  name: 'SelectboxCreate',
  mixins: [popupMixin],
  inject: ['storeModule'],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String || Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: '',
      itemsValue: [],
      payload: {},
      deleteLoadingId: null,
    };
  },
  computed: {
    createFields() {
      return this.field.fields;
    },
  },
  watch: {
    modelValue() {
      this.init();
    },
    items() {
      this.itemsValue = this.items;
    },
  },
  beforeMount() {
    this.init();
    this.itemsValue = this.items;
  },
  methods: {
    init() {
      this.value = this.modelValue;
    },
    update() {
      this.$emit('update:modelValue', this.value);
    },
    onCreate() {
      this.$store.dispatch('common/create', { payload: this.payload, query: {}, entity: this.field.camel_entity })
        .then((data) => {
          this.itemsValue = [...this.itemsValue, {
            id: data.data?.id.value || 0,
            name: data.data?.name.value || '',
            search: [data.data?.name.value, data.data?.id.value],
          }];
          this.$refs?.selectSearch?.updateItems();

          this.closePopup();
        });
    },
    onDelete(item) {
      this.deleteLoadingId = item.raw.id;
      this.$store.dispatch('common/delete', { entity: this.field.camel_entity, id: item.raw.id })
        .then(() => {
          this.itemsValue = this.itemsValue.filter((el) => el.id !== item.raw.id);
          this.$refs?.selectSearch?.updateItems();
        })
        .finally(() => {
          this.deleteLoadingId = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>