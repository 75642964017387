import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('printer');

const state = {
  ...store.state,
  data: {
    printerList: [],
  },
  loading: {
    printerList: false,
    send: false,
  },
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,
  sendToPrint({ dispatch, commit }, {
    entity, id, isAuto = false, printerId,
  }) {
    if (!printerId) return Promise.reject();
    commit('setLoading', { path: 'send', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `/api/print/${entity}/pdf/${id}?is_auto_print=${isAuto}&printer_id=${printerId}`,
      }, { root: true })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'send', value: false });
        });
    });
  },

  getPrinterList({ commit, dispatch }) {
    commit('setLoading', { path: 'printerList', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: '/api/printer/list',
      }, { root: true })
        .then((res) => {
          commit('setData', { path: 'printerList', value: res.data.data?.items || [] });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'printerList', value: true });
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
