
<template>
  <div id="app">
    <router-view />
    <NotificationHandler />
  </div>
</template>

<script>
import NotificationHandler from '@/components/Notification/NotificationHandler.vue';
import store from '@/store';
// Stop error resizeObserver
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    // eslint-disable-next-line no-restricted-globals
    const ctx = self;
    // eslint-disable-next-line no-unused-expressions
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window).ResizeObserver;
(window).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

export default {
  name: 'App',
  components: {
    NotificationHandler,
  },
  computed: {
    theme: {
      get() {
        return this.$store.getters['common/theme'];
      },
      set(val) {
        this.$store.commit('common/setTheme', val);
      },
    },
  },
  beforeMount() {
    this.theme = localStorage.getItem('userTheme') || 'advanced';
    this.$vuetify.theme.global.name = this.theme;
    this.$root.toast = {
      show({ message, color }) {
        store.commit('ajax/addMessage', { message, type: color === 'error' ? 'error' : 'message' });
      },
    };
  },
};
</script>

<style>
#app {
  font-family: var(--fontMain);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
