<template>
  <div class="activator">
    <v-btn
      :id="`button-${id}`"
      :color="color"
      size="x-small"
      :variant="variant"
      :readonly="!showPopup"
    >
      <template v-if="!showPopup">
        <p style="padding-top: 2px">
          {{ text }}
        </p>
      </template>
      <v-icon>
        {{ icon }}
      </v-icon>
      <v-menu
        activator="parent"
        :open-on-hover="hover"
        transition="none"
      >
        <v-card
          class="tooltipText pa-4"
          :class="`bg-${color}`"
        >
          <slot>{{ text }}</slot>
        </v-card>
      </v-menu>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Exclamation',
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'error',
    },
    icon: {
      type: String,
      default: 'mdi-exclamation',
    },
    hover: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'tonal',
    },
    showPopup: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.tooltipText
  max-width: 400px
  font-size: 12px
  opacity: 0.9
</style>