import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('integrationApiToken');

const state = {
  ...store.state,
  data: {
    token: '',
  },
  loading: {
    token: false,
  },
  error: {
    token: false,
  },
};
const getters = {
  ...store.getters,
  token: (s) => s.data.token,
};
const actions = {
  ...store.actions,

  createIntegrationToken({ commit, dispatch }, { payload }) {
    const url = 'api/integrationApiToken/create?';
    commit('setError', { path: 'token', value: false });
    commit('setLoading', { path: 'token', value: true });
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'put',
        query: url,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          commit('setData', { path: 'token', value: res.data.data.token });
          resolve(res);
        })
        .catch((err) => {
          commit('setError', { path: 'token', value: 'Произошла ошибка при получении токена' });
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'token', value: false });
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
