<template>
  <div
    class="pt-1"
  >
    <p class="subtitle text-start">
    </p>
    <slot :name="`item.${fieldName}`">
      <EditableField
        :key="`item.${fieldName}`"
        :model-value="payload[fieldName]"
        :fields="fields"
        :field-name="fieldName"
        :field="fields[fieldName]"
        :show-data-raw="showDataRaw"
        :loading="fieldsLoading"
        @update:modelValue="onUpdate"
        @update:raw="onRawUpdate"
        @setWatchQuery="onSetWatchQuery"
        @set-watch-query-search="onSetWatchQuerySearch"
      ></EditableField>
    </slot>
  </div>
</template>

<script>
import EditableField from '@/components/Field/EditableField/index.vue';
import { tableValueMixin } from '@/mixins/tableValue';

export default {
  name: 'ValueCellRenderer',
  components: { EditableField },
  mixins: [tableValueMixin],
  inject: ['storeModule'],
  props: {
    value: {
      type: [String, Object, Array, Number, Boolean, null, undefined],
      default: null,
    },
    item: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['setWatchQuery', 'update', 'setWatchQuerySearch', 'update:value'],
  computed: {
    fieldName() {
      return this.item.title.fieldName;
    },
  },
  methods: {
    onUpdate(
      val,
    ) {
      // const obj = {};
      // obj[this.fieldName] = val;
      // const newObj = { ...this.payload, ...obj };
      // this.$store.commit('payload/setPayload', { payload: newObj, storeModule: this.storeModule });
      this.$emit('update:value', val);
    },
    onRawUpdate(val) {
      this.$store.commit(`${this.storeModule}/setShowDataRaw`, val);
    },
    onSetWatchQuery(...args) {
      this.$emit('setWatchQuery', ...args);
    },
    onSetWatchQuerySearch(...args) {
      this.$emit('setWatchQuerySearch', ...args);
    },
  },
};

</script>
