<template>
  <v-text-field
    v-model="message"
    class="storagePicker"
    :label="currentLabel"
    auto
    clearable
    :density="density"
    hide-details
    :color="color"
    :readonly="!messageEditing"
    @click.stop="isMenuOpen = true"
    @click:clear="onStorageClear"
    @click:append-inner="messageEditing = !messageEditing"
  >
    <template #append-inner>
      <v-btn
        size="x-small"
        color="primary"
        variant="tonal"
        @click.stop="messageEditing = !messageEditing"
      >
        <v-icon>{{ messageEditing ? 'mdi-check' : 'mdi-magnify-scan' }}</v-icon>
      </v-btn>
    </template>
    <v-menu
      v-model="isMenuOpen"
      target="parent"
      :close-on-content-click="false"
      :lang="'ru'"
      :max-width="700"
      :min-width="720"
      color="white"
      transition="none"
      location="bottom"
    >
      <v-card
        elevation="4"
        class="storagePicker__card"
      >
        <v-select
          v-model="storage"
          :items="storageItems"
          item-title="name"
          item-value="id"
          label="Склад"
          return-object
          no-data-text="Нет доступных складов"
          density="compact"
          clearable
          @click:clear="onStorageClear"
          @update:modelValue="onStorageSelect"
        />

        <v-tabs
          v-if="storage && storage.areas"
          v-model="chapter"
          @update:modelValue="onChapterChange"
        >
          <v-tab
            v-for="(area, i) of Object.entries(storage.areas)"
            :key="i"
            color="primary"
            :value="area[0]"
          >
            {{ area[1].storages.label }}
          </v-tab>
        </v-tabs>
        <v-card
          v-if="chapter && areas?.length"
          class="d-flex bg-secondary pa-2"
          elevation="0"
          style="gap: 10px; overflow-x: auto"
        >
          <v-chip
            v-for="(area, i) of areas"
            :key="i"
            color="primary"
            :variant="area.selected ? 'flat' : 'tonal'"
            size="large"
            style="width: max-content; flex-shrink: 0"
            @click="onSectionSelect(area, 0)"
          >
            {{ area.name }} [{{ area.letter }}]
          </v-chip>
          <p
            v-if="!areas?.length"
            class="d-flex"
          >
            Зон нет
            <UiTooltip
              color="primary"
              icon="mdi-help"
              text="Их можно добавить в редактировании склада"
              class="ml-2"
              hover
            />
          </p>
        </v-card>
        <div class="nodes">
          <template v-for="(node, i) of selectedNodes">
            <storage-node
              :node="node"
              :index="i + 1"
              :storage-type="getInnerStorageType(i + 1)"
              :can-create="false"
              @select="onSectionSelect"
            />
          </template>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="tonal"
            size="small"
            @click="isMenuOpen = false"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>

    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner">
      </slot>
    </template>
  </v-text-field>
</template>

<script>
import StorageNode from './components/StorageNode.vue';
import { removeValuesWrapper } from '@/utils/utils';

export default {
  name: 'StoragePicker',
  components: { StorageNode },
  props: {
    modelValue: {
      type: [Array, Number],
      default: () => {},
    },
    propItems: {
      type: Array,
      default: () => {},
    },
    propAreas: {
      type: Array,
      default: () => [],
    },
    density: {
      type: String,
      default: 'compact',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Склад',
    },
    field: {
      type: Object,
      default: () => ({
        can_be_storage_area_type: true,
      }),
    },
    color: {
      type: String,
      default: 'primary',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:path', 'update:modelValue'],
  data() {
    return {
      storage: { id: null, name: '' },
      chapter: null,
      selectedNodes: [],
      path: [],
      activeItem: { value: null, index: 0 },
      selectedItems: [],
      items: [],
      initialItems: [],
      messageEditing: false,
      messageEditingTimeoutId: null,
      initialValue: null,
      isMenuOpen: false,
    };
  },
  computed: {
    areas: {
      get() {
        return this.storage?.areas?.[this.chapter]?.storages?.values || [];
      },
      set(val) {
        this.storage.areas[this.chapter].storages = val;
      },
    },
    currentLabel() {
      return this.label;
    },
    storageItems() {
      return this.storages.map((el) => {
        el = removeValuesWrapper(el);
        return el;
      });
    },
    storageType() {
      if (!this.selectedNodes.length) return {};
      return this.selectedNodes[0]?.storage_type_id;
    },
    canSelectStorage() {
      return !(!this.field?.areas?.some((el) => el.value === 'storage') && this.isEdit);
    },
    message: {
      get() {
        if (!this.canSelectStorage && !this.selectedNodes.length) return '';
        if (
          !(this.storage && this.storage.id) && !this.initialValue
        ) return '';
        if (!this.selectedNodes?.length) return this.storage?.name || '';
        return `${this.selectedNodes[this.selectedNodes.length - 1]?.name}${this.messageEditing ? '-' : ''}`;
      },
      set(val) {
        this.isMenuOpen = true;
        const storageNames = this.parseStorageName(val);
        if (!storageNames.length) this.onStorageClear();
        let name = '';
        this.path = [];
        this.selectedNodes = [];
        this.areas.forEach((area) => {
          area.selected = false;
        });
        storageNames.forEach((storageName, i) => {
          name += `${i > 0 ? '-' : ''}${storageName}`;
          const storageValue = this.propItems.find((el) => el.name.toLowerCase() === name.toLowerCase());
          if (storageValue) this.path.push(storageValue.id);
        });
        this.findSelected();
        if (this.selectedNodes[this.selectedNodes.length - 1]?.id && !(this.canSelectStorage && this.path.length <= 1)) {
          this.$emit('update:modelValue', this.selectedNodes[this.selectedNodes.length - 1].id);
          this.$emit('update:path', this.path);
        }
      },
    },
  },
  watch: {
    modelValue() {
      this.storages = this.transformStorages(this.propItems);
      this.setModelValue();
      this.findSelected();
    },
  },
  beforeMount() {
    this.storages = this.transformStorages(this.propItems);
    this.setModelValue();
    this.findSelected();
  },
  methods: {
    findSelected() {
      if (!this.path) return;
      this.selectedNodes = [];
      this.path.forEach((storageId, i) => {
        if (i === 0) {
          this.storage = removeValuesWrapper(this.storages.find((el) => String(el.id) === String(storageId)));
        } else if (i === 1) {
          if (!this.storage) return;
          Object.entries(this.storage.areas).forEach((area) => {
            area[1].storages.values.forEach((st) => {
              if (st.id === storageId) {
                this.chapter = area[0];
                st.selected = true;
                this.selectedNodes.push(st);
              }
            });
          });
        } else if (i >= 2) {
          if (this.selectedNodes?.[i - 2]?.storages) {
            const area = this.selectedNodes[i - 2].storages.find((st) => st.id === storageId);
            if (area) {
              area.selected = true;
              this.selectedNodes.push(area);
            }
          }
        }
      });
    },
    transformStorages(inputArray) {
      this.path = Array.isArray(this.modelValue) ? [...this.modelValue] : [this.modelValue];
      const storageMap = new Map();

      inputArray.forEach((item) => {
        storageMap.set(item.id, { ...item, id: item.id, storages: [] });
      });

      inputArray.forEach((item) => {
        const parentId = item.storage_path[item.storage_path.length - 2];
        if (parentId !== undefined && storageMap.has(parentId)) {
          storageMap.get(parentId).storages.push(storageMap.get(item.id));
        }
        // path generation
        if (item.id === this.path[0] && parentId) {
          this.path = [parentId, ...this.path];
        }
      });

      const chart = [];
      const areas = this.propAreas.filter((el) => el.value !== 'storage');

      inputArray.forEach((item) => {
        if (item.storage_path.length === 1) {
          const rootItem = { ...item, id: item.id, areas: {} };
          areas.forEach((area) => {
            rootItem.areas[area.value] = { storages: { values: [], label: area.label } };
          });

          storageMap.get(item.id).storages?.forEach((child) => {
            const childItem = storageMap.get(child.id);
            if (childItem && childItem.area_type) {
              rootItem.areas[childItem.area_type].storages.values.push(childItem);
            }
          });

          chart.push(rootItem);
        }
      });
      return chart;
    },
    setModelValue() {
      // if (Array.isArray(this.modelValue)) this.modelValue = this.modelValue[0] ?? null;
      if (this.modelValue) this.initialValue = this.modelValue;
    },
    onStorageSelect() {
      if (this.storage) this.path = [this.storage.id];
      this.selectedNodes = [];
      if (this.storage && this.storage.id && this.canSelectStorage) {
        this.$emit('update:modelValue', this.storage?.id || null);
        this.$emit('update:path', this.path);
      }
    },
    onSectionSelect(section, i) {
      this.areas.forEach((el) => {
        el.selected = false;
      });
      section.selected = true;
      if (this.selectedNodes[i - 1]) this.selectedNodes[i - 1].selected = true;

      if (!this.selectedNodes[i]) {
        this.selectedNodes.push(section);
      } else {
        this.selectedNodes[i] = section;
        this.selectedNodes = this.selectedNodes.slice(0, i + 1);
      }
      if (!this.path[i + 1]) {
        this.path.push(section.id);
      } else {
        this.path[i + 1] = section.id;
        this.path = this.path.slice(0, i + 2);
      }
      this.$emit('update:modelValue', this.selectedNodes[this.selectedNodes.length - 1].id);
      this.$emit('update:path', this.path);
    },
    onChapterChange() {
      this.unSelectAreas();
    },
    unSelectAreas() {
      this.selectedNodes = [];
    },
    getInnerStorageType(nodeIndex) {
      let i = 0;
      let obj = this.storageType;
      while (i < nodeIndex + 1 && obj) {
        obj = obj?.child_storage_field || null;
        i++;
      }
      return obj;
    },
    onStorageClear() {
      this.storage = { id: null, name: '' };
      this.isMenuOpen = false;
      this.selectedNodes = [];
      this.path = [];
      this.$emit('update:modelValue', null);
    },
    parseStorageName(name) {
      if (typeof name !== 'string') return [];
      return name.split('-').filter((el) => el);
    },
  },
};
</script>

<style>
:root {
  --radius: 2px;
  --baseFg: dimgray;
  --baseBg: white;
  --accentFg: rgba(0, 111, 194, 0);
  --accentBg: #252c31;
}

select {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em; width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}
select::-ms-expand { display: none; }
select:hover { border-color: #888; }
select:focus { border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
select option { font-weight:normal; }
*[dir="rtl"] select, :root:lang(ar) select, :root:lang(iw) select {
  background-position: left .7em top 50%, 0 0;
  padding: .6em .8em .5em 1.4em;
}
</style>

<style lang="sass" scoped>
.storagePicker
  min-width: 300px
  &__card
    max-height: 55vh
  &_content
    padding: 15px
    display: flex
    gap: 5px
    select
      border: 1px solid #eee
      border-radius: 5px
      padding-inline: 5px
  &_actions
    display: flex
    align-items: center
    gap: 10px
.nodes
  max-height: 450px
  overflow-y: scroll
</style>