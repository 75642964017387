export const filterObj = {
  greaterThan: 1,
  lessThan: 2,
  equals: 3,
  greaterThanOrEqual: 4,
  lessThanOrEqual: 5,
  contains: 6,
  inRange: 7,
};
export const filterObjInvert = {
  1: 'greaterThan',
  2: 'lessThan',
  3: 'equals',
  4: 'greaterThanOrEqual',
  5: 'lessThanOrEqual',
  6: 'contains',
  7: 'inRange',
};
export const moduleTransaltion = {
  acceptance: 'Приёмка',
  storages: 'Склады',
  regLinks: 'Ссылки на регистрацию',
  registrationLinkCompany: 'Ссылки на регистрацию',
  users: 'Сотрудники',
  organizations: 'Организации',
  marketplaces: 'Маркетплейсы',
  workers: 'Рабочие',
  role: 'Роли',
  company: 'Клиенты',
  workerRecord: 'Незаполненная работа',
  workerProfile: 'Сотрудник',
};

export function dataDefinition() {
  return {
    is_marking_kiz: {
      type: 'kizSelect',
    },
    type: {
      title: 'Тип',
      type: 'selectbox',
      multipleSelection: false,
    },
    status: {
      title: 'Статус',
      type: 'selectbox',
      multipleSelection: false,
    },
    // is_archive: {
    //   title: 'В архиве?',
    //   type: 'boolean',
    // },
    // organization: {
    //   title: 'Организация',
    //   type: 'selectbox',
    //   valuesGetter: 'common/organizations',
    //   multipleSelection: false,
    // },
    // organization_id: {
    //   title: 'Организация',
    //   type: 'selectbox',
    //   valuesGetter: 'common/organizations',
    //   multipleSelection: false,
    // },
    // storage: {
    //   title: 'Склад',
    //   type: 'selectbox',
    //   multipleSelection: false,
    // },
    // storage_id: {
    //   title: 'Склад',
    //   type: 'selectbox',
    //   multipleSelection: false,
    // },
    // received_products: {
    //   title: 'Принятые товары',
    //   type: 'productTable',
    // },
    // boxOrganizationProducts: {
    //   title: 'Товары',
    //   type: 'productTable',
    // },
    // organization_apis: {
    //   title: 'Api-токены',
    //   type: 'apiTokenTable',
    // },
    areas: {
      title: '',
      type: 'storageTable',
    },
    // storageWBs: {
    //   title: 'Склады маркетплейсов',
    //   type: 'storageWBsTable',
    // },
  };
}
