<template>
  <v-card
    style="min-height: 500px"
  >
    <v-card-title>ТАРы</v-card-title>
    <v-list
      v-if="!loading"
      class="boxList"
      density="compact"
      style="max-height: 450px"
    >
      <v-list-item
        v-for="(box, i) of boxes"
        :key="i"
        :active="selectedBoxes.find((b) => box.id === b.id)"
        density="compact"
        @click="toggleBox(box)"
      >
        <div
          class="boxList_item"
        >
          <v-btn
            v-if="box?.organization_products?.length"
            :icon="box.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            variant="text"
            size="small"
            @click.stop="box.isOpen = !box.isOpen"
          />
          <v-icon>mdi-barcode</v-icon>
          <p style="font-weight: bold">
            ТАРа {{ box.name }} ({{ box.barcode }})
          </p>
          <v-spacer />
          <v-checkbox
            :model-value="!!selectedBoxes.find((b) => box.id === b.id)"
            hide-details
          />
        </div>
        <v-list
          v-if="box.isOpen"
          density="compact"
          class="boxList_products"
        >
          <div
            v-for="(p, j) of box.organization_products"
            :key="j"
            class="boxList_products__item"
          >
            <p>{{ p.name }}</p>
            <p>{{ p.count }}шт.</p>
          </div>
        </v-list>
      </v-list-item>
      <p
        v-if="!boxes.length"
        class="pl-2"
      >
        Нет ТАР
      </p>
    </v-list>
    <v-skeleton-loader
      v-else
      type="article@2"
    />
    <v-card-actions class="mt-auto">
      <v-spacer />
      <v-btn
        color="primary"
        variant="tonal"
        @click="submit"
      >
        Готово
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BoxModal',
  inject: ['camelEntity', 'entityId'],
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      required: true,
    },
    storageId: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    currentBoxes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['addBoxes', 'deleteBoxes'],
  data() {
    return {
      boxes: [],
      selectedBoxes: [],
      loading: false,
    };
  },
  computed: {
    notSelectedBoxes() {
      return this.boxes.filter((box) => !this.selectedBoxes.find((b) => b.id === box.id));
    },
  },
  beforeMount() {
    this.selectedBoxes = JSON.parse(JSON.stringify(this.currentBoxes));
    this.loading = true;
    const body = {
      organization_product_ids: this.items.map((item) => item.main_organization_product.id),
      organization_id: +this.$route.query.organization_id,
      entity_id: +this.entityId,
      camel_entity: this.camelEntity,
    };
    this.$store.dispatch('box/getAvailableBoxes', { id: this.storageId, payload: body })
      .then((res) => {
        this.boxes = res.data.boxes;
        this.loading = false;
      });
  },
  methods: {
    submit() {
      this.$emit('deleteBoxes', this.notSelectedBoxes);
      this.$emit('addBoxes', this.selectedBoxes);
    },
    toggleBox(box) {
      if (!this.selectedBoxes.find((b) => b.id === box.id)) {
        this.selectedBoxes.push(box);
      } else {
        this.selectedBoxes = this.selectedBoxes.filter((b) => b.id !== box.id);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.boxList
  &_item
    display: flex
    align-items: center
    gap: 10px
  &_products
    margin-bottom: 10px
    background: transparent
    &__item
      display: flex
      align-items: center
      gap: 10px
</style>