import { mapState } from 'vuex';
import isEqual from 'lodash/isEqual';
import { dataDefinition, moduleTransaltion } from '@/const/const';

export const editMixin = {
  inject: {
    isPage: {
      default: true,
    },
    pageSize: {
      default: 13,
    },
    hiddenFields: {
      from: 'edit_hiddenFields',
      default: () => [],
    },
    storeModule: {},
    outerQuery: {
      type: Object,
      default: () => {},
    },
    fields_query: {
      from: 'fields_query',
      default: () => {},
    },
  },
  props: {
    reactiveQuery: {
      type: Object,
      default: () => {},
    },
    inline: {
      type: Boolean,
      default: false,
    },
    propId: {
      type: Number,
      default: null,
    },
    showCopyBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['save', 'back'],
  data() {
    return {
      chapter: 'main',
      openedChapters: [],
      moduleTransaltion,
      columns: [
        // {
        //   key: 'title',
        //   width: 200,
        //   sortable: false,
        //   excludeChapters: ['workers'],
        // },
        {
          key: 'value',
          sortable: false,
          excludeChapters: [],
        },
      ],
      dataDefinition: dataDefinition(this.storeModule),
      clearOnChange: {},
      // query that triggers fields request
      watchQuery: {},
      // query that not triggers fields request
      query: {},
      watchQueryTimeoutId: null,
      lastFieldsParams: {},
      lastFields: {},

      isAlert: false,
      alertMessage: '',
      isAjax: false,
      isDataLoaded: false,
    };
  },
  computed: {
    ...mapState('account', {
      chapters: (s) => s.chapters,
    }),
    id() {
      return (this.copyId ?? this.propId ?? this.payload.id ?? this.$route.params.id) || 0;
    },
    allChapters() {
      const obj = {};
      Object.entries(this.chapters).forEach((ch) => {
        Object.entries(ch[1].sections).forEach((c) => {
          obj[c[0]] = c[1];
        });
        obj[ch[0]] = ch[1];
      });
      return obj;
    },
    // header chapters
    tabChapters() {
      return this.$store.state[this.storeModule].chapters;
    },
    showData() {
      return this.$store.getters[`${this.storeModule}/showData`];
    },
    showDataRaw() {
      return this.$store.getters[`${this.storeModule}/showDataRaw`];
    },
    saveLoading() {
      return this.$store.getters[`${this.storeModule}/saveLoading`];
    },
    fields() {
      return this.$store.getters[`${this.storeModule}/fields`];
    },
    fieldsLoading() {
      return this.$store.getters[`${this.storeModule}/fieldsLoading`];
    },
    moduleType() {
      return 'edit';
    },
    completedQuery() {
      const idKey = `${this.storeModule}_id`;
      const obj = {
        ...this.watchQuery,
        ...this.query,
        ...this.outerQuery,
        ...this.fields_query,
        chapterType: this.chapter,
        moduleType: this.moduleType,
      };
      obj[idKey] = this.id;
      return obj;
    },
    asideName() {
      return this.$store.state[this.storeModule].aside.meta.name;
    },
    asideIsOpen: {
      get() {
        return this.$store.state[this.storeModule].aside.meta.isOpen;
      },
      set(val) {
        this.$store.commit(`${this.storeModule}/setAsideMeta`, { path: 'isOpen', value: val });
      },
    },
    asideData() {
      return this.$store.state[this.storeModule].aside.data;
    },
    asideWidth() {
      return this.$store.state[this.storeModule].aside.meta.width;
    },
    filteredColumns() {
      return this.columns.filter((col) => !col.excludeChapters.includes(this.chapter));
    },
    organizationId() {
      return this.showData.organization.id;
    },
    copyId() {
      return this.$route.query.copyFrom;
    },
    isCopy() {
      return !!this.copyId;
    },
    actions() {
      return this.$store.getters[`${this.storeModule}/actions`];
    },
    isProfile() {
      return this.$route.path.includes('profile') ? '/profile' : '';
    },
    abortController: {
      get() {
        return this.$store.getters[`${this.storeModule}/abortController`];
      },
      set(value) {
        this.$store.commit(`${this.storeModule}/setAbortController`, value);
      },
    },
  },
  beforeUnmount() {
    this.$store.commit(`${this.storeModule}/setShowDataRaw`, {});
  },
  methods: {
    goBack() {
      if (this.$route.query.routeFrom === 'show') {
        this.$router.push(`${this.isProfile}/${this.storeModule}/show/${this.$route.params.id}`);
      } else if (this.isPage) {
        this.$router.push(`${this.isProfile}/${this.storeModule}/list`);
      } else {
        this.$emit('back');
      }
    },
    goToCreate() {
      this.$router.push(`${this.isProfile}/${this.storeModule}/create`);
    },
    goToShow(id) {
      this.$router.push(`${this.isProfile}/${this.storeModule}/${id}/show`);
    },
    closeAlert() {
      this.isAlert = false;
      this.alertMessage = '';
    },
    closeAside() {
      this.$store.commit(`${this.storeModule}/setAsideMeta`, { path: 'isOpen', value: false });
    },
    setWatchQuery({ value, field }) {
      const obj = { ...this.watchQuery };
      if ((!obj[`${field}`] || obj[`${field}`] !== value) && typeof value !== 'object') {
        obj[`${field}`] = value;
        this.watchQuery = obj;
      }
    },
    setWatchQuerySearch(value, field) {
      this.watchQuery[`${field}.search`] = value;
      this.getFields();
    },
    getFields(forceReplace = false, forceReplaceOrg = false) {
      this.lastFields = structuredClone(this.fields);
      // this.abort();
      const params = { query: { ...this.completedQuery, allField: false, from: 'gf' } };
      if (isEqual(params, this.lastFieldsParams)) return new Promise((resolve) => resolve);
      this.lastFieldsParams = params;
      return this.$store.dispatch(`${this.storeModule}/getFields`, params)
        .then(() => {
          this.generatePayload({
            sendFields: true, forceReplace, forceReplaceOrg, oldFields: this.lastFields,
          });
        });
    },
    toCopy() {
      window.open(`${this.$route.path.startsWith('/profile') ? '/profile' : ''}/${this.storeModule}/create?copyFrom=${this.showData.id}`, '_blank');
    },
    toShow() {
      this.$router.push(`${this.isProfile}/${this.storeModule}/show/${this.$route.params.id}?routeFrom=edit`);
    },
    transformObject(obj) {
      if (!obj || typeof obj !== 'object') return obj;
      const newObj = JSON.parse(JSON.stringify(obj));

      // eslint-disable-next-line no-shadow
      function moveValuesField(obj, show) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj[key]?.hasOwnProperty('values')) {
              obj[key] = obj[key].values;
            }
            // eslint-disable-next-line no-prototype-builtins
            if (obj[key]?.hasOwnProperty('value')) {
              obj[key] = obj[key].value;
            }
            // eslint-disable-next-line no-prototype-builtins
            if (obj[key]?.hasOwnProperty('file_paths') && !show) {
              delete obj[key].file_paths;
            }

            moveValuesField(obj[key], show);
          }
        }
      }

      moveValuesField(newObj, this.show);

      return newObj;
    },
    abort() {
      this.abortController.abort();
      this.abortController = new AbortController();
    },
  },
};