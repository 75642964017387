<template>
  <div class="d-flex align-center">
    <Label>
      <template #default>
        {{ label }}
      </template>
      <template
        v-if="$slots['hint']"
        #hint
      >
        <slot name="hint"></slot>
      </template>
    </Label>
    <a
      class="storageLink text-primary"
      :href="`${basePath}${ link || params?.value?.url }`"
      target="_blank"
    >
      {{ params?.value?.label || 'Перейти' }}
    </a>
  </div>
</template>

<script>
import Label from '@/components/UiKit/Label/index.vue';

export default {
  name: 'LinkCellRenderer',
  components: { Label },
  props: {
    link: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    basePath() {
      return `${window.location.origin}/`;
    },
  },
};
</script>

<style lang="sass" scoped>
.storageLink
  max-width: 400px
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  word-break: break-all
  margin-right: 10px
  overflow: hidden
  &:hover
    text-decoration: underline

</style>
