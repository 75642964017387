export default {
  namespaced: true,
  state: {
    purchaseInfo: {},
    groups: [],
  },
  mutations: {
    setPurchaseInfo(state, payload) {
      state.purchaseInfo = payload;
    },
    setGroups(state, payload) {
      state.groups = payload;
    },
  },
  actions: {
    getAll({ dispatch }) {
      return Promise.all([dispatch('getPaymentGroups')]);
    },
    getPurchaseInfo({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: `api/payment/purchaseInfo?tariffId=${payload.tariffId}&periodId=${payload.periodId}`,
        }, { root: true })
          .then(async (resp) => {
            commit('setPeriods', resp.data.data);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPaymentGroups({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: 'api/payment/group/list',
        }, { root: true })
          .then(async (resp) => {
            commit('setGroups', resp.data.data);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    getGateway({ rootGetters, dispatch }, sendData) {
      const user = rootGetters['account/user'];
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: `api/payment/${user.user_company_id}/gateway`,
          sendData,
        }, { root: true })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    purchaseInfo: (state) => state.purchaseInfo,
    groups: (state) => state.groups,
  },
};
