export default [
  {
    title: 'Светлая',
    name: 'advanced',
    dark: false,
    showTooltip: false,
    showBgImage: true,
    formFieldVariant: 'outlined',
    sidebarWidth: 310,
    colors: {
      info: '#747af2',
      dark: '#272b3d',
      'dark-1': '#1f2234',
      background: '#F2F2F2',
      surface: '#FFFFFF',
      primary: '#2757FC',
      secondary: '#DCEFFF',
      error: '#fc2727',
      darkRed: '#9a1919',
      pink: '#FEB4FF',
      purple: '#A16CFF',
      green: '#0AD105',
      orange: '#F98D1E',
      warning: '#FFBE5B',
      'warning-dark': '#975B00',
      success: '#4ADA85',
      'success-dark': '#027D1D',

      sidebar: '#FFFFFF',
      sidebarBtn: '#2757FC',
    },
  },
  {
    title: 'Тёмная',
    name: 'dark',
    dark: true,
    showTooltip: false,
    showBgImage: true,
    formFieldVariant: 'outlined',
    sidebarWidth: 310,
    colors: {
      info: '#747af2',
      dark: '#272b3d',
      'dark-1': '#1f2234',
      background: '#161618',
      surface: '#1b1b1f',
      primary: '#2757FC',
      secondary: '#444950',
      error: '#fc2727',
      darkRed: '#9a1919',
      pink: '#FEB4FF',
      purple: '#A16CFF',
      green: '#0AD105',
      orange: '#F98D1E',
      warning: '#FFBE5B',
      'warning-dark': '#975B00',
      success: '#4ADA85',
      'success-dark': '#027D1D',

      sidebar: '#1b1b1f',
      sidebarBtn: '#FFF',
    },
  },
];