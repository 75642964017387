<template>
  <v-card
    v-show="showSnackbar"
    style="background: rgba(40,40,40,0.8)"
    :timeout="timer"
    bottom
    right
    class="toast"
    @click="$emit('removeMessageById')"
  >
    <div class="toast__header">
      <div style="display: grid; justify-items: center">
        <v-icon
          class="mb-6 ml-1"
          :size="25"
          :color="color"
        >
          {{ icon }}
        </v-icon>
        <v-btn
          :color="color"
          class="ml-2"
          size="x-small"
          icon="mdi-content-copy"
          @click.stop="copyMsg"
        />
      </div>
      <v-card-subtitle
        class="subtitle"
        style="white-space: normal; width: 90%"
        :class="`text-${color}`"
      >
        {{ message }}
      </v-card-subtitle>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'Toast',
  props: {
    showSnackbar: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'green',
    },
    timer: {
      type: Number,
      default: 3000,
    },
    icon: {
      type: String,
      default: 'mdi-check',
    },
  },
  methods: {
    copyMsg() {
      const input = document.createElement('textarea');
      input.innerHTML = this.message;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
  },
};
</script>
<style lang="sass" scoped>
.toast
  padding: 10px !important
  padding-right: 20px !important
  min-width: 300px
  border-radius: 5px !important
  &__header
    display: flex
    gap: 10px
    align-items: start
    max-width: 460px
    max-height: 150px
    overflow: hidden

  .subtitle
    text-overflow: ellipsis
</style>
