<template>
  <div>
    <div class="text-start">
      <Label>
        <template #default>
          {{ label }}
        </template>
        <template
          v-if="$slots['hint']"
          #hint
        >
          <slot name="hint"></slot>
        </template>
      </Label>
    </div>
    <div class="fileExtension">
      <div
        class="fileExtension_files"
      >
        <div
          v-for="(f) of files"
          class="fileExtension_files__file"
          @click="onFileClick(f)"
        >
          <button
            class="deleteBtn"
            @click.stop="deleteFile(f.id)"
          >
            x
          </button>
          <v-icon size="50">
            mdi-file
          </v-icon>
          <p
            v-if="f"
            class="fileExtension_files-text px-2"
          >
            {{ f.name }}
          </p>
        </div>
      </div>
      <div
        v-if="isEdit"
        class="fileExtension_actions"
      >
        <v-file-input
          v-model="file"
          label="Загрузить"
          density="compact"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          :loading="loading"
          :error-messages="errors"
          :error="errors.length"
          :accept="accept"
          clearable
          @update:modelValue="onFileLoad"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import Label from '@/components/UiKit/Label/index.vue';

export default {
  name: 'UploadedFile',
  components: { Label, draggable },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    rawData: {
      type: Array,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '*',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'update:raw'],
  data() {
    return {
      files: [],
      errors: [],
      file: null,
      dragging: false,
    };
  },
  computed: {
    ...mapState('file', {
      loading: (s) => s.loading.file,
    }),
  },
  watch: {
    modelValue() {
      this.init();
    },
    rawData() {
      this.init();
      // setTimeout(() => {
      //   this.$emit('update:modelValue', this.files?.[0]?.id ?? null);
      // }, 2500);
    },
  },
  beforeMount() {
    this.init();
    this.onFilesUpdate();
  },
  methods: {
    init() {
      if (this.multiple) {
        if (Array.isArray(this.rawData)) {
          this.files = structuredClone(...this.rawData);
        } else {
          this.files = [this.rawData];
        }
      } else if (this.rawData && this.rawData?.id) {
        this.files = [this.rawData];
      } else {
        this.files = [];
      }
    },
    deleteFile(id) {
      this.$store.dispatch('file/deleteFile', { id })
        .then(() => {
          this.files = this.files.filter((file) => file.id !== id);
          this.onFilesUpdate();
        });
    },
    onFileLoad(val) {
      this.file = [val];
      this.loadFile();
    },
    loadFile() {
      if (!this.file) return;
      const formData = new FormData();
      formData.append(this.file[0]?.name || 'file1', this.file[0]);

      this.$store.dispatch('file/uploadFiles', { payload: formData })
        .then((data) => {
          this.files = [data.data[0]];
          this.onFilesUpdate();
        })
        .catch(() => {
          this.errors.push('Ошибка при загрузке файла');
          setTimeout(() => {
            this.errors = [];
          }, 2000);
        })
        .finally(() => {
          this.file = null;
        });
    },
    onFilesUpdate() {
      if (this.multiple) {
        this.$emit('update:raw', this.files);
        this.$emit('update:modelValue', this.files.map((el) => el.id));
      } else {
        this.$emit('update:raw', this.files?.[0]);
        this.$emit('update:modelValue', this.files?.[0]?.id ?? null);
      }
    },
    onFileClick(file) {
      window.open(file.url, '_blank');
    },
  },
};
</script>

<style lang="sass" scoped>
.fileExtension
  padding-bottom: 10px
  display: grid
  &_files
    display: flex
    justify-content: start
    gap: 5px
    margin-bottom: 10px
    &-text
      font-size: 10px
      white-space: nowrap
      overflow: hidden
    &__file
      position: relative
      cursor: pointer
      transition: .3s ease
      width: 150px
      height: 75px
      border: 1px solid #eee
      border-radius: 5px
      .deleteBtn
        width: 17px
        height: 17px
        border-radius: 10px
        position: absolute
        top: 5px
        right: 5px
        font-size: 10px
        background: white
        border: 1px solid var(--primary)
        color: var(--primary)
        font-weight: bold
        &:hover
          filter: brightness(80%)
  &_actions
    display: grid
    grid-template-columns: 300px 50px
    justify-content: start
    gap: 20px
</style>