<template>
  <div class="timeSlotPicker">
    <Label>
      <template #default>
        {{ label }}
      </template>
      <template
        v-if="$slots['hint']"
        #hint
      >
        <slot name="hint"></slot>
      </template>
    </Label>
    <div>
      <TimePicker
        :model-value="time1"
        :disabled="disabled"
        @update:modelValue="onTime1Changed"
      />
    </div>
    <div>
      <TimePicker
        :model-value="time2"
        :disabled="disabled"
        @update:modelValue="onTime2Changed"
      />
    </div>
  </div>
</template>

<script>
import TimePicker from '@/components/UiKit/Form/TimePicker/index.vue';
import Label from '@/components/UiKit/Label/index.vue';

export default {
  components: { Label, TimePicker },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time1: null,
      time2: null,
    };
  },
  beforeMount() {
    if (this.modelValue) {
      [this.time1, this.time2] = this.modelValue.trim().split('-');
    }
  },
  methods: {
    onTime1Changed(val) {
      this.time1 = val;
      this.$emit('update:modelValue', `${this.time1 || '00:00'}-${this.time2 || '00:00'}`);
    },
    onTime2Changed(val) {
      this.time2 = val;
      this.$emit('update:modelValue', `${this.time1 || '00:00'}-${this.time2 || '00:00'}`);
    },
  },
};
</script>

<style>
.vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: var(--primary);
}
</style>
<style lang="sass" scoped>
.timeSlotPicker
  display: flex
  align-items: center
  gap: 10px
</style>