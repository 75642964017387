import { DefaultEntityStore } from '@/store/defaultEntityStore';
import addColumns from './addColumns';
import { generateListBody } from '@/utils/utils';

const store = new DefaultEntityStore('remnant');

const state = {
  ...store.state,
  addColumns,
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,

  getRemnantInfo({ dispatch }, { id, listParams }) {
    const url = `api/remnant/organizationProduct/${id}/storages/tree?`;
    let body = {};
    try {
      const {
        endRow, sortModel, filterModel, limit, isArchive, page,
      } = listParams;
      body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
    } catch (e) {
      body = { filter: {} };
    }
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
        sendData: body,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getQuantityFBO({ dispatch }, { id, listParams }) {
    const url = `api/remnant/organizationProduct/${id}/fbo?`;

    let body = {};
    try {
      const {
        endRow, sortModel, filterModel, limit, isArchive, page,
      } = listParams;
      body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
    } catch (e) {
      body = { fitler: {} };
    }
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
        sendData: body,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getQuantityFBS({ dispatch }, { id, listParams }) {
    const url = `api/remnant/organizationProduct/${id}/fbs?`;

    let body = {};
    try {
      const {
        endRow, sortModel, filterModel, limit, isArchive, page,
      } = listParams;
      body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
    } catch (e) {
      body = { filter: {} };
    }

    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
        sendData: body,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getQuantityPlanned({ dispatch }, { id, listParams }) {
    const url = `api/remnant/organizationProduct/${id}/planned?`;

    let body = {};
    try {
      const {
        endRow, sortModel, filterModel, limit, isArchive, page,
      } = listParams;
      body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
    } catch (e) {
      body = { filter: {} };
    }

    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
        sendData: body,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getQuantityShipped({ dispatch }, { id, listParams }) {
    const url = `api/remnant/organizationProduct/${id}/shipped?`;

    let body = {};
    try {
      const {
        endRow, sortModel, filterModel, limit, isArchive, page,
      } = listParams;
      body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
    } catch (e) {
      body = { filter: {} };
    }

    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
        sendData: body,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
