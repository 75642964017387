<template>
  <thead>
    <tr>
      <th v-if="fields.organization_product_from_id">
        Начальный товар организации
      </th>
      <th style="min-width: 250px">
        Товар организации
      </th>
      <th v-if="fields.count">
        Количество
      </th>
      <th>Планируемое количество</th>
      <th v-if="fields.count_not_distributed">
        Нераспределенное количество
      </th>
      <th
        v-for="(field, i) of Object.entries(payloadFields)"
        :key="i"
        class="text-left"
        style="min-width: 200px"
      >
        {{ field[1].label }}
      </th>
      <th
        class="text-left"
      />
    </tr>
  </thead>
</template>
<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    payloadFields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>