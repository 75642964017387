<template>
  <div class="count">
    <v-chip
      :color="params.data.count_fact === params.data.count_plan ? 'error' : 'success'"
    >
      {{ params.data.count_fact }}/{{ params.data.count_plan }}
    </v-chip>
  </div>
</template>
<script>
export default {
  name: 'ReglinkUsageCellrenderer',
};
</script>
