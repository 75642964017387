<template>
  <p v-if="params.data.job_name">
    {{ params.data.job_name }}
  </p>
  <v-checkbox
    v-else
    :model-value="value"
    :readonly="true"
    color="primary"
    hide-details
    class="worker-list-active-work-cell"
  />
</template>

<script>
export default {
  name: 'WorkerListCheckbox',
  computed: {
    value() {
      return this.params.value;
    },
  },
};
</script>

<style>
.worker-list-active-work-cell .v-selection-control {
  min-height: auto !important;
}
</style>
