<template>
  <UiSelectSearch
    v-model="value"
    class="my-2"
    item-title="name"
    item-value="id"
    :items="items"
    density="compact"
    :multiple="false"
    hide-details
    style="min-width: 200px"
    :disabled="disabled"
    @update:modelValue="$emit('update:modelValue', value)"
  />
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
    };
  },
  beforeMount() {
    this.value = this.modelValue || 0;
    this.$emit('update:modelValue', this.value);
  },
};
</script>