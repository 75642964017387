import { generateListBody, removeValuesWrapper, createQueryString } from '@/utils/utils';
import initialMutations from '@/store/initialMutations';


export class DefaultEntityStore {
  constructor(storeModule) {
    this.state = {
      columns: [],
      addColumns: [],
      list: [],
      listCounting: {},
      listExcelSample: null,
      listExcelExport: false,
      filters: [],
      actions: {},
      checkboxSelection: {},
      externalFilters: [],
      filterModel: [],
      fields: [],
      chapters: [],
      showData: {},
      showDataRaw: {},
      storeModule,
      abortController: new AbortController(),
      aside: {
        data: {},
        meta: {
          isOpen: false,
          name: '',
          width: 500,
        },
      },
      modal: {
        data: {},
        meta: {
          isOpen: false,
          name: '',
          width: 500,
        },
      },
      loading: {
        fields: false,
        filters: false,
        list: false,
        show: false,
        save: false,
        excel: false,
      },
    };
    this.mutations = {
      ...initialMutations,
      setList(state, payload) {
        state.list = payload;
      },
      setListExcelSample(state, payload) {
        state.listExcelSample = payload;
      },
      setListCounting(state, payload) {
        state.listCounting = payload;
      },
      setFilters(state, payload) {
        state.filters = payload;
      },
      setExternalFilters(state, payload) {
        state.externalFilters = payload;
      },
      setFilterModel(state, payload) {
        state.filterModel = payload;
      },
      setAbortController(state, payload) {
        state.abortController = payload;
      },
      appendFilterModel(state, payload) {
        Object.entries(payload).forEach((filter) => {
          // eslint-disable-next-line prefer-destructuring
          state.filterModel[filter[0]] = filter[1];
        });
      },
      setFields(state, payload) {
        state.fields = payload;
      },
      setChapters(state, payload) {
        state.chapters = payload;
      },
      setShowData(state, payload) {
        state.showData = payload;
      },
      setShowDataRaw(state, payload) {
        state.showDataRaw = payload;
      },
      setColumns(state, payload) {
        const result = [];
        Object.entries(payload).forEach((filter) => {
          const obj = {};
          // eslint-disable-next-line prefer-destructuring
          obj.field = filter[0];
          result.push(Object.assign(obj, filter[1]));
        });
        state.columns = result;
      },
      setAsideMeta(state, { path, value }) {
        state.aside.meta[path] = value;
      },
      setAsideData(state, { path, value }) {
        state.aside.data[path] = value;
      },
      clearAsideData(state) {
        state.aside.data = {};
      },
      setState(state, { path, value }) {
        state[path] = value;
      },
      openModal(state, { name, data, meta }) {
        this.modal.meta = { ...meta, name, isOpen: true };
        this.modal.data = { ...data };
      },
    };
    this.getters = {
      list: (state) => state.list,
      listCounting: (state) => state.listCounting,
      listExcelSample: (state) => state.listExcelSample,
      listExcelExport: (state) => state.listExcelExport,
      abortController: (state) => state.abortController,
      columns: (state) => state.columns,
      addColumns: (state) => state.addColumns,
      prependColumns: (state) => state.prependColumns || [],
      filters: (state) => state.filters,
      actions: (state) => state.actions,
      checkboxSelection: (state) => state.checkboxSelection,
      externalFilters: (state) => state.externalFilters,
      filterModel: (state) => state.filterModel,
      fields: (state) => state.fields,
      fieldsLoading: (state) => state.loading.fields,
      showData: (state) => state.showData,
      showDataRaw: (state) => state.showDataRaw,
      showLoading: (state) => state.loading.show,
      saveLoading: (state) => state.loading.save,
      excelLoading: (state) => state.loading.excel,
      // eslint-disable-next-line no-new-object
      config: () => new Object({ editable: true, showable: true, archivable: true }),

      isModalOpen: (state) => state.modal.meta.isOpen,
      modalName: (state) => state.modal.meta.name,
      modalWidth: (state) => state.modal.meta.width,
      modalData: (state) => state.modal.data,
    };
    this.actions = {
      getList({ commit, state, dispatch }, payload) {
        let body;
        const url = `api/${storeModule}/list?`;
        try {
          const {
            endRow, sortModel, filterModel, limit, isArchive, page,
          } = payload;
          body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
        } catch (e) {
          body = { filter: {} };
        }
        const isAll = payload.isAll;

        if (isAll) body.filter.is_all = true;
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: url,
            sendData: body,
            signal: state.abortController.signal,
          }, { root: true })
            .then(async (res) => {
              commit('setList', res.data.data.items);
              resolve({ rowData: res.data.data.items, totalCount: +res.data.data.total_count, fields: res.data.data.fields });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      getListCounting({ commit, state, dispatch }, payload) {
        let body;
        try {
          const {
            endRow, sortModel, filterModel, limit, isArchive, page,
          } = payload;
          body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
        } catch (e) {
          body = { filter: {} };
        }
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: `api/${storeModule}/counting`,
            sendData: body,
          }, { root: true })
            .then(async (res) => {
              commit('setListCounting', res.data.data);
              resolve(res.data.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      getExcelList({ commit, dispatch, state }, { listParams, columnFields }) {
        commit('setLoading', { path: 'excel', value: true });
        let body;
        try {
          const {
            endRow, sortModel, filterModel, limit, isArchive, page,
          } = listParams;
          body = generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page);
          body = { ...body, columnFields };
        } catch (e) {
          console.log({ e });
          body = { filter: {}, columnFields };
        }
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: `/api/${storeModule}/excel/list`,
            sendData: body,
          }, { root: true })
            .then((res) => {
              resolve(res.data.data);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              commit('setLoading', { path: 'excel', value: false });
            });
        });
      },
      getMainProducts({ dispatch }, { id }) {
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'get',
            query: `api/${storeModule}/show/${id}/mainProducts`,
          }, { root: true })
            .then(async (res) => {
              resolve(res.data.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      getWorkRecords({ dispatch }, { id }) {
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'get',
            query: `api/${storeModule}/show/${id}/workRecords`,
          }, { root: true })
            .then(async (res) => {
              resolve(res.data.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      getShow({ commit, dispatch }, { id, moduleType }) {
        commit('setLoading', { path: 'show', value: true });
        let url = `api/${storeModule}/show/${id}`;
        url = createQueryString(url, moduleType ? { moduleType } : null);
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'get',
            query: url,
          }, { root: true })
            .then((res) => {
              commit('setShowDataRaw', res.data.data);
              commit('setShowData', removeValuesWrapper(structuredClone(res.data.data)));
              resolve(res.data);
            })
            .catch((err) => {
              console.log({ err });
              commit('setShowDataError', true);
              reject(err);
            })
            .finally(() => {
              commit('setLoading', { path: 'show', value: false });
            });
        });
      },
      getFilters({ commit, dispatch, state }, { query = {} }) {
        commit('setLoading', { path: 'filters', value: true });
        let url = `api/${storeModule}/filters?`;
        url = createQueryString(url, query);
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'get',
            query: url,
            signal: state.abortController.signal,
          }, { root: true })
            .then((res) => {
              commit('setFilters', res.data.data.filters);
              commit('setExternalFilters', res.data.data.external_filters);
              commit('setColumns', res.data.data.filters);
              commit('setState', { path: 'checkboxSelection', value: res.data.data.checkbox_selection });
              commit('setListExcelSample', res.data.data.excel_sample);
              commit('setState', { path: 'listExcelExport', value: res.data.data.excel_export_available });
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              commit('setLoading', { path: 'filters', value: false });
            });
        });
      },
      getFields({ commit, dispatch, state }, {
        organization, userType, moduleType, query = {},
      }) {
        commit('setLoading', { path: 'fields', value: true });
        let url = `api/${storeModule}/fields?`;
        if (organization) url += `organization_id=${organization}&`;
        if (userType) url += `userType=${userType}&`;
        if (moduleType) url += `moduleType=${moduleType}&`;
        url = createQueryString(url, query);
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'get',
            query: url,
            signal: state.abortController.signal,
          }, { root: true })
            .then((res) => {
              commit('setFields', res.data.data.fields);
              commit('setChapters', res.data.data.chapters);
              commit('setState', { path: 'actions', value: res.data.data.actions });
              resolve(res.data.fields);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              commit('setLoading', { path: 'fields', value: false });
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      edit({ commit, dispatch, state }, { payload, query, entity = storeModule }) {
        commit('setLoading', { path: 'save', value: true });
        let url = `/api/${entity}/edit?`;
        url = createQueryString(url, query);
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'put',
            query: url,
            sendData: payload,
          }, { root: true })
            .then((res) => {
              commit('setShowDataRaw', res.data.data);
              commit('setShowData', removeValuesWrapper(structuredClone(res.data.data)));
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              commit('setLoading', { path: 'save', value: false });
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      create({ commit, dispatch, state }, { payload, query, entity = storeModule }) {
        commit('setLoading', { path: 'save', value: true });
        let url = `/api/${entity}/create?`;
        url = createQueryString(url, query);
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'put',
            query: url,
            sendData: payload,
          }, { root: true })
            .then((res) => {
              commit('setShowDataRaw', res.data.data);
              commit('setShowData', removeValuesWrapper(structuredClone(res.data.data)));
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              commit('setLoading', { path: 'save', value: false });
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      delete({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: `/api/${storeModule}/delete/${payload.id}`,
          }, { root: true })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      toArchive({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: `/api/${storeModule}/to-archive/${payload.id}`,
          }, { root: true })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      fromArchive({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: `/api/${storeModule}/from-archive/${payload.id}`,
          }, { root: true })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      loadFile({ dispatch }, { payload, entity, id }) {
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: `/api/${entity}/file/${id}`,
            sendData: payload,
          }, { root: true })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      selectionAction({ state, dispatch }, {
        type, payload, query, listParams,
      }) {
        let url = `/api/${storeModule}/batch/${type}?`;
        let body;
        if (query?.isAll) {
          url = createQueryString(url, query);
          try {
            const {
              endRow, sortModel, filterModel, limit, isArchive, page,
            } = listParams;
            body = { ...payload, ...generateListBody(state, endRow, sortModel, filterModel, limit, isArchive, page) };
          } catch (e) {
            body = payload;
          }
        } else {
          body = payload;
        }
        return new Promise((resolve, reject) => {
          dispatch('ajax/axiosSend', {
            type: 'post',
            query: url,
            sendData: body,
          }, { root: true })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
}
