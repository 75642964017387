<template>
  <div class="productPhoto_wrapper">
    <img
      alt="Фото"
      class="productPhoto"
      :src="src"
      :style="canClick ? 'cursor: pointer' : ''"
      @error="onError"
      @click="onClick"
    >
    <v-dialog
      v-model="isPopup"
      width="1200"
    >
      <v-card
        class="pa-0"
        @click="isPopup = false"
      >
        <img
          class="photo"
          :src="src"
          alt="Фото"
          @error="onError"
          @click.stop="isPopup = false"
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const emptyUrl = '/img/emptyImage.jpg';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      src: emptyUrl,
      isPopup: false,
    };
  },
  computed: {
    canClick() {
      return this.src !== emptyUrl;
    },
  },
  watch: {
    url() {
      this.src = this.url || emptyUrl;
    },
  },
  beforeMount() {
    this.src = this.url || emptyUrl;
  },
  methods: {
    onError() {
      this.src = emptyUrl;
    },
    onClick() {
      if (this.canClick) {
        this.isPopup = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.productPhoto
  width: 30px
  height: 40px
  object-fit: cover
  border-radius: 5px
</style>