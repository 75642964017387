<template>
  <v-card
    class="storageNode bg-white pa-2"
    elevation="0"
    :rounded="false"
    :style="node.storages.length ? 'border-bottom: 2px solid #eee; margin-top: 10px' : 'height: 0; padding: 0 !important'"
  >
    <div class="storageNode_content">
      <div
        v-for="(area, i) of node.storages"
        :key="i"
        class="storageNode_item"
      >
        <v-chip
          color="primary"
          :variant="area.selected ? 'flat' : 'tonal'"
          style="width: 100%"
          @click="onAreaSelect(area)"
        >
          <p
            style="white-space: nowrap; width: 100%"
          >
            {{ area.name }}
          </p>
        </v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'StorageNode',
  props: {
    node: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    storageType: {
      type: Object,
      required: true,
    },
  },
  emits: ['select'],
  data() {
    return {
      popup: {
        meta: { isOpen: false, name: '' },
        data: {},
      },
    };
  },
  methods: {
    onAreaSelect(area) {
      this.node.storages.forEach((ar) => { ar.selected = false; });
      area.selected = true;
      this.$emit('select', area, this.index);
    },
    openEditModal(item, index) {
      this.popup.meta.isOpen = true;
      this.popup.meta.name = 'edit';
      this.popup.data.item = item;
      this.popup.data.index = index;
    },
    openDeleteModal(index) {
      this.popup.meta.isOpen = true;
      this.popup.meta.name = 'delete';
      this.popup.data.index = index;
    },
    onEdit(area) {
      this.node.storages.forEach((el, i) => {
        if (i === this.popup.data.index) {
          el.name = area.name;
        }
      });
      this.popup.meta.isOpen = false;
    },
    onDelete() {
      this.node.storages = this.node.storages.filter((el, i) => i !== this.popup.data.index);
      this.popup.meta.isOpen = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.storageNode
  display: grid
  grid-template-columns: 1fr
  align-items: center
  justify-content: space-between
  &_content
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(150px, max-content))
    gap: 10px
    flex-wrap: wrap
    &:deep(.v-chip)
      height: auto
      min-height: 35px
    &:deep(.v-chip__content)
      font-size: 12px
      max-width: 100px
      overflow: hidden
      text-overflow: ellipsis
      white-space: break-spaces
      text-wrap: auto
  &_item
    min-width: 110px
    :deep(.v-chip)
      justify-content: start
    :deep(.v-chip__content)
      min-width: 100%
</style>