import { DefaultEntityStore } from '@/store/defaultEntityStore';
import initialMutations from '@/store/initialMutations';

const store = new DefaultEntityStore('fBSOrder');

const state = {
  ...store.state,
  loading: {
    update: false,
  },
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,

  updateOrders({ commit, dispatch }, { isCancelUpdate }) {
    let url = '/api/fbs/update/all?';
    if (isCancelUpdate) url += `isCancelUpdate=${isCancelUpdate}`;
    return new Promise((resolve, reject) => {
      commit('setLoading', { path: 'update', value: true });
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: url,
      }, { root: true })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoading', { path: 'update', value: false });
        });
    });
  },
};
const mutations = {
  ...initialMutations,
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
