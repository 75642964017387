import initialMutations from '@/store/initialMutations';

export default {
  namespaced: true,
  state: {
    loading: {
      downloadFile: false,
      sendFile: false,
    },
  },
  actions: {
    getPdfTemplate({ dispatch, commit }, module) {
      const url = `/api/${module}/pdf/sample`;
      commit('setLoading', { path: 'downloadFile', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'downloadFile', value: false });
          });
      });
    },

    sendPdfTemplate({ dispatch, commit }, { module, payload }) {
      const url = `/api/${module}/pdf/sample`;
      commit('setLoading', { path: 'sendFile', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'sendFile', value: false });
          });
      });
    },
  },
  mutations: {
    ...initialMutations,
  },
  getters: {
    downloadLoading: (s) => s.loading.downloadFile,
    sendLoading: (s) => s.loading.sendFile,
  },
};