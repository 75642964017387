import initialMutations from '@/store/initialMutations';

export default {
  namespaced: true,
  state: {
    loading: {
      barcode: false,
    },
  },
  mutations: {
    ...initialMutations,
  },
  actions: {
    generateBarcode({ commit, dispatch }, { organizationId, type, length }) {
      if (!type) type = 'number';
      let url = `api/barcode/generate?type=${type}&`;
      if (organizationId) {
        url += `organizationId=${organizationId}&`;
      }
      if (length) {
        url += `length=${length}&`;
      }
      commit('setLoading', { path: 'barcode', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
        }, { root: true })
          .then(async (res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'barcode', value: false });
          });
      });
    },
  },
};