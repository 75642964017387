import axios from 'axios';

export default {
  namespaced: true,
  state: {
    chapters: {},
    user: {},
    company: {},
    loading: {
      user: false,
      chapters: false,
      companyInfo: false,
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setCompany(state, payload) {
      state.company = payload;
    },
    setChapters(state, payload) {
      state.chapters = payload;
    },
    setLoading(state, { path, value }) {
      state.loading[path] = value;
    },
  },
  actions: {
    getUserInfo({ commit, dispatch }) {
      commit('setLoading', { path: 'user', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: 'api/user/info',
        }, { root: true })
          .then(async (res) => {
            commit('setUser', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'user', value: false });
          });
      });
    },
    getCompanyInfo({ commit, dispatch }) {
      commit('setLoading', { path: 'companyInfo', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: 'api/company/info',
        }, { root: true })
          .then(async (res) => {
            commit('setCompany', res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'companyInfo', value: false });
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    changeUserInfo({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: 'api/settings/user/change',
          sendData: payload,
        }, { root: true })
          .then(async (res) => {
            await localStorage.setItem('token', res.data.token);
            axios.defaults.headers.token = res.data.token;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    changeCompanyInfo({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: 'api/settings/company/change',
          sendData: payload,
        }, { root: true })
          .then(async (res) => {
            if (res.data.token) {
              localStorage.setItem('token', res.data.token);
              axios.defaults.headers.token = res.data.token;
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUserChapters({ commit, dispatch }) {
      commit('setLoading', { path: 'chapters', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: 'api/user/role',
        }, { root: true })
          .then(async (res) => {
            commit('account/setChapters', res.data.data, { root: true });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'chapters', value: false });
          });
      });
    },
  },
  getters: {
    user: (state) => state.user,
    company: (state) => state.company,
    chapters: (state) => state.chapters,
  },
};
