<template>
  <v-menu
    activator="parent"
    open-on-hover
  >
    <v-card
      style="opacity: 0.8"
      :class="`bg-${color}`"
    >
      <slot></slot>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'UiTooltip',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>