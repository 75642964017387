<template>
  <div class="organizationProductTable">
    <v-table>
      <thead>
        <tr>
          <th class="text-left">
            Зона
          </th>
          <th class="text-left">
            Кол-во ячеек
          </th>
          <th class="text-left" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in tableItems"
          :key="i"
        >
          <td>
            Зона {{ i + 1 }}
          </td>
          <td>
            <v-text-field
              v-model="item.cells"
              min="0"
              class="my-2"
              density="compact"
              hide-details
              @update:model-value="$emit('update:modelValue', formattedItems, true)"
            />
          </td>
          <td>
            <div class="actions">
              <v-btn
                icon="mdi-delete-outline"
                size="x-small"
                color="error"
                @click="del(i)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="d-flex justify-center">
      <v-btn
        icon="mdi-plus"
        color="primary"
        size="small"
        class="my-4"
        @click="addZone"
      />
    </div>
  </div>
</template>

<script>
import { payloadMixin } from '@/mixins/payload';

export default {
  name: 'StorageTableCreate',
  mixins: [payloadMixin],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    storeModule: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      tableItems: [],
    };
  },
  computed: {
    formattedItems() {
      const arr = structuredClone(this.tableItems).map((el) => {
        const c = +el.cells;
        el.cells = [];
        for (let i = 0; i < c; i++) el.cells.push({ id: 0 });
        return el;
      });
      return arr;
    },
  },
  mounted() {
    this.tableItems = this.modelValue;
    this.$emit('update:modelValue', this.formattedItems, true);
  },
  methods: {
    addZone() {
      this.tableItems.push({
        id: 0, cells: 1,
      });
      this.$emit('update:modelValue', this.formattedItems, false);
    },
    del(idx) {
      this.tableItems = this.tableItems.filter((el, i) => !(i === idx));
      this.$emit('update:modelValue', this.formattedItems, false);
    },
  },
};
</script>
<style scoped>
</style>
