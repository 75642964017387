const chaptersMixin = {
  data() {
    return {
      loadedChapters: [],
      deletedChapters: [],
      chapter: null,
    };
  },
  computed: {
    initialChapterFields() {
      return this.fields.chapter_fields;
    },
    chapterFields() {
      if (!this.initialChapterFields) return [];
      return this.initialChapterFields.reduce((acc, chapterFieldKey) => {
        acc[chapterFieldKey] = this.fields[chapterFieldKey];
        return acc;
      }, {});
    },
    chapters() {
      return this.items.reduce((acc, item) => {
        const values = this.getChapterValues(this.chapterFields, item);
        const name = this.getChapterName(values);
        if (!acc.includes(name)) acc.push(name);
        return acc;
      }, []) || [];
    },
    visibleChapters() {
      return this.chapters
        .filter((chapterName) => this.items.some((el) => {
          const values = this.getChapterValues(this.chapterFields, el);
          const name = this.getChapterName(values);
          return chapterName === name && !this.deletedChapters.includes(name) && !el.is_delete;
        }));
    },
  },
  async beforeMount() {
    if (this.chapters?.length) await this.onChapterSelect(this.visibleChapters[0]);
    this.chaptersLoaded = true;
  },
  methods: {
    getChapterName(values) {
      return Object.entries(values).reduce((acc, el) => {
        acc += `${el[0]}=${el[1]}&`;
        return acc;
      }, '');
    },
    onChapterSelect(chapterName) {
      this.chapter = chapterName;
      const values = this.getChapterValuesFromName(this.chapter);
      this.emptyElement = { ...this.emptyElement, ...values };
      const query = { ...this.$route.query };
      Object.entries(values).forEach(([fieldName, value]) => {
        query[fieldName] = value;
      });
      this.$emit('update:chapter', { values });
    },
    isEqualToChapterValues(chapterValues, value) {
      let isEqual = true;
      Object.entries(chapterValues).forEach(([key, val]) => {
        if (String(value[key]) !== String(val)) {
          isEqual = false;
        }
      });
      return isEqual;
    },
    getChapterValuesFromName(chapterName) {
      if (!chapterName) return {};
      return chapterName.split('&').reduce((acc, field) => {
        const [key, value] = field.split('=');
        if (key)acc[key] = value;
        return acc;
      }, {});
    },
    getChapterValues(chapterFields, item) {
      const obj = {};
      Object.entries(chapterFields).forEach(([key]) => {
        obj[key] = item[key];
      });
      return obj;
    },
  },
};
export default chaptersMixin;