<template>
  <div>
    <v-text-field
      :model-value="time"
      density="compact"
      hide-details
      :label="label"
      :disabled="disabled"
      :variant="variant"
      :color="primary"
      style="min-width: 200px"
      @update:modelValue="onTimeEdited"
    >
      <v-menu
        v-model="isMenuOpen"
        activator="parent"
        :max-height="200"
        :max-width="400"
        :close-on-content-click="false"
        transition="none"
      >
        <v-card class="pa-0">
          <template #message>
            {{ hours }}:{{ minutes }}
          </template>
          <div class="timepicker">
            <v-list>
              <v-list-item
                v-for="(hour, i) of allHours"
                :key="i"
                :class="`0${hour}` === hours || `${hour}` === hours ? 'bg-primary' : ''"
                @click="onHourSelect(hour)"
              >
                {{ hour }}
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item
                v-for="(min, i) of allMinutes"
                :key="i"
                :class="`0${min}` === minutes || `${min}` === minutes ? 'bg-primary' : ''"
                @click="onMinuteSelect(min)"
              >
                {{ min }}
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-menu>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'filled',
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hours: '00',
      minutes: '00',
      isMenuOpen: false,
    };
  },
  computed: {
    allHours() {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        let hour = String(i);
        if (hour.length < 1) {
          hour = `0${i}`;
        }
        arr.push(hour);
      }
      return arr;
    },
    allMinutes() {
      const arr = [];
      for (let i = 0; i < 60; i++) {
        let hour = String(i);
        if (hour.length < 1) {
          hour = `0${i}`;
        }
        arr.push(hour);
      }
      return arr;
    },
    time() {
      return `${this.hours}:${this.minutes}`;
    },
  },
  beforeMount() {
    if (this.modelValue) {
      [this.hours, this.minutes] = this.modelValue.split(':');
    } else {
      this.hours = new Date().getHours();
      this.minutes = new Date().getMinutes();
    }
  },
  methods: {
    onHourSelect(hour) {
      this.hours = String(hour).length <= 1 ? `0${hour}` : hour;
      this.update();
    },
    onMinuteSelect(minute) {
      this.minutes = String(minute).length <= 1 ? `0${minute}` : minute;
      this.update();
    },
    onTimeEdited(val) {
      if (val[2] && val[2] !== ':') val[2] = ':';
      const [hour, minute] = val.split(':');
      // eslint-disable-next-line no-nested-ternary
      this.hours = hour ? String(hour).length <= 1 ? `0${hour}` : hour : '00';
      // eslint-disable-next-line no-nested-ternary
      this.minutes = minute ? String(minute).length <= 1 ? `0${minute}` : minute : '00';
      this.update();
    },
    update() {
      this.$emit('update:modelValue', this.time);
    },
  },
};
</script>

<style lang="sass" scoped>
.timepicker
  display: grid
  grid-template-columns: 1fr 1fr
  height: 200px
</style>