export default [
  {
    field: 'organization_product_id',
    headerName: 'Товар',
    rowGroup: true,
    hide: false,
    meta: {
      childrenField: 'child_organization_products',
    },
    cellClass: (params) => {
      // eslint-disable-next-line no-underscore-dangle
      if (params.node.__hasChildren) return '';
      return 'ml-10';
    },
  },
];