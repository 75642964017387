<template>
  <div>
    <div class="text-start">
      <Label>
        <template #default>
          {{ label }}
        </template>
        <template
          v-if="$slots['hint']"
          #hint
        >
          <slot name="hint"></slot>
        </template>
      </Label>
    </div>
    <div class="gallery">
      <draggable
        v-if="isEdit"
        :list="photos"
        item-key="name"
        class="gallery_photos"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false;"
        @change="onMove"
      >
        <template #item="{element: photo, index}">
          <div
            class="gallery_photos__image"
            @click="popupPhoto = photo.url"
          >
            <div class="number">
              {{ index + 1 }}
            </div>
            <img
              :src="photo.url"
            >
            <button
              class="deleteBtn"
              @click.stop="deleteImage(photo.id)"
            >
              x
            </button>
          </div>
        </template>
      </draggable>
      <div
        v-else
        class="gallery_photos"
      >
        <div
          v-for="(photo, index) of photos"
          class="gallery_photos__image"
          @click="popupPhoto = photo.url"
        >
          <div class="number">
            {{ index + 1 }}
          </div>
          <img
            :src="photo.url"
          >
        </div>
      </div>
      <div
        v-if="isEdit"
        class="gallery_actions"
      >
        <v-file-input
          v-model="file"
          label="Загрузить"
          density="compact"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          :loading="loading"
          :error-messages="errors"
          :error="errors.length"
          clearable
          @update:modelValue="onFileLoad"
        />
      </div>
    </div>

    <v-dialog
      v-model="isPopup"
      width="1200"
    >
      <v-card
        class="pa-0"
      >
        <img
          class="photo"
          :src="popupPhoto"
          @click.stop="isPopup = false"
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import Label from '@/components/UiKit/Label/index.vue';

export default {
  name: 'Gallery',
  components: { Label, draggable },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    photoArray: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      photos: [],
      errors: [],
      popupPhoto: false,
      file: null,
      dragging: false,
    };
  },
  computed: {
    isPopup: {
      get() {
        return !!this.popupPhoto;
      },
      set(val) {
        if (!val) this.popupPhoto = false;
      },
    },
    ...mapState('file', {
      loading: (s) => s.loading.file,
    }),
  },
  beforeMount() {
    this.photos = Array.isArray(this.photoArray) ? JSON.parse(JSON.stringify(this.photoArray)) : [];
    this.onPhotosUpdate();
  },
  methods: {
    deleteImage(id) {
      this.$store.dispatch('file/deleteFile', { id })
        .then(() => {
          this.photos = this.photos.filter((photo) => photo.id !== id);
          this.onPhotosUpdate();
        });
    },
    onFileLoad(val) {
      this.file = [val];
      this.loadFile();
    },
    loadFile() {
      if (!this.file) return;
      const formData = new FormData();
      formData.append(this.file[0]?.name || 'file1', this.file[0]);

      this.$store.dispatch('file/uploadFiles', { payload: formData })
        .then((data) => {
          this.photos.push(data.data[0]);
          this.onPhotosUpdate();
        })
        .catch(() => {
          this.errors.push('Ошибка при загрузке файла');
          setTimeout(() => {
            this.errors = [];
          }, 2000);
        })
        .finally(() => {
          this.file = null;
        });
    },
    onPhotosUpdate() {
      this.$emit('update:modelValue', this.photos.map((el) => el.id));
      this.$emit('update:raw', this.photos);
    },
    onMove() {
      this.$emit('update:modelValue', this.photos.map((el) => el.id));
    },
  },
};
</script>

<style lang="sass" scoped>
.gallery
  padding-block: 10px
  display: grid
  &_photos
    display: flex
    justify-content: start
    gap: 5px
    margin-bottom: 10px
    &__image
      position: relative
      cursor: pointer
      transition: .3s ease
      img
        width: 75px
        height: 75px
        object-fit: cover
        border-radius: 5px
      .number
        font-weight: bold
        font-size: 9px
        position: absolute
        top: 5px
        left: 5px
        background: white
        border: 1px solid var(--primary)
        color: var(--primary)
        border-radius: 5px
        padding-inline: 5px
      .deleteBtn
        width: 17px
        height: 17px
        border-radius: 10px
        position: absolute
        top: 5px
        right: 5px
        font-size: 10px
        background: white
        border: 1px solid var(--primary)
        color: var(--primary)
        font-weight: bold
        &:hover
          filter: brightness(80%)
  &_actions
    display: grid
    grid-template-columns: 300px 50px
    justify-content: start
    gap: 20px
</style>