import axios from 'axios';
import router from '@/router';
import initialMutations from '@/store/initialMutations';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    company: {},
    loading: {
      promo: false,
    },
  },
  mutations: {
    ...initialMutations,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    signIn({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: '/api/login?notRedirect=1',
          sendData: { username: payload.username, password: payload.password },
        }, { root: true })
          .then(async (res) => {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('refresh_token', res.data.refresh_token);
            const { token } = res.data;
            axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    signInByString({ commit, dispatch }, authLink) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: `/api/users/login/${authLink}`,
          headers: { Authorization: '' },
        }, { root: true })
          .then(async (res) => {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('refresh_token', res.data.refresh_token);
            axios.defaults.headers.common.Authorization = res.data.token ? `Bearer ${res.data.token}` : '';
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    register({ dispatch }, payload) {
      const body = {
        mail: payload.mail,
        phone: payload.phone,
        fName: payload.fName,
        lName: payload.lName,
        password: payload.password,
        companyName: payload.companyName,
        isOfferAccepted: payload.isOfferAccepted,
      };
      let url = 'api/registration?';
      if (payload.regLink) url += `regLink=${payload.regLink}`;
      if (payload.registrationLinkCompany) url += `registrationLinkCompany=${payload.registrationLinkCompany}`;
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
          sendData: body,
        }, { root: true })
          .then(async (res) => {
            localStorage.setItem('token', res.data.token);
            axios.defaults.headers.token = res.data.token;
            setTimeout(async () => {
              await router.push({ path: '/dashboard', replace: true });
            }, 1);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    registerCheck({ dispatch }, { regLink, regLinkCompany }) {
      let url = 'api/registration/check-validation?';
      if (regLink) {
        url += `regLink=${regLink}&`;
      } else if (regLinkCompany) {
        url += `registrationLinkCompany=${regLinkCompany}`;
      }
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: url,
        }, { root: true })
          .then(async (res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendPromo({ commit, dispatch }, { promoCode }) {
      commit('setLoading', { path: 'promo', value: true });
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: `api/promo-code/${promoCode}/activate`,
        }, { root: true })
          .then(async (res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'promo', value: false });
          });
      });
    },
    refresh({ commit, dispatch }) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) return;
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: '/token/refresh',
          sendData: { refresh_token: refreshToken },
        }, { root: true })
          .then(async (res) => {
            commit('account/setUser', res.data.data, { root: true });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    isAuthorized: (state) => !!state.token,
  },
};
