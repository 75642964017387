<template>
  <div class="RoleTable">
    <Label>
      <template #default>
        {{ label }}
      </template>
      <template
        v-if="$slots['hint']"
        #hint
      >
        <slot name="hint"></slot>
      </template>
    </Label>
    <v-table v-if="fields.length">
      <thead>
        <tr>
          <th class="text-left">
            Раздел
          </th>
          <th class="text-left">
            Список
          </th>
          <th class="text-left">
            Просмотр
          </th>
          <th class="text-left">
            Изменение
          </th>
          <th class="text-left">
            ТСД
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in fields"
          :key="i"
        >
          <td
            :style="item[1].deep ? `padding-left: 50px`: ''"
          >
            {{ item[1].label }}
          </td>
          <td>
            <v-checkbox
              v-if="item[1].list"
              v-model="tableItems[i][1].list"
              type="checkbox"
              :disabled="disabled || tableItems[i][1].change"
              density="compact"
              color="primary"
              hide-details
              @change="updateValue(i,'list')"
            />
          </td>
          <td>
            <v-checkbox
              v-if="item[1].show"
              v-model="tableItems[i][1].show"
              type="checkbox"
              :disabled="disabled || tableItems[i][1].change"
              density="compact"
              color="primary"
              hide-details
              @change="updateValue(i,'show')"
            />
          </td>
          <td>
            <v-checkbox
              v-if="item[1].change"
              v-model="tableItems[i][1].change"
              type="checkbox"
              :disabled="disabled"
              density="compact"
              color="primary"
              hide-details
              @change="updateValue(i,'change');"
            />
          </td>
          <td>
            <v-checkbox
              v-if="item[1].tsd"
              v-model="tableItems[i][1].tsd"
              type="checkbox"
              :disabled="disabled"
              density="compact"
              color="primary"
              hide-details
              @change="updateValue(i,'tsd');"
            />
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import { payloadMixin } from '@/mixins/payload';
import Label from '@/components/UiKit/Label/index.vue';

// TODO: переделать логику

export default {
  name: 'CreateRole',
  components: { Label },
  mixins: [payloadMixin],
  props: {
    modelValue: {
      type: Array,
      default: () => {},
    },
    storeModule: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      tableItems: [],
      chapters: {},
      fields: {},
    };
  },
  computed: {
    isAllSelected() {
      const obj = {
        change: true, list: true, show: true, tsd: true,
      };
      this.tableItems.forEach((val, i) => {
        if (this.chapters[val[0]]) {
          if (!val[1].change && this.fields[i][1].change) obj.change = false;
          if (!val[1].list && this.fields[i][1].list) obj.list = false;
          if (!val[1].show && this.fields[i][1].show) obj.show = false;
          if (!val[1].tsd && this.fields[i][1].tsd) obj.tsd = false;
        } else {
          val[1].list = false;
          val[1].show = false;
          val[1].change = false;
          val[1].tsd = false;
        }
      });
      return obj;
    },
  },
  created() {
    this.generateFields();
    const obj = this.chapters;
    const value = this.modelValue?.value ?? this.modelValue;

    if (value && Object.keys(value).length) {
      Object.entries(value).forEach((sec) => {
        obj[sec[0]] = {
          change: sec[1].change, list: sec[1].list, show: sec[1].show, tsd: sec[1].tsd,
        };

        Object.entries(sec[1].sections).forEach((s) => {
          obj[s[0]] = {
            change: s[1].change, list: s[1].list, show: s[1].show, tsd: s[1].tsd,
          };
        });
      });
    } else {
      const fields = this.$store.getters[`${this.storeModule}/fields`].sections.values;
      Object.entries(fields).forEach((sec) => {
        obj[sec[0]] = {
          change: false, list: false, show: false, tsd: false,
        };
        Object.entries(sec[1].sections).forEach((s) => {
          obj[s[0]] = {
            change: false, list: false, show: false, tsd: false,
          };
        });
      });
    }

    this.tableItems = JSON.parse(JSON.stringify(this.fields));
    this.tableItems.forEach((val) => {
      if (this.chapters[val[0]]) {
        val[1].list = this.chapters[val[0]].list;
        val[1].show = this.chapters[val[0]].show;
        val[1].change = this.chapters[val[0]].change;
        val[1].tsd = this.chapters[val[0]].tsd;
      } else {
        val[1].list = false;
        val[1].show = false;
        val[1].change = false;
        val[1].tsd = false;
      }
    });
    this.$nextTick(() => {
      this.updateAllSelected();
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('update:modelValue', this.chapters);
    });
  },
  methods: {
    updateValue(row, column) {
      if (this.tableItems[row][0] === 'all') {
        const val = this.tableItems[row][1][column];
        while (this.tableItems[++row]) {
          this.tableItems[row][1][column] = val;
          this.chapters[this.tableItems[row][0]][column] = val;
          if (column === 'change' && val) {
            this.chapters[this.tableItems[row][0]].list = true;
            this.chapters[this.tableItems[row][0]].show = true;
            this.tableItems[row][1].list = true;
            this.tableItems[row][1].show = true;
          }
        }
      } else if (!this.tableItems[row][1].deep) {
        const val = this.tableItems[row][1][column];
        this.chapters[this.tableItems[row][0]][column] = val;
        while (this.tableItems[row + 1] && this.tableItems[row + 1][1].deep) {
          row++;
          this.tableItems[row][1][column] = val;
          this.chapters[this.tableItems[row][0]][column] = val;
          if (column === 'change' && val) {
            this.chapters[this.tableItems[row][0]].list = true;
            this.chapters[this.tableItems[row][0]].show = true;
            this.tableItems[row][1].list = true;
            this.tableItems[row][1].show = true;
          }
        }
      } else {
        this.chapters[this.tableItems[row][0]][column] = this.tableItems[row][1][column];
        if (column === 'change' && this.tableItems[row][1][column]) {
          this.chapters[this.tableItems[row][0]].list = true;
          this.chapters[this.tableItems[row][0]].show = true;
          this.tableItems[row][1].list = true;
          this.tableItems[row][1].show = true;
        }
      }
      this.updateAllSelected();
      this.setPayloadField({ key: 'sections', value: this.chapters });
      this.$emit('update:modelValue', this.chapters);
    },
    generateFields() {
      const fields = this.$store.getters[`${this.storeModule}/fields`]?.sections?.values || {};
      // const fields = this.$store.getters[`${this.storeModule}/fields`]?.sections?.values || {};
      const result = [];
      result.push(['all', {
        label: 'Все разделы', list: true, show: true, change: true, tsd: true,
      }]);
      Object.entries(fields).forEach((val) => {
        result.push(val);
        if (val[1].sections) {
          Object.entries(val[1].sections).forEach((deepval) => {
            deepval[1].deep = true;
            result.push(deepval);
          });
        }
      });
      this.fields = result;
    },
    updateAllSelected() {
      this.tableItems[0][1].list = this.isAllSelected.list;
      this.tableItems[0][1].show = this.isAllSelected.show;
      this.tableItems[0][1].change = this.isAllSelected.change;
      this.tableItems[0][1].tsd = this.isAllSelected.tsd;
    },
  },
};
</script>
<style scoped>

</style>
