export const fileMixin = {
  computed: {
    fileData: {
      get() {
        return this.$store.getters['payload/fileData'];
      },
      set(val) {
        this.$store.commit('payload/setFileData', val);
      },
    },
  },
  methods: {
    saveFile(id) {
      if (!this.fileData.file) return new Promise((resolve) => resolve());
      const formData = new FormData();
      formData.append(`${this.fileData.field}`, this.fileData.file);
      // eslint-disable-next-line consistent-return
      return this.$store.dispatch(
        'file/uploadFiles',
        {
          payload: formData,
          entity: this.fileData.entity,
          id,
        },
      );
    },
  },
  beforeUnmount() {
    this.fileData = {};
  },
};