<template>
  <UiTooltipBtn
    v-if="fields?.[fieldName]?.help?.text?.[injectedType ?? type]"
    :id="fieldName"
    :text="fields?.[fieldName]?.help?.text?.[injectedType ?? type]"
    color="primary"
    icon="mdi-help"
    class="ml-2"
    hover
  />
</template>
<script>
import UiTooltipBtn from '@/components/UiKit/TooltipBtn/index.vue';

export default {
  name: 'Help',
  components: { UiTooltipBtn },
  inject: { injectedType: { from: 'type' } },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'change',
    },
  },
};
</script>