import addColumns from './columns';
import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('box');

const state = {
  ...store.state,
  addColumns,
};
const getters = {
  ...store.getters,
};
const actions = {
  ...store.actions,
  /*
    camelEntity: 'inventoryChild' | 'storageChanger' | 'pallet' | 'storage'
    id: number
  */
  getAvailableBoxes({ dispatch }, { id, payload }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `/api/box/available/storage/${id}`,
        sendData: payload,
      }, { root: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBoxOrganizationProducts({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `/api/box/show/${id}/boxOrganizationProducts`,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  ...store.mutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
